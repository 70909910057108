import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/core/auth/auth.service';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { LocationStrategy, Location } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';
import { ToastService } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-recruiter-info',
  templateUrl: './recruiter-info.component.html',
  styleUrls: ['./recruiter-info.component.scss']
})
export class RecruiterInfoComponent implements OnInit, OnDestroy {

  fsCollection: AngularFirestoreCollection<any>;
  fsDoc: AngularFirestoreDocument<any>;
  doc$: Observable<any>;
  currentUser: any;
  data: any;
  userData: any;
  user: Subscription;
  userId: string;
  profileImage: String;
  firstName: String;
  lastName: String;
  email: String;
  description: String;
  companyName: String;
  companyWebsite: String;
  companyLogo: string;
  phoneNumber: number;
  profileLocation: String;
  companyLocation: String;
  edit: boolean = false;
  editProfile: boolean = false;
  ifAuth: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  downloadURLString: String;
  downloadURLLogo: Observable<string>;
  downloadURLLogoString: String;

  downloadSubscribe: Subscription;

  constructor(private router: Router, private afs: AngularFirestore, public auth: AuthService, private route: ActivatedRoute, private storage: AngularFireStorage, private location: LocationStrategy, private _clipboardService: ClipboardService,  private toast: ToastService) { }

  ngOnInit() {
    this.currentUser = this.route.snapshot.paramMap.get("uid");
    this.auth.currentUser.subscribe(user => {
      if (user) {
        this.userData = user;
        this.profileImage = this.userData.profileImage;
        this.firstName = this.userData.firstName;
        this.lastName = this.userData.lastName;
        this.email = this.userData.email;
        this.description = this.userData.description;
        this.companyLogo = this.userData.companyLogo;
        this.companyName = this.userData.companyName;
        this.companyWebsite = this.userData.companyWebsite;
        this.phoneNumber = this.userData.phoneNumber;
        this.profileLocation = this.userData.city;
        this.companyLocation = this.userData.companyLocation;
        this.userId = user.uid;
        this.ifAuth = true;
        if (this.currentUser === this.userId) { // logged in
          this.editProfile = true;
        }
      }       
    });
  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  save() {
    this.checkForm();
    this.afs.collection('users').doc(this.currentUser).update({
      profileImage: this.profileImage,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      city: this.companyLocation,
      companyName: this.companyName,
      phoneNumber: this.phoneNumber,
      companyWebsite: this.companyWebsite,
      description: this.description,
      companyLocation: this.companyLocation,
      companyLogo: this.companyLogo
    });
  }

  copy() {
    const appPath = location.origin + this.location.path();
    this._clipboardService.copyFromContent(appPath);
    this.toast.success('Profile link copied to clipboard');
  }

  checkForm() {
    if (!this.profileImage) {
      this.profileImage = null;
    }
    if (!this.firstName) {
      this.firstName = null;
    }
    if (!this.lastName) {
      this.lastName = null;
    }
    if (!this.email) {
      this.email = null;
    }
    if (!this.companyLocation) {
      this.companyLocation = null;
    }
    if (!this.phoneNumber) {
      this.phoneNumber = null;
    }
    if (!this.companyWebsite) {
      this.companyWebsite = null;
    }
    if (!this.description) {
      this.description = null;
    }
    if (!this.companyName) {
      this.companyName = null;
    }
    if (!this.companyLogo) {
      this.companyLogo = null;
    }
  }

  startUpload(event: FileList) {
    const file = event.item(0);
    if(file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
    }
    const path = `ProfileImages/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    const ref = this.storage.ref(path);
    this.downloadSubscribe = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(value => this.profileImage = value);
      })
    ).subscribe();
  }

  startUploadLogo(event: FileList) {
    const file = event.item(0);
    if(file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
    }
    const path = `CompanyLogos/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    const ref = this.storage.ref(path);
    this.downloadSubscribe = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURLLogo = ref.getDownloadURL();
        this.downloadURLLogo.subscribe(value => this.companyLogo = value);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.user) {
      this.user.unsubscribe();
    }

    if (this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }

  }
  authCheck() {
    if (this.ifAuth === false) {
      this.router.navigate(['/login']);
    }
  }
}
