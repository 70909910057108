import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-codingame-auth',
  templateUrl: './codingame-auth.component.html',
  styleUrls: ['./codingame-auth.component.scss']
})
export class CodingameAuthComponent implements OnInit {

  username: string;

  constructor() { }

  ngOnInit() {
    const codingameToken = new URL(window.location.href).searchParams.get('username');
    if (codingameToken) {
      window.close();
    }
  }

  public setUsername() {
    if (this.username) {
      window.location.href = `${window.location.href}?username=${this.username}`;
    }
  }

}
