import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Observable,of, from, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { stringify } from 'querystring';
//Environment variables
import { environment } from '../../../environments/environment';
import {MediumAccessToken} from './medium';

@Injectable({
  providedIn: 'root'
})
export class MediumService {

  private mediumKey: string = environment.mediumConfig.client_id;
  private mediumRedirctURI = encodeURI(environment.mediumConfig.redirect_uri);
  private mediumApiUrl = `${environment.apiAddress}/medium`;
  private authUrl = `https://medium.com/m/oauth/authorize?client_id=${this.mediumKey}&scope=basicProfile,listPublications&state=cyferlinx&response_type=code&redirect_uri=${this.mediumRedirctURI}`
  //private accesstokenUrl = 'https://bitbucket.org/site/oauth2/access_token'
  public accesstoken: BehaviorSubject<any> = new BehaviorSubject(null);
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<MediumAccessToken>((resolve, reject) => {
      console.log(this.authUrl)
      this.auth.launchAuthPopup(this.authUrl, 'Medium Auth')
      .then(href => {
        const mediumCode = new URL(href).searchParams.get('code');
        
        if (mediumCode) {
          this.getAccessToken(mediumCode)
          .then(accesstoken => {
            resolve (accesstoken);
          })
          .catch(err => {
            console.log(err)
            reject(new Error('Retrieving accesstoken failed'));
          }); 
        } else {
          console.log('err' )
          reject(new Error('No code found'));
        }
      })
      .catch(err => {
        reject(new Error(stringify(err)));
      });
    });
  }

  private getAccessToken(code: string) {
    return new Promise<MediumAccessToken>((resolve, reject) => {
      const params = `/${code}`; 
      this.http.get<MediumAccessToken>(this.mediumApiUrl + '/auth' + params, {headers: this.httpHeaders})
      .subscribe(res => {
        if (res) {
          resolve(res);
        } else{
          reject(new Error('auth failed'));
        }
      });
    });
  }

  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<MediumAccessToken>(this.mediumApiUrl + '/update', {headers: this.httpHeaders})
      .subscribe(res => {
        if (res) {
          resolve(res);
        } else{
          reject(new Error('auth failed'));
        }
      });
    });
  }

  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.mediumApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
