import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';
import { MeetupService } from '../meetup.service';


@Component({
  selector: 'app-meetup-profile',
  templateUrl: './meetup-profile.component.html',
  styleUrls: ['./meetup-profile.component.scss']
})
export class MeetupProfileComponent implements OnInit, OnChanges, OnDestroy {
  user: User;
  meetupUser: any;
  meetupGroups: any;
  meetupEvents: any;
  meetupGroups$: Subscription;
  meetupEvents$: Subscription;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  APIKey: string = "";
  isCollapsed: any[];
  uid: any;
  loaded: any;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true
  loading: boolean = false;

  // New chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;
  @Input() disable: boolean = true;
  
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private _http: Http,
    private meetup: MeetupService) { }

  private meetupHtml() {

  }

  private linkMeetupAccount() {

  }

  ngOnInit() {
    if(this.uidParam){
      const pathGroups = `meetup/${this.uidParam}/groups`;
      this.meetupGroups$ = this.afs.collection<any>(pathGroups).valueChanges().subscribe(groups => {
        if (groups) {
          this.meetupGroups = groups;
        }
      });
  
      const pathEvents = `meetup/${this.uidParam}/events`
      this.meetupEvents$ = this.afs.collection<any>(pathEvents).valueChanges().subscribe(events => {
        if (events) {
          this.meetupEvents = events;
        }
      });
  
      this.afs.collection('meetup').doc(this.uidParam).ref.get().then(doc => {
        if (doc.exists) {
          this.meetupUser = doc.data();
        }
      })
    }
  }

  ngOnChanges() {

  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  private updatePieChart() {

  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }

  async Update() {
    this.loading = true;
    await this.meetup.update().then(res => {
      this.toast.success("Successfully Updated Meetup");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
  }

  async Delete() {
    this.loading = true;
    await this.meetup.delete().then(res => {
      this.toast.success("Successfully Deleted Meetup");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
    this.meetupUser = null;
    this.meetupEvents = null;
    this.meetupGroups = null;
  }

  ngOnDestroy() {
    if (this.meetupEvents$) {
      this.meetupEvents$.unsubscribe();
    }
    if (this.meetupGroups$) {
      this.meetupGroups$.unsubscribe();
    }
  }

}
