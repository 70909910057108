import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoderComponent } from './coder.component';
import { ProfileGuard } from '../core/profile.guard/profile.guard';
import { ProfileVerifyGuard } from '../core/profile-verify.guard/profile-verify.guard';

const routes: Routes = [
    { path: '', component: CoderComponent, canActivate: [ProfileGuard]},
    { path: ':uid', component: CoderComponent},
    // { path: ':uid', component: CoderComponent, canActivate: [ProfileVerifyGuard]},
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class CoderRoutingModule { }