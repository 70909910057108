import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meetup-redirect',
  templateUrl: './meetup-redirect.component.html',
  styleUrls: ['./meetup-redirect.component.scss']
})
export class MeetupRedirectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.close();
  }
}
