import { Component, OnDestroy, OnChanges, Input } from '@angular/core';
import { GithubUser, GithubRepo, GithubLanguage, GithubContributors } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { PieChartConfig } from 'src/app/core/charts/PieChartConfig';
import { AuthService } from 'src/app/core/auth/auth.service';
import { GithubService } from '../github.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-github-profile',
  templateUrl: './github-profile.component.html',
  styleUrls: ['./github-profile.component.scss']
})
export class GithubProfileComponent implements OnDestroy, OnChanges {

  //parameters for sub component
  @Input() enableEditParam: boolean = true;
  @Input() uidParam: string;
  @Input() disable: boolean = true;

  githubUser: GithubUser = null;
  githubUserSubscribe: Subscription;
  githubProgressSubscribe: Subscription;
  githubRepos: GithubRepo[] = [];
  githubReposSubscribe: Subscription;
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  data1: any[] = [];
  listData: any[] = [];
  percentageData: any[] = [];
  config1: PieChartConfig;
  elementId1: string;
  languageTotal: number = 0;
  listColours: string[] = [];
  edit = false;
  tags: any;
  uid: any;
  loading: boolean = false;
  githubToggle: boolean = false;
  deleteSub: Subscription;
  mdbChartComplete: boolean = false;
  //new chart
  mdbChartLabel: string[];
  mdbChartData: any[];
  name: string;
  TotalCommits: any[] = []
  TotalCommitsArray: any
  TotalCommitsDates: any[] = []
  DataProcessing = false
  progressInfo: string
  public chartType: string = 'line';
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];
  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(74, 107, 119, .2)',
      borderColor: 'rgba(74, 107, 119, .7)',
      borderWidth: 2,
    },

  ];

  public chartOptions: any = {
    responsive: true, scales: {
      yAxes: [{
        ticks: { beginAtZero: true }
      }]
    }
  };
  public chartLabels: Array<any> = ['Dates']



  constructor(public auth: AuthService, public github: GithubService, private afs: AngularFirestore, private toast: ToastService,) {

  }

  private linkGithubAccount() {
    // this.auth.linkGithub(false);
  }

  private connectPrivateRepos() {
    // this.auth.enablePrivateRepos();
  }

  async Update() {
    this.loading = true;
    await this.github.update().then(res => {
      // do something with github here
      this.toast.success("Successfully Updated GitHub");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of github
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
  }

  //ngOnchanges effectively replaces the ngOnit code.
  ngOnChanges() {
    if (this.uidParam) {
      this.uid = this.uidParam;
      this.githubToggle = false;
      if (this.enableEditParam == true) {
        this.githubToggle = true;
      }
      //this.githubUserSubscribe = this.github.getUser(this.uid).subscribe(github => {
      const path = `github/${this.uidParam}`;
      this.githubUserSubscribe = this.afs.doc<GithubUser>(path).valueChanges().subscribe(github => {
        if (github) {
          this.githubUser = github;
          console.log(`SORA =============================`, this.githubUser)
          if (this.githubUser != null) {
            this.TotalCommitsArray = []
            this.TotalCommitsDates = []
            this.updatePieChart(this.githubUser.languages)
            let commits = []
            for(let i=0; i<this.githubUser['commitHistory'].length; i++){
              this.TotalCommitsDates.push(this.githubUser['commitHistory'][i].date)
              commits.push(this.githubUser['commitHistory'][i].commits)
            }
            this.TotalCommitsArray = [{ data: commits, label: 'Commits' }]
            this.DataProcessing = true
          }
        }
        if (github == null) {
          this.githubUser = null;
        }
      });
      const pathProgress = `github/${this.uidParam}/progress/data`;
      this.githubProgressSubscribe = this.afs.doc(pathProgress).valueChanges().subscribe(progress => {
        if (progress) {
          //console.log(progress)
          this.progressInfo = progress['info']
        }
      })
      const pathrepo = `github/${this.uidParam}/repos`;
      this.githubReposSubscribe = this.afs.collection<GithubRepo>(pathrepo).valueChanges().subscribe(async repos => {
        //this.githubReposSubscribe = this.github.getRepos(this.uid).subscribe(repos => {
        if (repos) {
          if (this.githubUser) {
            this.githubRepos = repos;
            this.TotalCommits = []
            for (let j = 0; j < repos.length; j++) {
              let owner = this.githubUser.loginName//repos[j].url.split("/")[3]
              let userLocation
              repos[j].contributors = await this.getCollaborators(pathrepo, repos[j].name);
              //console.log(repos[j])
              if (repos[j]['contributors'].length != 0) {
                for (let i = 0; i < repos[j]['contributors'].length; i++) {
                  if(repos[j]['contributors'][i].author) {
                    if (repos[j]['contributors'][i].author['login'] == owner) {
                      userLocation = i
                      //console.log(repos[j].name)
                      //console.log(userLocation)
                      //console.log(repos[j]['contributors'][i].total)
                      this.githubRepos[j].commits = repos[j]['contributors'][i].total
                      let a = 0, c = 0, d = 0, w = 0
                      //let commits = []
                      //let dates = []

                      for (let k = 0; k < repos[j]['contributors'][i].weeks.length; k++) {
                        a = a + repos[j]['contributors'][i].weeks[k].a
                        c = c + repos[j]['contributors'][i].weeks[k].c
                        d = d + repos[j]['contributors'][i].weeks[k].d
                        /*commits[k] = repos[j]['contributors'][i].weeks[k].c
                        dates[k] = repos[j]['contributors'][i].weeks[k].d
                        const Totalcommits = [{
                          commits: repos[j]['contributors'][i].weeks[k].c,
                          timestamp: repos[j]['contributors'][i].weeks[k].w
                        }]
                        //console.log(Totalcommits)
                        this.TotalCommits.push(Totalcommits)*/
                        if (repos[j]['contributors'][i].weeks[k].a > 0) {
                          w = w + 1
                        }
                      }
                      this.githubRepos[j].a = a
                      this.githubRepos[j].c = c
                      this.githubRepos[j].d = d
                      this.githubRepos[j].w = w
                      //this.githubRepos[j].commitsArray = [{ data: commits, label: 'Commits' }]
                      //console.log(commits)
                      //this.githubRepos[j].datesArray = dates

                    }
                  }
                }

              }

            }
            /*this.TotalCommits.sort(function (x, y) {
              return x[0].timestamp - y[0].timestamp;
            })
            let temp: any[] = []
            let commit: number = 0
            let date = 0
            for (let j = 1; j < this.TotalCommits.length; j++) {
              //console.log(this.TotalCommits[j][0].timestamp)
              if (this.TotalCommits[j - 1][0].timestamp !== this.TotalCommits[j][0].timestamp) {

                //console.log(this.TotalCommits[j][0].timestamp)
                if (commit !== 0) {
                  let conv = new Date(date * 1000).toLocaleDateString()
                  let year = conv.split('/')[2]
                  let month = conv.split('/')[0]
                  let day = conv.split('/')[1]
                  //console.log(day+'/'+month+'/'+year)
                  const sumCommit = [{
                    commits: commit,
                    timestamp: day + '/' + month + '/' + year
                  }]
                  temp.push(sumCommit)
                  commit = 0
                  date = 0
                }
                else {
                  let date = new Date(this.TotalCommits[j][0].timestamp * 1000).toLocaleDateString()
                  let year = date.split('/')[2]
                  let month = date.split('/')[0]
                  let day = date.split('/')[1]
                  const sumCommit = [{
                    commits: this.TotalCommits[j][0].commits,
                    timestamp: day + '/' + month + '/' + year
                  }]
                  temp.push(sumCommit)
                }

              }
              else {
                //console.log(this.TotalCommits[j][0].timestamp)
                commit = commit + Number(this.TotalCommits[j][0].commits)
                date = this.TotalCommits[j][0].timestamp

              }
            }*/
            //console.log(temp)
            /*let commitData = []
            for (let i = 0; i < temp.length; i++) {
              commitData.push(temp[i][0].commits)
              this.TotalCommitsDates.push(temp[i][0].timestamp)
            }
            this.TotalCommitsArray = [{ data: commitData, label: 'Commits' }]
            this.DataProcessing = true*/
            //console.log(this.TotalCommitsArray)
          }
        }
      });
    }
  }
  unlink() {
    // this.github.unlinkGithub()
  }

  //Get collaborators from the collaborator sub collection
  getCollaborators (repoPath: string, repoID: string) {
    return new Promise<any[]> ((resolve) => {
      this.afs.collection<GithubRepo>(repoPath).doc(repoID).collection("contributors")
      .valueChanges().subscribe(conts => resolve(conts));
    });
  }

  async Delete() {
    this.loading = true;
    await this.github.delete().then(res => {
      this.toast.success("Successfully Deleted Github");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of github
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
    this.githubUser = null;
  }
  githubToggleSetting() {
    this.githubToggle = !this.githubToggle
    //console.log(this.githubToggle)
  }
  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
    // this.github.changeShow(this.uid, this.githubUser.languages)
    this.data1 = [['Language', '%']];
    this.percentageData = [];
    this.languageTotal = 0;
    for (let i = 0; i < this.githubUser.languages.length; i++) {
      if (this.githubUser.languages[i].show) {
        this.data1.push([this.githubUser.languages[i].language.toLowerCase(), this.githubUser.languages[i].score]);
        this.languageTotal += this.githubUser.languages[i].score;
      }
    }
    for (let j = 1; j < this.data1.length; j++) {//(let j=1; j<this.data1.length-1;j++){
      this.percentageData.push([this.data1[j][0], (Number(this.data1[j][1]) / this.languageTotal)])
    }
    // this.github.updateLanguagePercentages(this.uid);

    // Automatically Updates Repo Count for User
    let repoCount = 0;
    let repoTotal = 0;
    for (const repo of this.githubRepos) {
      if (repo.show) {
        repoCount++;
      }
      repoTotal++
    }
    this.afs.doc<GithubUser>(`github/${this.uidParam}`).update({ repoCount: repoCount, repoTotal: repoTotal, languages: this.githubUser.languages });
  }

  //changes the state of a github repo to be made public or private
  changeCheckbox(i) {
    if (this.githubRepos) {
      this.githubRepos[i].show = !this.githubRepos[i].show;
      this.afs.collection('github').doc(this.uid).collection('repos').doc(this.githubRepos[i].name).update({
        show: this.githubRepos[i].show
      });
    }
  }

  //change's the state of a language to be made public or private
  changeLanguageCheckbox(i) {
    if (this.githubUser) {
      this.githubUser.languages[i].show = !this.githubUser.languages[i].show;
    }
  }

  ngOnDestroy() {
    if (this.githubUserSubscribe) {
      this.githubUserSubscribe.unsubscribe();
    }
    if (this.githubReposSubscribe) {
      this.githubReposSubscribe.unsubscribe();
    }
    if (this.githubProgressSubscribe) {
      this.githubProgressSubscribe.unsubscribe();
    }
    if (this.deleteSub) {
      this.deleteSub.unsubscribe();
    }
  }

  // Doughnut
  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  private updatePieChart(languages: GithubLanguage[]) {
    //Piechart1 Data & Config
    if (languages) {
      this.mdbChartData = [];
      this.mdbChartLabel = [];
      let miniVal = 0;
      let biggerVals = false;
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].show) {
          if (miniVal > 0 && i == languages.length - 1) { // add combined value to chart
            this.mdbChartData.push((miniVal).toFixed(2));
            this.mdbChartLabel.push('various');
          }

          if (languages[i].score < 1 && biggerVals) {
            miniVal += (languages[i].score);
          } else if (languages[i].score < 1 && !biggerVals) {
            this.mdbChartData.push((languages[i].score*100).toFixed(2));
            this.mdbChartLabel.push(languages[i].language);
          } else {
            biggerVals = true;
            this.mdbChartData.push((languages[i].score).toFixed(2));
            this.mdbChartLabel.push(languages[i].language);
          }
        }
      }
      this.mdbChartComplete = false;
      setTimeout(() => {
        this.mdbChartComplete = true;
      })
    }
  }

}
