import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoderComponent } from './coder.component';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { CoderProfileComponent } from './coder-profile/coder-profile.component';
import { CoderProfileWorkHistoryComponent } from './coder-profile/coder-profile-work-history/coder-profile-work-history.component';
import { CoderProfileShareComponent } from './coder-profile/coder-profile-share/coder-profile-share.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CoderProfileBasicComponent } from './coder-profile/coder-profile-basic/coder-profile-basic.component';
import { CoderProfileIntegrationsComponent } from './coder-profile/coder-profile-integrations/coder-profile-integrations.component';
import { IntegrationsModule } from '../integrations/integrations.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule } from '@angular/material';
import { CoderRoutingModule } from './coder-routing.module'

@NgModule({
  declarations: [CoderComponent, CoderProfileComponent, CoderProfileWorkHistoryComponent,  CoderProfileIntegrationsComponent, CoderProfileShareComponent, CoderProfileBasicComponent],
  imports: [
    CommonModule,
    SharedModule,
    UiModule,
    MDBBootstrapModulesPro.forRoot(),
    IntegrationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    CoderRoutingModule],
  exports: [CoderProfileShareComponent]
})
export class CoderModule { }
