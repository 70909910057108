import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ClipboardModule } from 'ngx-clipboard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
//MDB Imports
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ToastModule } from 'ng-uikit-pro-standard';
import { AgmCoreModule } from '@agm/core';
//Angular Fire Imports
//import * as firebase from 'firebase/app';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireDatabaseModule } from '@angular/fire/database';
//Custom modules
import {SharedModule} from './shared/shared.module'
import {SignupModule} from './signup/signup.module'
import {CoreModule} from './core/core.module'
import {IntegrationsModule} from './integrations/integrations.module'
import { AdminModule } from './admin/admin.module'
import { UiModule } from './ui/ui.module'
import { CoderModule } from './coder/coder.module'
import { RecruiterModule } from './recruiter/recruiter.module'
import { PositionModule } from './recruiter/recruiter-position/position.module'
import { JobsModule } from './jobs/jobs.module';
//Environment variables
import { environment } from '../environments/environment';
import { HomeMapComponent } from './home/home-map/home-map.component';
import { HomeSignupComponent } from './home/home-signup/home-signup.component';
import { HomeInviteComponent } from './home/home-invite/home-invite.component';
import { HomeTechSearchComponent } from './home/home-tech-search/home-tech-search.component';
import { HomeFooterComponent } from './home/home-footer/home-footer.component';
import { HomeTechPassportComponent } from './home/home-tech-passport/home-tech-passport.component';
import { HomeEvaluationComponent } from './home/home-evaluation/home-evaluation.component';
//import { CandidateComponent } from './home/home-evaluation/candidate/candidate.component';
import { HomeHeaderComponent } from './home/home-header/home-header.component';
import { MainFooterComponent } from './main-footer/main-footer.component';

import { TransferHttpCacheModule } from '@nguniversal/common';

export const googleAPIKey = environment.googleAPIKey;
export const firebaseConfig = environment.firebaseConfig;
//firebase.initializeApp(firebaseConfig)

import { LocalSyncComponent } from './local-sync/local-sync.component';
import { HomeIntroComponent } from './home/home-intro/home-intro.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeIntroComponent,
    HomeMapComponent,
    HomeSignupComponent,
    HomeInviteComponent,
    HomeTechSearchComponent,
    HomeFooterComponent,
    HomeTechPassportComponent,
    HomeEvaluationComponent,
    //CandidateComponent,
    HomeHeaderComponent,
    MainFooterComponent,
    LocalSyncComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: googleAPIKey, libraries: ["places"] }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFirePerformanceModule, // performance
    AngularFireDatabaseModule,
    SharedModule,
    SignupModule,
    CoreModule,
    IntegrationsModule,
    AdminModule,
    UiModule,
    CoderModule,
    RecruiterModule,
    PositionModule,
    HttpModule,
    ClipboardModule,
    JobsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  exports: [
    HomeComponent,
    SharedModule,
    UiModule,
  ]
})
export class AppModule { }
