import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';
import {PlaceData} from '../../core/google-places/google-places';
import {  User} from '../../core/user';
import * as firebase from 'firebase/app'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-map',
  templateUrl: './home-map.component.html',
  styleUrls: ['./home-map.component.scss']
})
export class HomeMapComponent implements OnInit, OnDestroy {

  public lat = []
  public lon = []
  public location: any
  geolocationPosition: any
  public map: any = { lat: 17, lng: 5, zoom: 2 };
  mapPoints = []
  Suburb = []
  Users:any
  projects: number
  globalstats: any;
  languages =[];
  totalLanguages: number;
  totalUsers: number;
  totalHackers: number;
  totalRecruiters: number;
  totalSearches: number;
  jobs: number;
  mapSubscribe: Subscription;
  userSubscribe: Subscription;
  statsSubscribe: Subscription;
  userCountSubscribe: Subscription;
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.mapSubscribe = this.afs.doc(`admin/map`).valueChanges().subscribe(docs => {
      if (docs) {
        this.location = docs['locations']
        for (let i = 0; i < docs['locations'].length; i++) {
          if (docs['locations'][i].users.length > 0) {
            const point = {
              latitude: docs['locations'][i]['location'].latitude,
              longitude: docs['locations'][i]['location'].longitude
            }
            this.mapPoints.push(point)
            const locationStats = {
              Location: docs['locations'][i].city,
              numberOfUsers: docs['locations'][i].totalUsers
            }
            this.Suburb.push(locationStats)
          }
        }
      }
    })
    this.userSubscribe = this.afs.doc(`admin/users`).valueChanges().subscribe(docs => {
      if (docs) {
        this.Users = docs;
        //console.log(this.Users.total);
      }
    })
    this.statsSubscribe = this.afs.doc('admin/stats').valueChanges().subscribe(stats => {
      if (stats) {
        this.projects = stats['total'];
        this.totalSearches = stats['searches'];
        this.jobs = stats['jobs'];
        this.languages =[];
        let Languages = Object.keys(stats);
        //console.log(Languages)
        for (let k=0; k<Languages.length; k++) {
          if (Languages[k] != "total" && Languages[k] != "unknown" && Languages[k] != "searches" && Languages[k] != "jobs") {
            //console.log(Languages[k])
            this.languages.push(Languages[k]);
          }
        }
        this.totalLanguages = this.languages.length;
      }
    });
  }

  ngOnDestroy() {
    if (this.mapSubscribe) {
      this.mapSubscribe.unsubscribe();
    }
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.statsSubscribe) {
      this.statsSubscribe.unsubscribe();
    }
    if (this.userCountSubscribe) {
      this.userCountSubscribe.unsubscribe();
    }
  }

  styles: any[] = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1c2a48"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#0be4b6"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1a3646"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#64779e"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#334e87"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1c3048"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6f9ba5"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3C7680"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2c6675"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fcfefe"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#b0d5ce"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3a4762"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#0e1626"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4e6d70"
        }
      ]
    }
  ]

}
