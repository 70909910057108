import { Component, OnInit } from '@angular/core';
import { GitlabService } from '../gitlab.service';

@Component({
  selector: 'app-gitlab-redirect',
  templateUrl: './gitlab-redirect.component.html',
  styleUrls: ['./gitlab-redirect.component.scss']
})
export class GitlabRedirectComponent implements OnInit {

  constructor(private gitlab: GitlabService) { }

  async ngOnInit() {
/*     const gitlabCode = new URL(window.location.href).searchParams.get('code');
    const gitlabStatus = new URL(window.location.href).searchParams.get('status');
    if (gitlabCode) {
      this.gitlab.getAccessToken(gitlabCode)
      .then(accesstoken => {
        window.location.href = `${window.location.origin}/gitlab-redirect?status=success`
      })
      .catch(err => {
        window.location.href = `${window.location.origin}/gitlab-redirect?status=${JSON.stringify(err)}`
      });
    }

    if (gitlabStatus) {
      window.close();
    } */
    window.close();
    
  }

}
