import {map, distinctUntilChanged, debounceTime, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable ,  BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CitiesSearchService {

  constructor(private db: AngularFireDatabase) { }

  /*getCities(start: BehaviorSubject<string>, end: BehaviorSubject<string>): Observable<any[]> {
    return Observable.zip(start, end).switchMap(param => {
      return this.db.list("/cities", ref => ref.orderByChild("city").limitToFirst(10).startAt(param[0]).endAt(param[1])).snapshotChanges()
        .map(changes => {
        return changes.map(c => {
          return { key: c.payload.key, ...c.payload.val() };
        });
      });
    });
  }*/
  getCities(start: BehaviorSubject<string>): Observable<any[]> {
    return start.pipe(switchMap(startText => {
      const endText = startText + '\uf8ff';
      return this.db
        .list('/Cities', ref =>
          ref
            .orderByChild('city')
            .limitToFirst(20)
            .startAt(startText)
            .endAt(endText)
        )
        .snapshotChanges().pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(changes => {
          return changes.map(c => {
            return { key: c.payload.key, ...c.payload.val() };
          });
        }),);
    }));
  }

}
