import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService} from '../../../core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { Subscription } from 'rxjs';

@Component({
  selector: 'accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss']
})
export class AcceptComponent implements OnInit, OnDestroy {
  userSub: Subscription
  roleSub: Subscription;
  user: any
  constructor(private auth: AuthService, private router: Router, private toast: ToastService) { }

  ngOnInit() {
    this.userSub =  this.auth.currentUser.subscribe((user) => {
      if(user){
        this.user = user
        if(this.user.termsAccepted == null){
          this.user.termsAccepted = false
        }
      }
    })
  }
  changeCheckbox() {
    if (this.user) {
        this.user.termsAccepted = !this.user.termsAccepted;
        this.auth.changeTermsOfUse(this.user.uid, this.user.termsAccepted);
        if (this.user.termsAccepted){
          this.roleSub = this.auth.roles.subscribe(roles => {
            if (roles) {
              if(roles['hacker'] === true){
                this.router.navigate(['hacker']);
              }
              else{
                this.router.navigate(['recruiter']);
              }
            }
          })
        }
    }
  }
  ngOnDestroy(){
    if(this.userSub){
      this.userSub.unsubscribe()
    }
    if(this.roleSub){
      this.roleSub.unsubscribe();
    }
  }

}
