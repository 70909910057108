import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { User} from '../core/user';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit, OnDestroy {
  user: User;
  userSubscribe: Subscription;

  constructor(
    public auth: AuthService
    )
    { }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
        if (user) {
          this.user = user;
        } else {
          this.user = null;
        }
    });
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }
}
