import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore } from '@angular/fire/firestore';
import { Http } from '@angular/http';
import { MediumService } from '../medium.service';


@Component({
  selector: 'app-medium-profile',
  templateUrl: './medium-profile.component.html',
  styleUrls: ['./medium-profile.component.scss']
})
export class MediumProfileComponent implements OnInit, OnChanges, OnDestroy {
  user: User;
  mediumUser: any;
  mediumPublications$: Subscription;
  mediumPublications: any;
  
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  APIKey: string = "";
  isCollapsed: any[];
  uid: any;
  loaded: any;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true
  loading: boolean = false;

  // New chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;

  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private _http: Http,
    private medium: MediumService ) { }

  private mediumHtml() {

  }

  private linkMediumAccount() {

  }

  ngOnInit() {
    if(this.uidParam){
      const pathPublications = `medium/${this.uidParam}/publications`;
      this.mediumPublications$ = this.afs.collection<any>(pathPublications).valueChanges().subscribe(publications => {
        if (publications) {
          this.mediumPublications = publications;
        }
      });
    }
    this.afs.collection('medium').doc(this.uidParam).ref.get().then(doc => {
      if (doc.exists) {
        this.mediumUser = doc.data();
      }
    });
  }

  ngOnChanges() {

  }

  ngOnDestroy() {
    if (this.mediumPublications$) {
      this.mediumPublications$.unsubscribe();
    }
  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  private updatePieChart() { 

  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }

  async Update() {
    this.loading = true;
    await this.medium.update().then(res => {
      this.toast.success("Successfully Updated Medium");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
  }
  
  async Delete() {
    this.loading = true;
    await this.medium.delete().then(res => {
      this.toast.success("Successfully Deleted Medium");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
    this.mediumUser = null;
    this.mediumPublications = null;
  }

  

}
