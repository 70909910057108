import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GithubRedirectComponent } from './github/github-redirect/github-redirect.component';
import { GithubService } from './github/github.service';
import { BitbucketService } from './bitbucket/bitbucket.service';
import { BitbucketRedirectComponent } from './bitbucket/bitbucket-redirect/bitbucket-redirect.component';
import { GitlabRedirectComponent } from './gitlab/gitlab-redirect/gitlab-redirect.component';
import { GitlabService } from './gitlab/gitlab.service';
import { MeetupRedirectComponent } from './meetup/meetup-redirect/meetup-redirect.component';
import { MeetupService } from './meetup/meetup.service';
import { TopcoderService } from './topcoder/topcoder.service';
import { TopcoderAuthComponent } from './topcoder/topcoder-auth/topcoder-auth.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodewarsAuthComponent } from './codewars/codewars-auth/codewars-auth.component';
import { CodewarsService } from './codewars/codewars.service';
import { YoutubeRedirectComponent } from './youtube/youtube-redirect/youtube-redirect.component';
import { YoutubeService } from './youtube/youtube.service';
import { TwitterRedirectComponent } from './twitter/twitter-redirect/twitter-redirect.component';
import { TwitterService } from './twitter/twitter.service';
import { CodingameService } from './codingame/codingame.service';
import { CodingameAuthComponent } from './codingame/codingame-auth/codingame-auth.component';
import { LinkedinService } from './linkedin/linkedin.service';
import { LinkedinRedirectComponent } from './linkedin/linkedin-redirect/linkedin-redirect.component';
import { BitbucketProfileComponent } from './bitbucket/bitbucket-profile/bitbucket-profile.component';
import { UiModule } from '../ui/ui.module';
import { GitlabProfileComponent } from './gitlab/gitlab-profile/gitlab-profile.component';
import { StackoverflowProfileComponent } from './stackoverflow/stackoverflow-profile/stackoverflow-profile.component';
import { CodewarsProfileComponent } from './codewars/codewars-profile/codewars-profile.component';
import { CodingameProfileComponent } from './codingame/codingame-profile/codingame-profile.component';
import { GithubProfileComponent } from './github/github-profile/github-profile.component';
import { LinkedinProfileComponent } from './linkedin/linkedin-profile/linkedin-profile.component';
import { MeetupProfileComponent } from './meetup/meetup-profile/meetup-profile.component';
import { TopcoderProfileComponent } from './topcoder/topcoder-profile/topcoder-profile.component';
import { TwitterProfileComponent } from './twitter/twitter-profile/twitter-profile.component';
import { YoutubeProfileComponent } from './youtube/youtube-profile/youtube-profile.component';
import { MediumProfileComponent } from './medium/medium-profile/medium-profile.component';
import { HackadayProfileComponent } from './hackaday/hackaday-profile/hackaday-profile.component';
import { TwitchProfileComponent } from './twitch/twitch-profile/twitch-profile.component';
import { MediumRedirectComponent } from './medium/medium-redirect/medium-redirect.component';
import { StackoverflowRedirectComponent } from './stackoverflow/stackoverflow-redirect/stackoverflow-redirect.component';




@NgModule({
  declarations: [
    GithubRedirectComponent, 
    BitbucketRedirectComponent, 
    GitlabRedirectComponent, 
    MeetupRedirectComponent, 
    TopcoderAuthComponent, 
    CodewarsAuthComponent, 
    YoutubeRedirectComponent, 
    TwitterRedirectComponent, 
    CodingameAuthComponent, 
    LinkedinRedirectComponent, 
    BitbucketProfileComponent, 
    CodewarsProfileComponent, 
    CodingameProfileComponent,
    GithubProfileComponent, 
    GitlabProfileComponent,  
    LinkedinProfileComponent, 
    MeetupProfileComponent, 
    StackoverflowProfileComponent, 
    TopcoderProfileComponent, 
    TwitterProfileComponent, 
    YoutubeProfileComponent,
    MediumProfileComponent, 
    HackadayProfileComponent, 
    TwitchProfileComponent, MediumRedirectComponent, StackoverflowRedirectComponent, 
  ],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    UiModule
  ],
  providers: [
    GithubService,
    BitbucketService,
    GitlabService,
    MeetupService,
    TopcoderService,
    CodewarsService,
    YoutubeService,
    TwitterService,
    CodingameService,
    LinkedinService
  ],
  exports: [
    BitbucketProfileComponent,
    CodewarsProfileComponent,
    CodingameProfileComponent,
    GithubProfileComponent,
    GitlabProfileComponent,
    StackoverflowProfileComponent,
    TopcoderProfileComponent,
    LinkedinProfileComponent,
    MeetupProfileComponent,
    MediumProfileComponent, 
    TwitterProfileComponent, 
    YoutubeProfileComponent, 
    TwitchProfileComponent
  ],
})
export class IntegrationsModule {
  constructor (@Optional() @SkipSelf() integrations: IntegrationsModule) {
    if(integrations) {
      console.log("Integrations module already running!");
    }
  }
 }
