import {tap, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { AuthService} from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard'; 
import * as _ from 'lodash';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private toast: ToastService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      
        return this.auth.user.pipe(
            take(1),
            map(user => !!user),
            tap(loggedIn => {
                if (!loggedIn) {
                //console.log('access denied')
                this.toast.info('You must be logged in to access this page!')
                this.auth.loginAndRoute("/admin");
                }
                else{
                    this.auth.roles.subscribe(roles =>{
                        if (roles) {
                            if(roles['admin'] === true){
                                return true
                            }
                            else{
                                this.router.navigate(['/']);
                                this.toast.error('You must be admin!');
                                return false
                            }
                        }
                    }) 
                }
            })
        )
    }
}

     

