import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-signup-recruiter-profile',
  templateUrl: './signup-recruiter-profile.component.html',
  styleUrls: ['./signup-recruiter-profile.component.scss']
})
export class SignupRecruiterProfileComponent implements OnInit, OnDestroy {

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  downloadURLString: String;
  isHovering: boolean;
  validationForm: FormGroup;
  currentUser: any;
  fsCollection: AngularFirestoreCollection<any>;

  userSubscribe: Subscription;
  downloadSubscribe: Subscription;

  constructor(
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    public auth: AuthService,
    private afs: AngularFirestore,
    private router: Router)
    {
      this.validationForm = fb.group({
        firstNameFormEx: [null, [Validators.required]],
        lastNameFormEx: [null, [Validators.required]],
        positionFormEx: [null, Validators.required],
        descriptionFormEx: [null, [Validators.required]],
        });
     }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }

  startUpload(event: FileList) {
    const file = event.item(0);
    if(file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
    }
    const path = `ProfileImages/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    const ref = this.storage.ref(path);
    this.downloadSubscribe = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(value => this.downloadURLString = value);
      })
    ).subscribe();
  }

  onSubmit({value}: {value: any}) {
    this.afs.collection('users').doc(this.currentUser.uid).update({
      firstName: value.firstNameFormEx,
      lastName: value.lastNameFormEx,
      position: value.positionFormEx,
      description: value.descriptionFormEx,
      profileImage: this.downloadURLString
    }).then(() => {this.router.navigate([`recruiter/${this.currentUser.uid}`])});
  }

  skip() {
    this.router.navigate([`recruiter/${this.currentUser.uid}`]);
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
    if (this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }
  }
}
