import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter-info-user',
  templateUrl: './recruiter-info-user.component.html',
  styleUrls: ['./recruiter-info-user.component.scss']
})
export class RecruiterInfoUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
