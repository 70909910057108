import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from '../../../environments/environment';
import { TopCoderToken } from './topcoder';
import { HttpClient,  HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TopcoderService {

  private topcoderURL = 'https://api.topcoder.com/v2';
  private topcoderAPIURL = `${environment.apiAddress}/topcoder`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
  }

  async signIn() {
    return new Promise<TopCoderToken>((resolve, reject) => {
      this.auth.launchAuthPopup(`${window.location.origin}/topcoder-auth`, 'TopCoder Auth')
      .then(href => {
        const topCoderToken = new URL(href).searchParams.get('token');
        if (topCoderToken) {
          const newToken: TopCoderToken = {
            token: topCoderToken,
          };
          resolve(newToken);
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {reject(new Error((err)))});
    });
  }

  async getAccessToken(username: string, password: string) {
    return new Promise<TopCoderToken>((resolve, reject) => {
      let params = {
        username: username,
        password: password
      };
      this.http.post<TopCoderToken>(this.topcoderURL + '/auth', params).subscribe(async (res: any) => {
        if (res) {
          const topcoderToken = res.token;
          resolve(topcoderToken);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async getUser(token: any) {
    return new Promise<TopCoderToken>((resolve, reject) => {
      const params = `?accessToken=${token}`;
      this.http.get<TopCoderToken>(this.topcoderAPIURL + '/auth' + params, {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async updateUser() {
    return new Promise<TopCoderToken>((resolve, reject) => {

      this.http.get<TopCoderToken>(this.topcoderAPIURL + '/update', {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async deleteUser() {
    return new Promise<TopCoderToken>((resolve, reject) => {
      this.http.get<TopCoderToken>(this.topcoderAPIURL + '/delete', {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
