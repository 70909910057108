import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';

@Component({
  selector: 'admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss']
})
export class AdminRolesComponent implements OnInit, OnDestroy {
  UserSubscribe: Subscription
  users = []
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.UserSubscribe = this.afs.collection(`users`).valueChanges().subscribe(users => {
      if (users) {
        console.log(users)
        this.users = users
      }
    })
  }
  function(){
    console.log(this.users)

    for(let i=0; i<this.users.length;i++){
      let hacker = this.users[i]['hacker']
      let recruiter = this.users[i]['recruiter']
      console.log(hacker)
      console.log(recruiter)
      this.afs.doc(`roles/${this.users[i].uid}`).set({hacker: hacker, recruiter: recruiter})
    }
  }
  ngOnDestroy() {
    this.UserSubscribe.unsubscribe()
  }

}
