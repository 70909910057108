import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { GithubService } from 'src/app/integrations/github/github.service';
import { BitbucketService } from 'src/app/integrations/bitbucket/bitbucket.service';
import { GitlabService } from 'src/app/integrations/gitlab/gitlab.service';
import { MeetupService } from 'src/app/integrations/meetup/meetup.service';
import { TopcoderService } from 'src/app/integrations/topcoder/topcoder.service';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { CodewarsService } from 'src/app/integrations/codewars/codewars.service';
import { YoutubeService } from 'src/app/integrations/youtube/youtube.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { TwitterService } from 'src/app/integrations/twitter/twitter.service';
import { CodingameService } from 'src/app/integrations/codingame/codingame.service';
import { LinkedinService } from 'src/app/integrations/linkedin/linkedin.service';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { LinkedinAccessToken } from 'src/app/integrations/linkedin/linkedin';
import { MediumService } from 'src/app/integrations/medium/medium.service';
import { StackoverflowService } from 'src/app/integrations/stackoverflow/stackoverflow.service';

@Component({
  selector: 'app-signup-coder-integrations',
  templateUrl: './signup-coder-integrations.component.html',
  styleUrls: ['./signup-coder-integrations.component.scss']
})
export class SignupCoderIntegrationsComponent implements OnInit, OnDestroy {

  toastMessageOpacity: {} = { opacity: 1 };
  githubConnect = false;
  githubLoading = false;
  gitlabConnect = false;
  gitlabLoading = false;
  bitbucketConnect = false;
  bitbucketLoading = false;
  topCoderConnect = false;
  topCoderLoading = false;
  codewarsConnect = false;
  codewarsLoading = false;
  meetupConnect = false;
  meetupLoading = false;
  twitterConnect = false;
  twitterLoading = false;
  youtubeConnect = false;
  youtubeLoading = false;
  codingameConnect = false;
  codingameLoading = false;
  stackOverflowConnect = false;
  stackOverflowLoading = false;
  mediumConnect = false;
  mediumLoading = false;
  twitchConnect = false;
  twitchLoading = false;
  hackadayConnect = false;
  hackadayLoading = false;
  linkedinConnect = false;
  linkedinLoading = false;


  fsCollection: AngularFirestoreCollection<any>;
  currentUser: any;
  param: string;
  routed: boolean = false;
  userSubscribe: Subscription;

  constructor(
    public auth: AuthService,
    private github: GithubService,
    private bitbucket: BitbucketService,
    private gitlab: GitlabService,
    private meetup: MeetupService,
    private topcoder: TopcoderService,
    private codewars: CodewarsService,
    private youtube: YoutubeService,
    private toast: ToastService,
    private twitter: TwitterService,
    private codingame: CodingameService,
    private linkedin: LinkedinService,
    private medium: MediumService,
    private stackoverflow: StackoverflowService,
    private afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.getConnectedIntegrations();
    this.param = this.route.snapshot.queryParamMap.get("profile");
    if (this.param) {
      this.routed = true;
    }
  }

  async connectGithub() {
    this.githubLoading = true;
    await this.github.signIn()
    .then(res => {
      // do something with github here
      //this.toast.success("Successfully Connected to GitHub");
      this.githubConnect = true;
      this.githubLoading = false;
    });
    await this.github.update()
    .then(res => {
      // do something with github here
      this.toast.success("Successfully Updated", "Github", this.toastMessageOpacity);
      console.log('Message: ' + (res));
    })
    .catch(err => {
      // handle failure in the connection of github
      this.githubLoading = false;
      this.toast.error("Failed!", "Github", this.toastMessageOpacity);
      console.log(err);
    });
  }

  async connectBitbucket() {
    this.bitbucketLoading = true;
    await this.bitbucket.signIn()
    .then(res => {
      //this.toast.success(JSON.stringify(res));
      this.bitbucketConnect = true;
      this.bitbucketLoading = false;
    });
    await this.bitbucket.update()
    .then(res => {
      this.toast.success("Successfully Connected", "Bitbucket", this.toastMessageOpacity);
    })
    .catch(err => {
      this.bitbucketLoading = false;
      this.toast.error("Failed!", "Bitbucket", this.toastMessageOpacity);
      console.log(err);
    });
  }

  async connectTopCoder() {
    this.topCoderLoading = true;
    await this.topcoder.signIn()
    .then(async res => {
      this.topCoderConnect = true;
      this.topCoderLoading = false;
      await this.topcoder.getUser(res.token)
      .then(async resol => {
        this.toast.success("Successfully Connected", "TopCoder", this.toastMessageOpacity);
      })
      .catch(err => {
        this.topCoderLoading = false;
        this.toast.error("Failed!", "TopCoder", this.toastMessageOpacity);
        console.log(err);
      });
    })
    .catch(err => {
      this.topCoderLoading = false;
      this.toast.error("Failed!", "TopCoder", this.toastMessageOpacity);
      console.log(err);
    });
  }

  async connectGitlab() {
    this.gitlabLoading = true;
    try {
      await this.gitlab.signIn()
      .then(res => {
        this.toast.success("Successfully Connected", "Gitlab", this.toastMessageOpacity);
        this.gitlabConnect = true;
        this.gitlabLoading = false;
      });
      await this.gitlab.update()
      .then(res => {
        this.toast.success("Updated", "Gitlab", this.toastMessageOpacity);
      })
    }
    catch (err) {
      this.toast.error("Failed!", "Gitlab", this.toastMessageOpacity);
      this.gitlabLoading = false;
      console.log(err);
    }
  }

  async connectMeetup() {
    this.meetupLoading = true;
    await this.meetup.signIn()
    .then(res => {
      this.toast.success("Successfully Connected", "Meetup", this.toastMessageOpacity);
      this.meetupConnect = true;
      this.meetupLoading = false;
    })
    await this.meetup.update()
    .then(res => {
      this.toast.success("Updated", "Meetup", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Failed!", "Meetup", this.toastMessageOpacity);
      this.meetupLoading = false;
      console.log(err);
    });
  }

  async connectCodeWars() {
    this.codewarsLoading = true;
    await this.codewars.signIn()
    .then(res => {
      this.toast.success("Successfully Connected", "CodeWars", this.toastMessageOpacity);
      this.codewarsConnect = true;
      this.codewarsLoading = false;
    })
    .catch(err => {
      this.toast.error("Failed!", "CodeWars", this.toastMessageOpacity);
      this.codewarsLoading = false;
      console.log(err);
    });
  }

  async connectYouTube() {
    this.youtubeLoading = true;
    await this.youtube.signIn()
    .then(res => {
      this.toast.success("Successfully Connected", "YouTube", this.toastMessageOpacity);
      this.youtubeConnect = true;
      this.youtubeLoading = false;
    })
    .catch(err => {
      this.toast.error("Failed!", "YouTube", this.toastMessageOpacity);
      this.youtubeLoading = false;
      console.log(err);
    });
  }

  async connectTwitter() {
    this.twitterLoading = true;
    await this.twitter.signIn()
    .then(res => {
      this.toast.success("Successfully Authenticated", "Twitter", this.toastMessageOpacity);
      this.twitterConnect = true;
      this.twitterLoading = false;
    })
    .catch(err => {
      this.twitterLoading = false;
      console.log(err);
    });
    await this.twitter.getUser()
    .then(res => {
      this.toast.success("Successfully Connected", "Twitter", this.toastMessageOpacity);
    })
    .catch(err => {
      this.twitterLoading = false;
      this.toast.error("Failed!", "Twitter", this.toastMessageOpacity);
      console.log(err);
    });
  }

  async connectCodingame() {
    this.codingameLoading = true;
    await this.codingame.signIn()
    .then(res => {
      this.toast.success("Successfully Connected", "CodinGame", this.toastMessageOpacity);
      //this currently returns the profile but should really write it to the DB from the cloud
      //takes about 30 seconds to execute
      this.codingame.getUser(res)
      .then(profile => {
        this.toast.success("Successfully Retrieved Profile", "CodinGame", this.toastMessageOpacity);
      })
      .catch(err => {
        this.toast.error("Failed!", "CodinGame", this.toastMessageOpacity);
        console.log(err)
      });

      this.codingameConnect = true;
      this.codingameLoading = false;
    })
    .catch(err => {
      this.toast.error("Failed!", "CodinGame", this.toastMessageOpacity);
      this.codingameLoading = false;
      console.log(err);
    });
  }

  async connectLinkedin() {
    this.linkedinLoading = true;
    let accesstoken: LinkedinAccessToken;
    await this.linkedin.signIn()
    .then(res => {
      accesstoken = res;
      this.toast.success("Successfully Authenticated", "LinkedIn", this.toastMessageOpacity);
      this.linkedinConnect = true;
      this.linkedinLoading = false;
    })
    .catch(err => {
      this.toast.error("Auth Failed!", "LinkedIn", this.toastMessageOpacity);
      this.linkedinLoading = false;
      console.log(err);
    });
    await this.linkedin.getUser(accesstoken.vanityName)
    .then(res => {
      this.toast.success("Success", "LinkedIn", this.toastMessageOpacity);
    })
    .catch(err => {
      this.linkedinLoading = false;
      this.toast.error("Profile Data Failed!", "LinkedIn", this.toastMessageOpacity);
      console.log(err);
    });
  }

  async connectMedium() {
/*     this.toast.info("Coming Soon!", "Medium", this.toastMessageOpacity); */
    this.mediumLoading = true;
await this.medium.signIn()
    .then(res => {
      this.toast.success("Successfully Connected to Medium");
      console.log('Message: ' + (JSON.stringify(res)));
      this.mediumConnect = true;
      this.mediumLoading = false;
    })    
    await this.medium.update()
    .then(res => {
      this.toast.success("Updated", "Medium", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Failed!", "Medium", this.toastMessageOpacity);
      this.mediumLoading = false;
      this.toast.error(JSON.stringify(err));
      console.log(err);
    });

  }

  async connectStackOverflow() { /////////////
    // this.toast.info("Coming Soon!", "StackOverFlow", this.toastMessageOpacity);
    this.stackOverflowLoading = true;
    await this.stackoverflow.signIn()
    .then(res => {
      this.toast.success("Successfully Connected to StackOverflow");
      console.log('Message: ' + (JSON.stringify(res)));
      this.stackOverflowConnect = true;
      this.stackOverflowLoading = false;
    })    
    await this.stackoverflow.update()
    .then(res => {
      this.toast.success("Updated", "StackOverflow", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Failed!", "StackOverflow", this.toastMessageOpacity);
      this.stackOverflowLoading = false;
      this.toast.error(JSON.stringify(err));
      console.log(err);
    });
  }


  // Update
  async updateGithub() {
    await this.github.update()
    .then(res => {
      // do something with github here
      this.toast.success("Successfully Updated GitHub");
    })
    .catch(err => {
      // handle failure in the connection of github
      this.toast.error("Github Update Failed!");
      console.log(err);
    });
  }

  async updateBitbucket() {
    await this.bitbucket.update()
    .then(res => {
      this.toast.success("Successfully Updated Bitbucket");
    })
    .catch(err => {
      this.toast.error("Bitbucket Update Failed");
      console.log(err);
    });
  }

  async updateTopCoder() {
    await this.topcoder.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated TopCoder");
    })
    .catch(err => {
      this.toast.error("TopCoder Update Failed");
      console.log(err);
    });
  }

  async updateGitlab() {
    await this.gitlab.update()
    .then(res => {
      this.toast.success("Successfully Updated Gitlab");
    })
    .catch(err => {
      this.toast.error("Gitlab Update Failed");
      console.log(err);
    });
  }

  async updateMeetup() {
    await this.meetup.update()
    .then(res => {
      this.toast.success("Successfully Updated Meetup");
    })
    .catch(err => {
      this.toast.error("Meetup Update Failed");
      console.log(err);
    });
  }

  async updateMedium() {
    await this.medium.update()
    .then(res => {
      this.toast.success("Successfully Updated Medium");
    })
    .catch(err => {
      this.toast.error("Medium Update Failed");
      console.log(err);
    });
  }

  async updateCodeWars() {
    await this.codewars.update()
    .then(res => {
      this.toast.success("Successfully Updated Codewars");
    })
    .catch(err => {
      this.toast.error("CodeWars Update Failed");
      console.log(err);
    });
  }

  async updateCodingame() {
    await this.codingame.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated CodinGame");
    })
    .catch(err => {
      this.toast.error("CodinGame Update Failed");
      console.log(err);
    });
  }

  async updateTwitter() {
    await this.twitter.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated Twitter");
    })
    .catch(err => {
      this.toast.error("Twitter Update Failed");
      console.log(err);
    });
  }

  async updateLinkedin() {
    await this.linkedin.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated Linkedin");
    })
    .catch(err => {
      this.toast.error("LinkedIn Update Failed");
      console.log(err);
    });
  }

  // Delete
  async deleteGithub() {
    this.githubLoading = true;
    await this.github.delete()
    .then(res => {
      this.githubConnect = false;
      this.githubLoading = false;
      // do something with github here
      this.toast.success("Successfully Deleted", "Github", this.toastMessageOpacity);
    })
    .catch(err => {
      // handle failure in the connection of github
      this.toast.error("Delete Failed", "Github", this.toastMessageOpacity);
      this.githubLoading = false;
      console.log(err);
    });
  }

  async deleteBitbucket() {
    this.bitbucketLoading = true;
    await this.bitbucket.delete()
    .then(res => {
      this.bitbucketConnect = false;
      this.bitbucketLoading = false;
      this.toast.success("Successfully Deleted", "BitBucket", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Delete Failed", "Bitbucket", this.toastMessageOpacity);
      this.bitbucketLoading = false;
      console.log(err);
    });
  }

  async deleteTopCoder() {
    this.topCoderLoading = true;
    await this.topcoder.deleteUser()
    .then(res => {
      this.topCoderConnect = false;
      this.topCoderLoading = false;
      this.toast.success("Successfully Deleted", "TopCoder", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Delete Failed", "TopCoder", this.toastMessageOpacity);
      this.topCoderLoading = false;
      console.log(err);
    });
  }

  async deleteGitlab() {
    this.gitlabLoading = true;
    await this.gitlab.delete()
    .then(res => {
      this.gitlabConnect = false;
      this.gitlabLoading = false;
      this.toast.success("Successfully Deleted", "Gitlab", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Delete Failed", "Gitlab", this.toastMessageOpacity);
      this.gitlabLoading = false;
      console.log(err);
    });
  }

  async deleteMeetup() {
    this.meetupLoading = true;
    await this.meetup.delete()
    .then(res => {
      this.meetupConnect = false;
      this.meetupLoading = false;
      this.toast.success("Successfully Deleted", "Meetup", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Meetup Delete Failed");
      this.meetupLoading = false;
      console.log(err);
    });
  }

  async deleteMedium() {
    this.mediumLoading = true;
    await this.medium.delete()
    .then(res => {
      this.mediumConnect = false;
      this.mediumLoading = false;
      this.toast.success("Successfully Deleted", "Medium", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("Medium Delete Failed");
      this.mediumLoading = false;
      console.log(err);
    });
  }

  async deleteStackOverflow() { ////////////////
    this.stackOverflowLoading = true;
    await this.stackoverflow.delete()
    .then(res => {
      this.stackOverflowConnect = false;
      this.stackOverflowLoading = false;
      this.toast.success("Successfully Deleted", "StackOverflow", this.toastMessageOpacity);
    })
    .catch(err => {
      this.toast.error("StackOverflow Delete Failed");
      this.stackOverflowLoading = false;
      console.log(err);
    });
  }

  async deleteCodeWars() {
    this.codewarsLoading = true;
    await this.codewars.delete()
    .then(res => {
      this.codewarsConnect = false;
      this.codewarsLoading = false;
      this.toast.success("Successfully Deleted Codewars");
    })
    .catch(err => {
      this.toast.error("CodeWars Delete Failed");
      this.codewarsLoading = false;
      console.log(err);
    });
  }

  async deleteCodingame() {
    this.codingameLoading = true;
    await this.codingame.deleteUser()
    .then(res => {
      this.codingameConnect = false;
      this.codingameLoading = false;
      this.toast.success("Successfully Deleted CodinGame");
    })
    .catch(err => {
      this.toast.error("CodinGame Delete Failed");
      this.codingameLoading = false;
      console.log(err);
    });
  }

  async deleteTwitter() {
    this.twitterLoading = true;
    await this.twitter.deleteUser()
    .then(res => {
      this.twitterConnect = false;
      this.twitterLoading = false;
      this.toast.success("Successfully Deleted Twitter");
    })
    .catch(err => {
      this.twitterLoading = false;
      console.log(err);
    });
  }

  async deleteLinkedin() {
    this.linkedinLoading = true;
    await this.linkedin.deleteUser()
    .then(res => {
      this.linkedinConnect = false;
      this.linkedinLoading = false;
      this.toast.success("Successfully Deleted LinkedIn");
    })
    .catch(err => {
      this.toast.error("LinkedIn Delete Failed");
      this.linkedinLoading = false;
      console.log(err);
    });
  }

  async next(){
    this.router.navigate(['/signup/coder-profile']);
  }

  getConnectedIntegrations() {
    // Get current user
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    // Check if user is connected to github
    this.fsCollection = this.afs.collection('github');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.githubConnect = true;
      }
    });

    // Check if user is connected to gitlab
    this.fsCollection = this.afs.collection('gitlab');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.gitlabConnect = true;
      }
    });

    // Check if user is connected to bitbucket
    this.fsCollection = this.afs.collection('bitbucket');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.bitbucketConnect = true;
      }
    });

    // Check if user is connected to twitter
    this.fsCollection = this.afs.collection('twitter');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.twitterConnect = true;
      }
    });
    // Check if user is connected to codingame
    this.fsCollection = this.afs.collection('codingame');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.codingameConnect = true;
      }
    });
    // Check if user is connected to linkedin
    this.fsCollection = this.afs.collection('linkedin');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.linkedinConnect = true;
      }
    });
    // Check if the user is connected to twitch

    // Check if the user is connected to stackoverflow

    // Check if user is connected to topcoder
    this.fsCollection = this.afs.collection('topcoder');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.topCoderConnect = true;
      }
    });

    // Check if user is connected to codewars
    this.fsCollection = this.afs.collection('codewars');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.codewarsConnect = true;
      }
    });

    // Check if user is connected to meetup
    this.fsCollection = this.afs.collection('meetup');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.meetupConnect = true;
      }
    });

    // Check if the user is connected to youtube

    // Check if the user is connected to hackaday

    // Check if the user is connected to medium
    this.fsCollection = this.afs.collection('medium');
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.mediumConnect = true;
      }
    });

    // Check if the user is connected to stackoverflow ////////// 
    this.fsCollection = this.afs.collection('stackoverflow'); 
    this.fsCollection.doc(this.currentUser.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.stackOverflowConnect = true;
      }
    });

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
  }

}
