import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-hackaday-profile',
  templateUrl: './hackaday-profile.component.html',
  styleUrls: ['./hackaday-profile.component.scss']
})
export class HackadayProfileComponent implements OnInit, OnChanges, OnDestroy {
  user: User;
  hackadayUser: any;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  APIKey: string = "";
  isCollapsed: any[];
  uid: any;
  loaded: any;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true

  // New chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;

  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private _http: Http) { }

  private hackadayHtml() {

  }

  private linkHacakadayAccount() {

  }

  Update() {
  }

  ngOnInit() { }

  ngOnChanges() {

  }
  ngOnDestroy() {
  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  private updatePieChart() { 

  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }

  Delete() {

  }

}
