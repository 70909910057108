import { Component, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Event, NavigationCancel, Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  title = 'cyferlinx';
  loading = true;
  isBrowser = false;
  routerSubscribe: Subscription;

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object){
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.routerSubscribe = this.router.events.subscribe((event: Event) => {
        switch(true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false;
            break;
          }
          default: { 
            break;
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.routerSubscribe) {
      this.routerSubscribe.unsubscribe();
    }
  }
}
