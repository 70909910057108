import { Component, OnInit, NgModule } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {

  constructor(private title:Title, private meta:Meta) { }

  ngOnInit() {
    this.title.setTitle('CYFERLINX SIGNUP')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + '/signup'},
      { property: 'og:title', content: 'CYFERLINX SIGNUP'},
      { property: 'og:description', content: 'Signup to Cyferlinx to create your tech passport'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'Signup to Cyferlinx to create your tech passport'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content: 'CYFERLINX SIGNUP'},
      { name: 'twitter:description', content: 'Signup to Cyferlinx to create your tech passport'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
  }

}
