import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User, StackUser } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { StackoverflowService } from '../stackoverflow.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-stackoverflow-profile',
  templateUrl: './stackoverflow-profile.component.html',
  styleUrls: ['./stackoverflow-profile.component.scss']
})
export class StackoverflowProfileComponent implements OnInit { //}, OnChanges, OnDestroy {
  user: User;
  userSubscribe: Subscription;
  stackUserSubscribe: Subscription;
  stackUser: any;
  uid:any;
  stackToggle: boolean = false;
  edit = false;
  loading: boolean = false;
  
  @Input() enableEditParam: boolean = true;
  @Input() uidParam: string;
  @Input() disable: boolean = true;


  constructor(private auth: AuthService, private stack: StackoverflowService, private afs: AngularFirestore, private toast: ToastService) { }

  ngOnInit() {
    if(this.uidParam){
      this.afs.collection('stackoverflow').doc(this.uidParam).ref.get().then(doc => {
        if (doc.exists) {
          this.stackUser = doc.data().items[0];
        }
      });
    }
  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }

  async Update() {
    this.loading = true;
    await this.stack.update().then(res => {
      this.toast.success("Successfully Updated StackOverflow");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
  }
  
  async Delete() {
    this.loading = true;
    await this.stack.delete().then(res => {
      this.toast.success("Successfully Deleted StackOverflow");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of meetup
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
    this.stackUser = null;
  }



  ///////////////////// Below is original code over here

  // private linkStackAccount(){
  //   // this.stack.authenticate();  
  // }
  // Update(){
  //   // this.stack.signIn(this.uid,this.stackUser.accessToken,this.stackUser.expires);
  //   //this.stack.authenticate();  
  // }

  // /*ngOnInit() {
  //   this.userSubscribe = this.auth.currentUser.subscribe((user) => {
  //     this.user = user;
  //     if (this.user){
  //       this.stackUserSubscribe =  this.stack.getUser(user.uid).subscribe(stack => {
  //         if (stack){
  //           this.stackUser = stack;
  //         }
  //       });
  //     }
  //   })
  // }*/
  // ngOnChanges(){
  //   if (this.uidParam){
  //     this.uid = this.uidParam;
  //     this.stackToggle = false;
  //     //this.stackUserSubscribe =  this.stack.getUser(this.uid).subscribe(stack => {
  //     const path = `stackoverflow/${this.uidParam}`; 
  //     this.stackUserSubscribe = this.afs.doc<StackUser>(path).valueChanges().subscribe(stack =>{
  //       if (stack){
  //         this.stackUser = stack;
  //       }
  //       if(stack == null){
  //         this.stackUser = null;
  //       }
  //     });
  //     if(this.enableEditParam==true){
  //       this.stackToggle=true;
  //     }

  //   }
  // }
  // Delete(){
  //   // this.stack.DeleteStackData(this.uid)
  //   this.stackUser = null;
  // }
  // enableEdit() {
  //   this.edit = true;
  //  }
  //  disableEdit() {
  //   this.edit = false;
  // }
  // stackToggleSetting(){
  //   this.stackToggle = !this.stackToggle 
  //   //console.log(this.githubToggle)
  // }
  // ngOnDestroy(){
  //   if(this.userSubscribe){
  //     this.userSubscribe.unsubscribe();
  //   }
  //   if(this.stackUserSubscribe){
  //     this.stackUserSubscribe.unsubscribe();
  //   }
    
  // }

}
