import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-recruiter',
  templateUrl: './signup-recruiter.component.html',
  styleUrls: ['./signup-recruiter.component.scss']
})
export class SignupRecruiterComponent implements OnInit {

  termsAccept = false;
  validationForm: FormGroup;
  loading: boolean = false;

  constructor(private auth: AuthService, private router: Router, public fb: FormBuilder) {
    this.validationForm = fb.group({
      emailFormEx: [null, [Validators.required, Validators.email]],
      emailRetypeFormEx: [null, [Validators.required, Validators.email]],
      passwordFormEx: [null, Validators.required],
    });
  }

  ngOnInit() {
  }


  changeTerms() {
    this.termsAccept = !this.termsAccept;
  }

  onSubmit(formData: any) {
    const hacker = false;
    this.loading = true;
    this.auth.emailSignUpRecruiter(this.emailFormEx.value, this.passwordFormEx.value, hacker)
      .then(async () => {
        this.loading = false;
        this.router.navigate(['/signup/recruiter-company']);
        await this.auth.setNewUser();
      })
      .catch(error => { 
        console.log(error);
        this.loading = false; 
        this.router.navigate(['/signup/recruiter']);
      });
  }

  get emailFormEx() { return this.validationForm.get('emailFormEx'); }
  get passwordFormEx() { return this.validationForm.get('passwordFormEx'); }

}
