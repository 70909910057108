
import {tap, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { AuthService} from '../auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard'; 
import * as _ from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private toast: ToastService) {}
  userSub: Subscription
  roleSub: Subscription
  user: any
  roles: any
  messageUsed: boolean
  loop = false

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.auth.user.pipe(
        take(1),
        map(user => !!user),
        tap(async loggedIn => {
          console.log('auth guard')
          if (!loggedIn) {
                this.router.navigate(['/login'],{queryParams:{'redirectRoute':this.router.url}});
                return false;
            }
            else {
                return true;
            }
        })
    )
}
}


