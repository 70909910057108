import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import {User} from '../../core/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signup-coder-profile-info',
  templateUrl: './signup-coder-profile-info.component.html',
  styleUrls: ['./signup-coder-profile-info.component.scss']
})
export class SignupCoderProfileInfoComponent implements OnInit, OnDestroy {
  validationForm: FormGroup;
  userUid: any;
  addLanguages: string[] = [];
  addInterests: string[] = [];
  languagesToggle = false;
  userSubscribe: Subscription;

  constructor(private afs: AngularFirestore, private fb: FormBuilder, private auth: AuthService, private router: Router) {
    this.validationForm = fb.group({
      firstNameFormEx: [null],
      lastNameFormEx: [null],
      videoFormEx: [null],
      descFormEx: [null],
      phoneFormEx: [null],
      websiteFormEx: [null],
      interestSkillsFormEx: [null]
    });
   }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      if (user) {
        this.userUid = user.uid;
        // Auto Redirect is user data found
        // console.log(user);
        if (user.firstName !== 'DefaultName' || user.lastName || user.video || user.blurb) {
          this.router.navigate([`coder/${this.userUid}`]);
        }
      }
    });
  }

  get firstNameFormEx() { return this.validationForm.get('firstNameFormEx'); }
  get lastNameFormEx() { return this.validationForm.get('lastNameFormEx'); }
  get videoFormEx() { return this.validationForm.get('videoFormEx'); }
  get descFormEx() { return this.validationForm.get('descFormEx'); }
  get phoneFormEx() { return this.validationForm.get('phoneFormEx'); }
  get websiteFormEx() { return this.validationForm.get('websiteFormEx'); }
  get interestSkillsFormEx() { return this.validationForm.get('interestSkillsFormEx'); }

  onSubmit() {
    const skills: string[] = [];
    skills.push(this.interestSkillsFormEx.value);
    this.afs.collection('users').doc<User>(this.userUid).update({
      
      firstName: this.firstNameFormEx.value,
      lastName: this.lastNameFormEx.value,
      video: this.videoFormEx.value,
      blurb: this.descFormEx.value,
      website: this.websiteFormEx.value,
      phone: this.phoneFormEx.value,
      skills: skills
    });
    this.router.navigate([`coder/${this.userUid}`]);
  }

  done() {
    this.router.navigate([`coder/${this.userUid}`]);
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
  }
}
