import { Component, OnInit, NgModule } from '@angular/core';
import {SharedModule} from '../shared/shared.module'
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

@NgModule({
  imports: [SharedModule]
})
export class HomeComponent implements OnInit {

  constructor(private title:Title, private meta:Meta) { }

  ngOnInit() {
    this.title.setTitle('CYFERLINX')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + ''},
      { property: 'og:title', content: 'CYFERLINX'},
      { property: 'og:description', content: 'CYFERLINX makes life easy for coders by linking all their coding platform accounts together.'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'CYFERLINX makes life easy for coders by linking all their coding platform accounts together'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content:environment.metadata.name},
      { name: 'twitter:description', content: 'CYFERLINX makes life easy for coders by linking all their coding platform accounts together.'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
  }

}
