import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-sync',
  templateUrl: './local-sync.component.html',
  styleUrls: ['./local-sync.component.scss']
})
export class LocalSyncComponent implements OnInit {

  syncs : [
    { src: string, title: string, description: string }, 
    { src: string, title: string, description: string }, 
    { src: string, title: string, description: string }
  ];

  windowsDownload: string = "https://firebasestorage.googleapis.com/v0/b/hackerlinxdev-a70df.appspot.com/o/LocalSyncDownloads%2Fangular_electron-7.0.0-win.zip?alt=media&token=8ad47ef3-73cd-49a9-a507-cf236cd81c68"

  constructor() { }

  ngOnInit() {
    this.syncs = [
      {src: "sync-1.svg", title: "Select a repo", description: "Select a local repo to analyse"},
      {src: "sync-2.svg", title: "Analyse code stats", description: "Analyse the repo code stats and review"},
      {src: "sync-3.svg", title: "Sync to profile", description: "Sync only the stats to your profile"}
    ]
  }

  openVideo() {
    console.log("");
  }

}
