import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Observable,of, from, BehaviorSubject } from 'rxjs';
//Environment variables
import { environment } from '../../../environments/environment';
import { GithubAccessToken} from './github';



@Injectable({
  providedIn: 'root'
})
export class GithubService {

  private githubClientId = environment.githubConfig.client_id;
  private githubApiUrl = `${environment.apiAddress}/github`;
  private rootURL = 'https://github.com/login/oauth';
  private authUrl = this.rootURL + '/authorize' + `?client_id=${this.githubClientId}&scope=user:email`; 
  public accesstoken: BehaviorSubject<any> = new BehaviorSubject(null);
  private httpHeaders: HttpHeaders;


  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => {
      this.httpHeaders = newHeaders
      
    });

  }

  async signIn() {
    return new Promise<GithubAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'Github Auth')
      .then(href => {
        const githubCode = new URL(href).searchParams.get( 'code');
        if (githubCode) {
          this.getAccessToken(githubCode)
          .then(accesstoken => {resolve (accesstoken)})
          .catch(err => {reject(new Error('auth failed'))});
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {reject(new Error((err)))});
    });

  }

  private getAccessToken(code: string) {
    return new Promise<GithubAccessToken>((resolve, reject) => {
      const params = `?code=${code}`;
      this.http.get<GithubAccessToken>(this.githubApiUrl + '/auth' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.githubApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }


  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.githubApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
