import { Injectable, ReflectiveInjector } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth} from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestoreModule} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { WindowService } from '../window/window.service';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { Observable, BehaviorSubject, of} from 'rxjs';
import {take, switchMap} from 'rxjs/operators';
import { User } from '../user';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { resolve } from 'url';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private windowHandle: any;
  private intervalId: any;
  private intervalLength = 100;  //ms per loop
  private loopCount = 1800;   //180 seconds timeout based on 100ms interval
  user: Observable<User> = of(null);
  userData: User;
  role: Observable<any> = of(null);
  currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  roles: BehaviorSubject<any> = new BehaviorSubject(null);
  httpHeaders: HttpHeaders = null;
  httpHeadersSubject: BehaviorSubject<HttpHeaders> = new BehaviorSubject(null);
  tempUser: User;
  roleResult: any;
  private userApiUrl = `${environment.apiRoute}/user`;
  constructor(private popupWindow: WindowService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private toast: ToastService,
    private router: Router,
    private http: HttpClient){ 
      //// Get auth data, then get firestore user document || null

      this.user.pipe(switchMap(() =>
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            const verified = this.afAuth.auth.currentUser.emailVerified;
            this.getVerifyToken()
            .then(res => {
              this.httpHeaders = new HttpHeaders().set('id_token', res);
              // console.log(`http header = ${JSON.stringify(this.httpHeaders)}`);
              this.httpHeadersSubject.next(this.httpHeaders);
            })
            .catch(err => {console.log('verify error')});
            this.getUserType()
            .then(res => {
              this.role = JSON.parse(res);
              this.roles.next(this.role);
            });
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {

            return of(null)
          }
        })
      )
    )).subscribe(this.currentUser)
  }

  //Returns the user object of current user
  public getUser(): Promise<User> {
    return new Promise<User> ((resolve) => {
      this.currentUser.subscribe(user =>{
          resolve(user);
      })
    })
  }

  public getUserInfo(uid): Promise<User> {
    return new Promise<User> ((resolve) => {
      this.afs.doc<User>(`users/${uid}`).valueChanges().subscribe(user => resolve(user))
    })
  }

  //Get Role
  public getRole(): Promise<any> {
    return new Promise<any> ((resolve) => {
      this.roles.subscribe(roles =>{
          resolve(roles);
      })
    })
  }

  //Get headers
  public getHeaders(): Promise<any> {
    return new Promise<any> ((resolve) => {
      this.httpHeadersSubject.subscribe(headers =>{
          resolve(headers);
      })
    })
  }


  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        if(user){
          this.user = this.afs.doc<User>(`users/${this.afAuth.auth.currentUser.uid}`).valueChanges();
        }
      })
    .catch(error => this.handleError(error) );
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    return firebase.auth().sendPasswordResetEmail(email)
      .then(() => this.toast.success('Password Reset Email Sent'))
      .catch((error) => this.handleError(error) )
  }

  //// Sign Out ////
  signOut() {
    this.afAuth.auth.signOut().then(() => {
        this.loginAndRoute("/profile");
        this.user = null;
        this.currentUser.next(null);
        this.roles.next(null);
    });
  }

  changeTermsOfUse(user, value){
    const returnValue = new BehaviorSubject(false);
    this.afs.doc(`users/${user}`).update({ 'termsAccepted': value
    }).then((doc) => {returnValue.next(true)});
    return returnValue;
  }

  public launchAuthPopup (url: string, windowName: string) {
    var loop = this.loopCount;
    var href: string;
    this.windowHandle = this.popupWindow.createWindow(url, windowName);
    return new Promise<string>((resolve, reject) => {
      this.intervalId = setInterval(() => {

        if (loop-- < 0) {
          clearInterval(this.intervalId);
          this.windowHandle.close();
          reject(new Error("timeout"));
        }
  
        if (this.windowHandle.closed) {
          href = this.windowHandle.location.href ? this.windowHandle.location.href : null ;
          clearInterval(this.intervalId);
          if (href) {
            resolve(href);
          }
          else {
            reject(new Error("no href"));
          }
        }
      }, this.intervalLength)
    })
  }
  //// Email/Password Auth ////

  async emailSignUp(email: string, password: string, hacker: boolean, location: any) {
    await this.signUp('coder', email, password, hacker, location)
    .then(() => {}).catch(err => {
      this.toast.error(err.error)
    })
  }


  async emailSignUpRecruiter(email: string, password: string, hacker: boolean) {
    await this.signUp('recruiter', email, password, hacker, location)
    .then(() => {}).catch(err => {
      this.toast.error(err.error)
    })
  }

  async setNewUser() {
    await firebase.auth().currentUser.sendEmailVerification()
    .then(() => this.toast.info('Verification email sent'))
    .catch( error => {console.log(error);});
    await this.getVerifyToken()
    .then(res => {
      this.httpHeaders = new HttpHeaders().set('id_token', res);
      this.httpHeadersSubject.next(this.httpHeaders);
    })
    .catch(err => {console.log('verify error')});
    await this.getUserType()
    .then(res => {
      this.roleResult = JSON.parse(res);
      this.role = this.roleResult;
      this.roles.next(this.role);
    });
    await this.sendNewUserEmail();
  }

  deleteProfile() {
    return new Promise<any>((resolve,reject) => {
      const httpOptions = {
        headers: this.httpHeaders
      }
      this.http.get(this.userApiUrl + `/delete`, httpOptions).subscribe( res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'))
        }
      });
    });
  }

  public getVerifyToken() {
    return new Promise<string>((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {resolve(idToken)})
      .catch(err => {reject(new Error(err))});
    });
  }

  public loginAndRoute (redirectRoute: string) {
    this.router.navigate(['/login'],{queryParams:{'redirectRoute': redirectRoute}});
  }

  private getUserType() {
    return new Promise<string>((resolve,reject) => {
      firebase.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        resolve(JSON.stringify(idTokenResult.claims));
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
    });
  }

  private setUserToCoder() {
    return new Promise<string>((resolve, reject) => {
      this.http.get<any>(environment.apiRoute + `/user/setCoder`, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  private setUserToRecruiter() {
    return new Promise<string>((resolve, reject) => {
      this.http.get<any>(environment.apiRoute + '/user/setRecruiter', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
  getRolesByUID(uid: string) {
    return new Promise<any>(async (resolve, reject) => {
      this.http.get<any>(environment.apiRoute + '/user/getRoles/' + uid, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  private sendNewUserEmail() {
    return new Promise<string>(async (resolve, reject) => {
      this.http.get<any>(environment.apiRoute + '/user/newUserEmail', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  public applicationSubmision(positionUID) {
    return new Promise<any>(async (resolve, reject) => {
      //await this.getVerifyToken()
      this.http.get<any>(environment.apiRoute + '/user/applicationSubmission/'+positionUID, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  private handleError(error) {
    this.toast.error(error.message)
  }

  addNewEmail(userId, newEmail) {
    this.afs.doc(`users/${userId}`).ref.get().then(async doc => {
      if (doc.exists) {
        const user = doc.data();
        const emails = user.verifiedEmails;
        if (!this.isVerifiedEmail(emails, newEmail)) {
          await this.getVerifyToken()
          .then(res => {
            this.httpHeaders = new HttpHeaders().set('id_token', res);
            this.httpHeadersSubject.next(this.httpHeaders);
          });
          await this.updateEmails(newEmail).then(()=>{
            this.toast.success("Successfully verified email");
          });
          this.router.navigate([`/coder/${userId}`])
        }
      }
    })
  }

  private isVerifiedEmail(allEmails, email) {
    let found = false;
    allEmails.forEach(verified => { // check if new email in list of verified emails
      if (email.toLowerCase() == verified.toLowerCase()) {
        found = true;
      }
    });
    return found;
  }

  public updateEmails(email) {
    return new Promise<any>(async (resolve, reject) => {
      this.http.get<any>(environment.apiRoute + `/updateEmails/${email}`, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('could not add email: ' + email));
        }
      });
    });
  }

  public async verifyEmail(email) {
    return new Promise<any>(async (resolve, reject) => {
      this.http.get<any>(environment.apiRoute + `/sendVerificationEmail/${email}`, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('could not verify email: ' + email));
        }
      });
    });
  }

  public async signUp(accountType, email, password, hacker, location) {
    return new Promise<any>(async (resolve, reject) => {
      this.http.get<any>(environment.apiRoute + `/user/signup/${accountType}/${email}/${password}`, {headers: this.httpHeaders}).subscribe(async res => {
        if (res) {
          const userId = res.uid;
          if (userId && accountType == "coder") {
            const locationData = new firebase.firestore.GeoPoint(location.lat, location.lng)
            this.tempUser = {
              uid: res.uid,
              firstName: email.substring(0,email.indexOf("@")),
              lastName: "",
              email: res.email,
              photoURL: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              selectedAvatarURL: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              avatarPath: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              preferredAvatar: "upload",
              hacker: hacker,
              city: location.description,
              location: locationData,
              termsAccepted: true,
              verified: false,
              verifiedEmails: []
            };
          } else if (userId && accountType == "recruiter") {
            this.tempUser = {
              uid: res.uid,
              firstName: email.substring(0,email.indexOf("@")),
              lastName: "",
              email: res.email,
              photoURL: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              selectedAvatarURL: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              avatarPath: "https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/avatar.png?alt=media&token=b844fa9a-d232-4315-b6ee-0a256978bb9b",
              preferredAvatar: "upload",
              termsAccepted: true,
              verified: false,
              positions: []
            };
          }
          await this.emailLogin(email,password)
          await this.afs.doc(`users/${userId}`).set(this.tempUser)
          resolve(res);
        }
      }, error => reject(error))
    })
  }
}
