import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CodingameService } from '../codingame.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-codingame-profile',
  templateUrl: './codingame-profile.component.html',
  styleUrls: ['./codingame-profile.component.scss']
})
export class CodingameProfileComponent implements OnInit, OnChanges, OnDestroy {
  user: User;
  userSubscribe: Subscription;
  codingameUser: any;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  profileURL: string;
  level: string;
  school: string;
  biography: string;
  medals: any[] = [];
  achievements: any[] = [];
  APIKey: string = "";
  isCollapsed: any[];
  uid: any;
  loaded: any;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true;
  loading: boolean = false;
  existingUser: boolean = false;

  // New chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;
  @Input() disable: boolean = true;
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private _http: Http,
    private codingame: CodingameService) { }

  async Update() {
    this.loading = true;
    await this.codingame.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated CodinGame");
      this.loading = false;
    })
    .catch(err => {
      this.toast.error("CodinGame Update Failed");
      console.log(err);
      this.loading = false;
    });
  }

  ngOnInit() {
    if(this.uidParam){
      this.afs.collection('codingame').doc(this.uidParam).ref.get().then(doc => {
        if (doc.exists) {
          this.codingameUser = doc.data();
          this.existingUser = true;
          this.profileURL = this.codingameUser.profileURL;
          this.school = this.codingameUser.school;
          this.level = this.codingameUser.level;
          this.medals = this.codingameUser.medals;
          this.achievements = this.codingameUser.achievements;
          this.biography = this.codingameUser.biography;
        }
      })
    }
  }

  ngOnChanges() {

  }
  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  private updatePieChart() {

  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }

  async Delete() {
    this.loading = true;
    await this.codingame.deleteUser()
    .then(res => {
      this.loading = false;
      this.toast.success("Successfully Deleted CodinGame");
    })
    .catch(err => {
      this.toast.error("CodinGame Delete Failed");
      this.loading = false;
      console.log(err);
    });
  }

}
