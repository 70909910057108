import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ToastService } from 'ng-uikit-pro-standard';
import { environment } from '../../../environments/environment';
import { WindowService } from 'src/app/core/window/window.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-beta-signup',
  templateUrl: './beta-signup.component.html',
  styleUrls: ['./beta-signup.component.scss']
})
export class BetaSignupComponent implements OnInit, OnDestroy {

  termsAccept = false;
  validationForm: FormGroup;
  loading: boolean = false;
  private apiUrl = `${environment.apiRoute}`;
  json = {};

  submitSubscribe: Subscription;

  constructor(private auth: AuthService, private router: Router, public fb: FormBuilder, private http: HttpClient, private toast: ToastService, private popupWindow: WindowService) {
    this.validationForm = fb.group({
      emailFormEx: [null, [Validators.required, Validators.email]],
      nameFormEx: [null, Validators.required],
      companyFormEx: [null, Validators.required],
    });
  }

  ngOnInit() {
  }

 async onSubmit(formData: any) {
    this.loading = true;
    this.json["question1"] = "";
    this.json["Other"] = this.nameFormEx.value;
    this.json["question2"] = "";
    this.json["question3"] = "";
    this.json["question4"] = "";
    this.json["email"] = encodeURIComponent(this.emailFormEx.value);
    this.json["company"] = this.companyFormEx.value;
    this.json["subject"] = `New Beta Tester - ${this.nameFormEx.value}`;
    const params: string = `?answers=${JSON.stringify(this.json)}`;
    this.submitSubscribe = await this.http.get<any>(this.apiUrl + '/user/submitWebsummitSurvey' + params).subscribe(res => {
      if (res) {
        //console.log(res);
        this.nameFormEx.reset(null);
        this.companyFormEx.reset(null);
        this.emailFormEx.reset(null);
        this.toast.success("Submission Successfully Submitted!");
      } else {
        //console.log('send failed');
        this.toast.error("Whoops! Submission Failed");
      }
      this.loading = false;
    });

  }

  openVideo() {
    this.popupWindow.createWindow("https://www.youtube.com/channel/UCCU6g_6-H-uMn8ji7pUZjZg", "CYFERLINX")
  }

  get emailFormEx() { return this.validationForm.get('emailFormEx'); }
  get nameFormEx() { return this.validationForm.get('nameFormEx'); }
  get companyFormEx() { return this.validationForm.get('companyFormEx'); }

  ngOnDestroy() {
    if (this.submitSubscribe) {
      this.submitSubscribe.unsubscribe();
    }
  }

}
