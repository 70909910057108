import { Component, OnChanges, OnDestroy, Input } from '@angular/core';
import { User, TopCoderUser } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TopcoderService } from '../topcoder.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Http } from '@angular/http';
import { ToastService } from 'ng-uikit-pro-standard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topcoder-profile',
  templateUrl: './topcoder-profile.component.html',
  styleUrls: ['./topcoder-profile.component.scss']
})
export class TopcoderProfileComponent implements OnChanges, OnDestroy {
  user: User;
  topcoderUserSubscribe: Subscription;
  topcoderUser: TopCoderUser = null;
  username: string ="";
  password: string ="";
  isCollapsed: any[];
  edit = false;
  uid:any;
  topcoderToggle: boolean = false;
  loading: boolean = false;

  @Input() enableEditParam: boolean = true;
  @Input() uidParam: string;
  @Input() disable: boolean = true;
  
  constructor(private auth: AuthService,
    private topcoder: TopcoderService,
    private afs: AngularFirestore,
    // private fun: AngularFireFunctions,
    private _http: Http,
    private toast: ToastService) { }

  private linkTopcoderAccount(){
    const userRef: AngularFirestoreDocument = this.afs.doc(`topcoder/${this.uid}`);
    userRef.set({ loading: true });
    let url = environment.cloudFunctionConfig.topcoderLink;
    url += this.uid + "/" + this.username + "/" + this.password;
    this._http.get(url, {}).subscribe(res => {
      if (res) {
      }
    },
      err => {
        console.log(err)
        userRef.set({ loading: false });
        this.toast.error("Error linking Topcoder, please try again")
        return
      })
  }
  async Update(){
    this.loading = true;
    await this.topcoder.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated TopCoder");
      this.loading = false;
    })
    .catch(err => {
      this.toast.error("TopCoder Update Failed");
      console.log(err);
      this.loading = false;
    });
  }

  ngOnInit() {}

  ngOnChanges(){
    if (this.uidParam){
      this.uid = this.uidParam;
      this.topcoderToggle = false;
      //this.topcoderUserSubscribe =  this.topcoder.getUser(this.uid).subscribe(topcoder => {
      const path = `topcoder/${this.uidParam}`;
      this.topcoderUserSubscribe = this.afs.doc<TopCoderUser>(path).valueChanges().subscribe(topcoder =>{
        if(topcoder){
          this.topcoderUser = topcoder;
          //console.log(this.topcoderUser)
        }
        if(topcoder == null){
          this.topcoderUser = null;
        }
      })
      if(this.enableEditParam==true){
        this.topcoderToggle=true;
      }
    }
  }

  async Delete(){
    this.loading = true;
    await this.topcoder.deleteUser()
    .then(res => {
      this.loading = false;
      this.toast.success("Successfully Deleted", "TopCoder");
    })
    .catch(err => {
      this.toast.error("Delete Failed", "TopCoder");
      this.loading = false;
      console.log(err);
    });
  }
  
  topcoderToggleSetting(){
    this.topcoderToggle = !this.topcoderToggle
    //console.log(this.githubToggle)
  }

  enableEdit() {
    this.edit = true;
   }
   disableEdit() {
    this.edit = false;
    this.topcoder.updateUser(); // this.topcoder.changeShow(this.uid, this.topcoderUser.Achievements)
    //console.log(this.topcoderUser.Achievements)
  }

  changeCheckbox(i) {
    if (this.topcoderUser) {

      this.topcoderUser.Achievements[i].show = !this.topcoderUser.Achievements[i].show;
    }
    //console.log(this.topcoderUser.Achievements)
  }
  ngOnDestroy(){
    if(this.topcoderUserSubscribe){
      this.topcoderUserSubscribe.unsubscribe();
    }


  }
}
