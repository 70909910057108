import { Component, OnInit } from '@angular/core';
import { WindowService } from 'src/app/core/window/window.service';

@Component({
  selector: 'app-codewars-auth',
  templateUrl: './codewars-auth.component.html',
  styleUrls: ['./codewars-auth.component.scss']
})
export class CodewarsAuthComponent implements OnInit {
  
  username: string;
  access_token: string;
  error: any;
  codewarsURL: string = "https://www.codewars.com/users/edit";

  constructor(private authwindow: WindowService) { }

  ngOnInit() {
    var codeWarsToken = new URL(window.location.href).searchParams.get("token");
    if(codeWarsToken) {
      window.close();
    }
  }

  authCodeWars() {
    if (this.username && this.access_token) {
      window.location.href = `${window.location.href}?token=${this.access_token}&user=${this.username}`;
    }
  }

  openCodeWars() {
    this.authwindow.createWindow(this.codewarsURL);
  }

}
