/// <reference types="@types/googlemaps" />

import { Injectable } from '@angular/core';
import { Observable ,  from as fromPromise , BehaviorSubject,  Subscription } from 'rxjs';

import {environment} from '../../../environments/environment';
import { MapsAPILoader } from '@agm/core';
//import {} from '@types/googlemaps';
export const googleAPIKey = environment.googleAPIKey;
import {PlaceData} from './google-places';

@Injectable()
export class GooglePlacesService {

  apiBaseURL: string =  'https://maps.googleapis.com/maps/api/place'; 
  placeService: any;
  placeDetailsService: any;
  placeServiceIsReady: true;
  placeResults: BehaviorSubject<PlaceData[]> = new BehaviorSubject(null);
  placeResultSelected: BehaviorSubject<PlaceData> = new BehaviorSubject(null);
  sessionToken: any;
  

  constructor(
              private mapsAPILoader: MapsAPILoader) {
    this.mapsAPILoader.load().then(() => {
        this.placeService = new google.maps.places.AutocompleteService();
        this.placeDetailsService = new google.maps.places.PlacesService(document.createElement('div'));
        this.placeServiceIsReady = true;
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      });
    }

  //send the autcomplete results
  public getAutocompleteResults(partialCity: string, type: string, instance: string){
    if (this.placeServiceIsReady) {
      this.placeService.getPlacePredictions({input:partialCity, types: [type], sessionToken: this.sessionToken}, result => {
      //this.placeService.getQueryPredictions({input:partialCity, types: ["(administrative_area_level_2)"]}, result => {
        
        if(result){
          var selectedPlaces: PlaceData[] = [];
          for (let c=0;c<result.length;c++){
            let place = result[c];
            var newPlace: PlaceData = {
              description: place.description,
              lat: 0,
              lng: 0,
              placeID: place.place_id,
              instance: instance
            }
            selectedPlaces.push(newPlace);
          }
          this.placeResults.next(selectedPlaces);
        }
      });
    }
  }


  selectPlace(selectedPlace: PlaceData, instance: string){
    this.placeDetailsService.getDetails({placeId: selectedPlace.placeID, fields: ['geometry'], sessionToken: this.sessionToken}, res => {
      if (res){
        let parse = JSON.parse(JSON.stringify(res))
        var newPlace: PlaceData = {
          description: selectedPlace.description,
          lat: parse.geometry.location.lat,
          lng: parse.geometry.location.lng,
          placeID: selectedPlace.placeID,
          instance: instance
        }
        this.placeResultSelected.next(newPlace);
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      }
    }); 

  }

  clearPlaces(){
    this.placeResults.next(null);
  }

  clearSelection(){
    this.placeResultSelected.next(null);
  }

  

}
