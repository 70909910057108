import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
//mport undefined = require('firebase/empty-import');



@Component({
  selector: 'admin-location-stats',
  templateUrl: './admin-location-stats.component.html',
  styleUrls: ['./admin-location-stats.component.scss']
})
export class AdminLocationStatsComponent implements OnInit, OnDestroy {
  userSubscribe: Subscription
  users = []
  locations = []
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.userSubscribe = this.afs.collection(`users`).valueChanges().subscribe(users => {
      if (users) {
        console.log(users)
        this.users = users
      }
    })
  }
  function() {
    console.log(this.users)
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].location != null) {
        let key = false;
        for (let j = 0; j < this.locations.length; j++) {
          if (this.users[i].location.latitude === this.locations[j]['location']['latitude'] &&
          this.users[i].location.longitude === this.locations[j]['location']['longitude']) {
            key = true
            this.locations[j]['totalUsers'] = this.locations[j]['totalUsers'] + 1
            this.locations[j]['users'].push(this.users[i].uid)
          }
        }
        if (key === false) {
          /*if (this.users[i].city === undefined) {
            this.users[i].city = null;
          }*/
          const point = {
            location: this.users[i].location,
            city: this.users[i].city,
            totalUsers: 1,
            users: [this.users[i].uid]
          }
          this.locations.push(point)
        }
      }
    }
    console.log(this.locations)
    const Path = `admin/map`;
    this.afs.doc(Path).update({locations: this.locations})
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe()
  }
}
