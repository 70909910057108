import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { LoginComponent } from './login/login.component';
import { ContactComponent } from './contact/contact.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyEmailSuccessComponent } from './verify-email/verify-email-success/verify-email-success.component';
import { LocationFormComponent } from './location-form/location-form.component'
import { SendMessageModalComponent } from './send-message-modal/send-message-modal.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component'
//Environment variables
import { environment } from '../../environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ChartsComponent } from './charts/charts.component';
import { MdbPieChartComponent } from './charts/mdb-pie-chart/mdb-pie-chart.component';
import { WebsummitComponent } from './websummit/websummit.component';
import { BetaSignupComponent } from './beta-signup/beta-signup.component';
export const googleAPIKey = environment.googleAPIKey;
@NgModule({
  declarations: [
    LoginComponent,
    ContactComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    VerifyEmailSuccessComponent,
    LocationFormComponent,
    PageNotFoundComponent,
    ChartsComponent,
    MdbPieChartComponent,
    WebsummitComponent,
    SendMessageModalComponent,
    LoadingSpinnerComponent,
    BetaSignupComponent],
  imports: [
    CommonModule,  
    SharedModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AgmCoreModule.forRoot({ apiKey: googleAPIKey, libraries: ["places"] }),

  ],
  exports: [
    LocationFormComponent,
    MdbPieChartComponent,
    SendMessageModalComponent,
    LoadingSpinnerComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class UiModule { }
