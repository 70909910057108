import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User, userMessage } from '../../core/user';
import { AuthService } from '../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { environment } from '../../../environments/environment';
//import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
@Component({
  selector: 'send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.scss']
})
export class SendMessageModalComponent implements OnInit, OnDestroy {
  @Input() uidParam: string;
  @Input() user: User;
  message:string
  name:string;
  email:string;
  uid:string;
  contactMessage: userMessage = null;
  userSubscribe: Subscription;
  currentUser: User;
  roleSub: Subscription;
  emailSub: Subscription;
  profilelink: string;
  cyferlink: string;
  Roles:any
  role: any = null
  params: any
  private httpHeaders: HttpHeaders;
  constructor(
    private afs: AngularFirestore,
    public auth: AuthService, 
    private toast: ToastService,
    private http: HttpClient,
    //private fun: AngularFireFunctions,
    private router: Router,
  ) { this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders); }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(async user => {
      if(user){
        this.currentUser = user;
        this.Roles = await this.auth.getRole()
        if(this.Roles['recruiter']) {
          this.profilelink = "/recruiter";
          this.role = 'recruiter'
          this.cyferlink = "https://cyferlinx.com/recruiter/" + this.currentUser.uid;
        } else {
          this.profilelink = "/hacker";
          this.role = 'hacker'
          this.cyferlink = "https://cyferlinx.com/coder/" + this.currentUser.uid;
        }
      }
    });
    
  }
  async onSubmit() {
    //console.log(this.message)
    //console.log(this.name)
    //console.log(this.email)
    let timestamp =  new Date().getTime()
    const userRef: AngularFirestoreDocument<userMessage> = this.afs.doc<userMessage>(`messages/${timestamp}`);
    if(this.currentUser){
      //if(this.message!=null){
        const contactMessage: userMessage ={
          uid: this.user.uid,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          senderFirstName: this.currentUser.firstName,
          senderLastName: this.currentUser.lastName,
          email: this.user.email,
          senderEmail: this.currentUser.email,
          senderUID: this.currentUser.uid,
          message: this.message,
          timestamp: timestamp,
          profile: this.cyferlink,
          role: this.role
        }
        //console.log(contactMessage);
        //this.fun.httpsCallable('UserMessage')(contactMessage).toPromise()
        /*userRef.set(contactMessage).then(() => {
        })*/
        this.params = '?firstName=' + encodeURIComponent(this.user.firstName) +
          '&lastName=' + encodeURIComponent(this.user.lastName) +
          '&senderFirstName=' + encodeURIComponent(this.currentUser.firstName) +
          '&senderLastName=' + encodeURIComponent(this.currentUser.lastName) +
          '&email=' + encodeURIComponent(this.user.email) +
          '&senderEmail=' + encodeURIComponent(this.currentUser.email) +
          '&senderUID=' + encodeURIComponent(this.currentUser.uid) +
          '&message=' + encodeURIComponent(this.message) +
          '&timestamp=' + encodeURIComponent(new Date(timestamp).toString()) + 
          '&profile=' + encodeURIComponent(this.cyferlink) +
          '&role=' + encodeURIComponent(this.role);
        //console.log(environment.apiRoute + '/userMessage' + this.params);
        this.emailSub = await this.http.get<any>(environment.apiRoute + '/userMessage' + this.params, {headers: this.httpHeaders}).subscribe(res => {
          if (res) {
            //console.log(res);
            this.toast.success("Email Sent");
          } else {
            //console.log('send failed');
            this.toast.error("Emails Failed To Send");
          }
        });
        //this.toast.success("Message Sent")
        this.message = null
      //}


    }   
  }
  
  ngOnDestroy() {
    if(this.roleSub){
      this.roleSub.unsubscribe()
    }
    if(this.userSubscribe){
      this.userSubscribe.unsubscribe()
    }
    if(this.emailSub) {
      this.emailSub.unsubscribe();
    }
  }

}
