import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  createWindow(url: string, name: string = 'Auth') {
    if (url == null) {
        return null;
    }
    //width: number = 500, height: number = 600, left: number = 0, top: number = 0
    //var options = `width=${width},height=${height},left=${left},top=${top}`;
    //var options = `width=600,height=800`;

    return window.open(url, name);
  }
}
