import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { PositionServiceService } from './position-service.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-recruiter-position',
  templateUrl: './recruiter-position.component.html',
  styleUrls: ['./recruiter-position.component.scss']
})
export class RecruiterPositionComponent implements OnInit, OnDestroy {

  private apiUrl = `${environment.apiRoute}`;
  private httpHeaders: HttpHeaders;

  emailForm: FormGroup;
  sendCopy: boolean = false;
  positionUID: any;
  position: any;
  title: string;
  company: String;
  dateClosing: any;
  minPay: number;
  maxPay: number;
  creator: string;
  skillsNeeded: String;
  description: String;
  city: String;
  userId: string;
  recruiterUID: string;
  recruiterEmail: string;
  applicantsUID: string[] = [];
  candidatesUID: string[] = [];
  applicants: any[] = [];
  candidates: any[] = [];
  checkPositions: any[] = [];
  checkCandidates: any[] = [];
  assessmentEmails: string[] = [];
  user: any;
  params: string = '?emails=';
  recruiter: boolean = false;
  edit: boolean = false;
  coder: boolean = false;
  noUser: boolean = false;
  applied: boolean = false;
  ownsPosition: boolean = false;
  fsCollection: AngularFirestoreCollection<any>;
  descConfig: AngularEditorConfig;
  numberOfApplicants: Number = 0;
  numberOfCandidates: Number = 0;
  positionsSubscription: any[] = [];
  positions: any[] = [];

  applicants$: Subscription;
  candidates$: Subscription;
  userSubscribe: Subscription;
  headerSubscribe: Subscription;
  roleSubscribe: Subscription;
  positionSubscribe: Subscription;
  assessmentSubscribe: Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private positionService: PositionServiceService,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private toast: ToastService,) {
      this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
    }


  ngOnInit() {
    this.descConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      width: '100%',
      placeholder: 'Enter detailed description here...',
      translate: 'no',
    };
    this.emailForm = this.fb.group({
      emails: this.fb.array([this.createEmailGroup()])
    });

    /*this.emails.push(this.createEmailGroup());
    this.emails.push(this.createEmailGroup()); */
    this.positionUID = this.route.snapshot.paramMap.get("uid");
    this.roleSubscribe = this.auth.roles.subscribe(role => {
      if (role) {
        this.userSubscribe = this.auth.currentUser.subscribe(user => {
          if (user) {
            this.recruiterEmail = user.email;
            this.checkPositions = user.positions;
            if (this.checkPositions) {
              for (let i = 0; i < this.checkPositions.length; i++) {
                if (this.positionUID === this.checkPositions[i]) {
                  this.ownsPosition = true;
                }
              }
            }
            
          }
        });
        if (role.recruiter === true) {
          this.recruiter = true;
          this.noUser = false;
        } else if(role.coder === true) {
          this.coder = true;
          this.noUser = false;
        } else {
          this.noUser = true;
        }
      } else {
        this.noUser = true;
      }
    }); 

    // getting position data from Position Service
    this.positionService.getPosition(this.positionUID).then(pos => {
      this.position = pos;
      this.title = this.position.title;
      this.company = this.position.company;
      this.dateClosing = this.position.dateClosing;
      this.minPay = this.position.minPay;
      this.maxPay = this.position.maxPay;
      this.skillsNeeded = this.position.skillsNeeded;
      this.description = this.position.description;
      this.city = this.position.city;
      this.applicantsUID = this.position.applicants;
      this.candidatesUID = this.position.candidates;
      this.creator = this.position.creator;

      // candidate & applicant stuff retrieved via Service
      this.positionService.getApplicants(this.applicantsUID);
      this.positionService.getCandidates(this.candidatesUID);

      this.candidates = [];
      this.candidates$ = this.positionService.candidates$.subscribe(result => {
        if (result) {
          this.candidates = result;
          this.numberOfCandidates = result.length;
          console.log(this.candidatesUID.length);
          for (let i = 0; i < this.candidatesUID.length; i++) {
            if (this.userId === this.candidatesUID[i]) {
              this.applied = true;
            }
          }
        } else {this.candidates = []};
      });

      
      this.applicants = [];
      this.applicants$ = this.positionService.applicants$.subscribe(result => {
        if (result) {
          this.applicants = result;
          this.numberOfApplicants = result.length;
          for (let i = 0; i < this.applicantsUID.length; i++) {
            if (this.userId === this.applicantsUID[i]) {
              this.applied = true;
            }
          }
        } else {this.applicants = []};
      });
    })
    // this.positionSubscribe = this.positionService.positions$.subscribe(pos => { 
    //   if (pos) {
    //     this.positions = pos;
    //     for (let i = 0 ; i < this.positions.length ; i++) { // get position data 
    //       if (this.positionUID === this.positions[i].uid) {
    //         this.position = this.positions[i];
    //         this.title = this.position.title;
    //         this.company = this.position.company;
    //         this.dateClosing = this.position.dateClosing;
    //         this.minPay = this.position.minPay;
    //         this.maxPay = this.position.maxPay;
    //         this.skillsNeeded = this.position.skillsNeeded;
    //         this.description = this.position.description;
    //         this.city = this.position.city;
    //         this.applicantsUID = this.position.applicants;
    //         this.candidatesUID = this.position.candidates;
    //         this.creator = this.position.creator;

            
    //         // candidate & applicant stuff retrieved via Service
    //         this.positionService.getApplicants(this.applicantsUID);
    //         this.positionService.getCandidates(this.candidatesUID);

    //         this.candidates = [];
    //         this.candidates$ = this.positionService.candidates$.subscribe(result => {
    //           if (result) {
    //             this.candidates = result;
    //             this.numberOfCandidates = result.length;
    //             console.log(this.candidatesUID.length);
    //             for (let i = 0; i < this.candidatesUID.length; i++) {
    //               if (this.userId === this.candidatesUID[i]) {
    //                 this.applied = true;
    //               }
    //             }
    //           } else {this.candidates = []};
    //         });

            
    //         this.applicants = [];
    //         this.applicants$ = this.positionService.applicants$.subscribe(result => {
    //           if (result) {
    //             this.applicants = result;
    //             this.numberOfApplicants = result.length;
    //             for (let i = 0; i < this.applicantsUID.length; i++) {
    //               if (this.userId === this.applicantsUID[i]) {
    //                 this.applied = true;
    //               }
    //             }
    //           } else {this.applicants = []};
    //         });


    //       }
    //     }
    //   }
    // });    
  }

  get emails() {
    return this.emailForm.get('emails') as FormArray;
  }

  createEmailGroup() {
    return this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])]
    });
  }

  addEmail() {
    this.emails.push(this.createEmailGroup());
  }

  removeEmail(index) {
      this.emails.removeAt(index);
  }

  removeLastEmail() {
    if (!(this.emails.length === 1)) {
      let last = this.emails.length;
      this.emails.removeAt(last - 1);
    }
  }

  async sendEmails() {
    return new Promise<any>(async (resolve, reject) => {
      for (let i = 0; i < this.emails.length; i++) {
        this.assessmentEmails.push(this.emails.value[i].email);
      }
      if (this.sendCopy) {
        this.assessmentEmails.push(this.recruiterEmail);
      }
      for (let i = 0; i < this.assessmentEmails.length; i++) {
        if (this.assessmentEmails[i] === null) {
          this.assessmentEmails.splice(i, 1);
        }
      }
      if (this.assessmentEmails[0] === null) {
        this.assessmentEmails.splice(0, 1);
      }

      // ----- Send email function
      for(let i=0; i<this.assessmentEmails.length; i++){
        this.params = this.params + encodeURIComponent(this.assessmentEmails[i]) + ',';
      }
      //console.log(this.apiUrl + '/user/positionAssessmentEmail/' + this.positionUID + this.params)
      const url = this.http.get<any>(this.apiUrl + '/user/positionAssessmentEmail/'+this.positionUID + this.params, {headers: this.httpHeaders});
      this.assessmentSubscribe = url.subscribe(res => {
        if (res) {
          //console.log(res);
          this.toast.success("Assessment Emails Sent");
          resolve(res)
        } else {
          //console.log('send failed');
          this.toast.error("Assessment Emails Failed To Send");
        }
      });
      // ------

      this.assessmentEmails = [];
      for (let i = 0; i < this.emails.length; i++) {
        this.removeEmail(i);
      }
      this.emailForm = this.fb.group({
        emails: this.fb.array([this.createEmailGroup()])
      });
      this.emails.push(this.createEmailGroup());
      //this.emails.push(this.createEmailGroup());
      this.sendCopy = false;

    })
  }

  addCandidate(applicant: any) {
    const applicantUID = applicant.uid;
    for (let i = 0; i < this.applicantsUID.length; i++) {
      if (applicantUID === this.applicantsUID[i]) {
        this.applicantsUID.splice(i, 1);
        this.applicants.splice(i, 1);
      }
    }
    if (this.candidatesUID) {
      this.candidatesUID.push(applicantUID);
    } else {
      this.candidatesUID = [];
      this.candidatesUID.push(applicantUID);
    }

    // saves to afs database
    this.afs.collection('positions').doc(this.positionUID).update({
      applicants: this.applicantsUID,
      candidates: this.candidatesUID
    });

    // retrieves from Service
    this.positionSubscribe = this.positionService.positions$.subscribe(pos => {
      this.candidates.push(applicant);
      this.positionService.applicants$.next(this.applicants);
      this.positionService.candidates$.next(this.candidates);
      console.log("new num of candies: " + this.candidates.length);
      console.log("new num of apps: " + this.applicants.length)
    });

    // --- actual code to retrieve data from afs 
    /*this.afs.collection('users').doc(applicantUID).ref.get().then(doc => {
      if (doc.exists) {
        this.user = doc.data();
        this.candidates.push(this.user);
        // trying to see if the update happens when code placed in here instead of outside
        this.positionService.applicants$.next(this.applicants);
        this.positionService.candidates$.next(this.candidates);
        console.log("new num of candies: " + this.candidates.length);
        console.log("new num of apps: " + this.applicants.length);
      }
    });*/
    // code here moved inside get() from afs ^
  }

  removeCandidate(candidate: any) {
    let candidateUID = candidate.uid;
    for (let i = 0; i < this.candidatesUID.length; i++) {
      if (candidateUID === this.candidatesUID[i]) {
        this.candidatesUID.splice(i, 1);
        this.candidates.splice(i, 1);
      }
    }
    if(this.applicantsUID) {
      this.applicantsUID.push(candidateUID);
      // this.applicants.push(candidate);
    } else {
      this.applicantsUID = [];
      this.applicantsUID.push(candidateUID);
      // this.applicants.push(candidate);
    }

    // saves to afs database
    this.afs.collection('positions').doc(this.positionUID).update({
      applicants: this.applicantsUID,
      candidates: this.candidatesUID
    });

    // retrieves from Service
    this.positionSubscribe = this.positionService.positions$.subscribe(pos => {
      this.applicants.push(candidate);
      this.positionService.applicants$.next(this.applicants);
      this.positionService.candidates$.next(this.candidates);
      console.log("new num of candies: " + this.candidates.length);
      console.log("new num of apps: " + this.applicants.length)
    });

    // --- actual code to retrieve data from afs 
    /*this.afs.collection('users').doc(candidateUID).ref.get().then(doc => {
      if (doc.exists) {
        this.user = doc.data();
        this.applicants.push(this.user);
        // trying to see if the update happens when code placed in here instead of outside
        this.positionService.applicants$.next(this.applicants);
        this.positionService.candidates$.next(this.candidates);
        console.log("new num of candies: " + this.candidates.length);
        console.log("new num of apps: " + this.applicants.length);
      }
    });*/

    // code here moved inside get() from afs ^
  }

  async apply() {
    await this.auth.applicationSubmision(this.positionUID).then(res => {
      // do something with github here
      this.applied = true;
      this.coder = false;
      this.toast.success("Successfully Submitted An Application");
    })
    .catch(err => {
      this.toast.error("Application Has Already Been Submitted");
      console.log(err);
    });
    /* this.applicantsUID.push(this.userId);
    this.afs.collection('positions').doc(this.positionUID).update({
      applicants: this.applicantsUID
    }).then(() => {
      this.applied = true;
      this.coder = false;
    }); */
  }

  signupApply() {
    this.router.navigate(['signup/coder-position'],{queryParams:{'positionUID': this.positionUID}});
  }

  routeToRecruiterProfile() {
    if(this.creator) {
      this.router.navigate([`recruiter/${this.creator}`]);
    }
  }

  createReport() {
    this.router.navigate([`position/report/${this.positionUID}`])
  }

  ngOnDestroy() {
    //this.positionService.clear();
    if (this.applicants$) {
      this.applicants$.unsubscribe();
    }
    if (this.candidates$) {
      this.candidates$.unsubscribe();
    }
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.roleSubscribe) {
      this.roleSubscribe.unsubscribe();
    }
    if (this.positionSubscribe) {
      this.positionSubscribe.unsubscribe();
    }
    if (this.assessmentSubscribe) {
      this.assessmentSubscribe.unsubscribe();
    }

  }

  toggleEdit() {
    this.edit = !this.edit;
  }

  save() {
    this.checkForm();
    this.afs.collection('positions').doc(this.positionUID).update({
      company: this.company,
      minPay: this.minPay,
      maxPay: this.maxPay,
      dateClosing: this.dateClosing,
      city: this.city,
      skillsNeeded: this.skillsNeeded,
      description: this.description
    });
  }

  checkForm() {
    if (!this.company) {
      this.company = null;
    }
    if (!this.minPay) {
      this.minPay = null;
    }
    if (!this.maxPay) {
      this.maxPay = null;
    }
    if (!this.dateClosing) {
      this.dateClosing = null;
    }
    if (!this.city) {
      this.city = null;
    }
    if (!this.skillsNeeded) {
      this.skillsNeeded = null;
    }
    if (!this.description) {
      this.description = null;
    }
  }


}
