import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecruiterPositionComponent } from './recruiter-position.component';
import { RecruiterReportComponent } from '../recruiter-report/recruiter-report.component';

const routes: Routes = [
    { path: ':uid', component: RecruiterPositionComponent},
    { path: 'report/:uid', component: RecruiterReportComponent},
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class PositionRoutingModule { }