import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import * as firebase from 'firebase/app'; ////////////
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-coder',
  templateUrl: './coder.component.html',
  styleUrls: ['./coder.component.scss']
})
export class CoderComponent implements OnInit {

  userUID: string;
  queryEmail: string;

  constructor(private route: ActivatedRoute, private auth: AuthService) { 
    this.userUID = this.route.snapshot.paramMap.get("uid")
  }

  ngOnInit() {
    const user = firebase.auth().currentUser;
    this.queryEmail = this.route.snapshot.queryParams.email; // occurs after clicking verification link

    if (user && user.emailVerified && user.uid == this.userUID) { // ensure verified users email is in list
      this.auth.addNewEmail(this.userUID, user.email)
    }
    if (user && this.queryEmail) { // only exists if verified link clicked
      this.auth.addNewEmail(user.uid, this.queryEmail)
    }    
  }
}
