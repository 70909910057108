import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/core/auth/auth.service';
import { reject } from 'q';
import { User, Position } from 'src/app/core/user';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class PositionServiceService {

  public applicants$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public candidates$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public positions$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public positionsExpired$: BehaviorSubject<boolean[]> = new BehaviorSubject(null);
  public positionLogo$: BehaviorSubject<any> = new BehaviorSubject(null);
  user: any;
  applicants: any[] = [];
  candidates: any[] = [];
  positions: any[] = [];
  positionsExpired: boolean[] = [];
  positionUIDs: any[] = [];
  positionLogo: string;
  position: any;
  dataLoaded: boolean = false;

  // All the position service needs to do is listen for if the user is logged in it's constructor.
  // If the user is logged in then check if they are a recruiter. If they are a recruiter then get all the positions linked to that recruiter.
  constructor(private afs: AngularFirestore, private auth: AuthService, private toast: ToastService) {
    this.auth.currentUser.subscribe(user => {
      if (user) {
        this.user = user;
        this.getPositions(this.user.uid);
        // try to get applicants and candidates here. So it gets loaded before hand.
        // get every position's applicants and candidates already
        // and only when on specific page, it can just retrieve the already loaded afs data
      } 
    });
   }
   

   async getPositions(recruiterUid: string) {
    if(recruiterUid) { 
      this.positions$.next([]);
      this.positionsExpired$.next([]);
      this.positionLogo$.next(null);
      this.positionUIDs = this.user.positions; 
      this.positionLogo = this.user.companyLogo;
      this.positionLogo$.next(this.positionLogo);
      
      if (this.positionUIDs){
        await this.retrievePositionDocs(this.positionUIDs);
        this.positions$.next(this.positions);   
        this.positionsExpired$.next(this.positionsExpired);
        
      }
    }
  }
  // gets applicants and candidates when needed only...
  async getApplicants(applicantsUID: any[]) { // all applicant UIDs
    if(applicantsUID) {
      applicantsUID = applicantsUID;
      this.applicants$.next([]);
      this.applicants$.next(await this.retrieveUserDocs(applicantsUID));
    }
  }

 async getCandidates(candidatesUID: any[]) {
    if (candidatesUID) {
      candidatesUID = candidatesUID;
      this.candidates$.next([]);
      this.candidates$.next(await this.retrieveUserDocs(candidatesUID));
    }
  }

  // retrieves the applicants/candidates and then gets added onto the Behavior Subjects
  async retrieveUserDocs (uidArray: any[]) {
    return new Promise<any[]> ((resolve) => {
      const result: any[] = [];
      uidArray.forEach((uid, index, array) =>{
        this.afs.collection('users').doc(uid).ref.get().then(doc => {
          if (doc.exists) {
            this.user = doc.data();
            result.push(this.user);
          }
          if (index === array.length -1) resolve(result);
        });

      });
    });
  }
  // this gets the actual position (title, etc.)
  async retrievePositionDocs (uidArray: any[]) {
    return new Promise<any[]> ((resolve) => {
      this.positions = [];
      this.positionsExpired = [];
      let currentDate = new Date();
      for (let i = 0; i < uidArray.length; i++) {
        this.afs.collection('positions').doc(uidArray[i]).ref.get().then(doc => {
          if(doc.exists) {
            this.user = doc.data();
            this.position = this.user; 
            this.positions.push(this.position);
            this.positions$.next(this.positions); ////////// this needed to be added to continuously update the BehaviorSubject
            let compare = new Date(this.position.dateClosing);
            if (compare < currentDate) {
              this.positionsExpired.push(true);
            }
            else {
              this.positionsExpired.push(false);
            }
          }
        });
        if (i === uidArray.length -1) resolve();
      }
      
    });

     
  }

  async deletePosition (recruiterUid: string,positionUID: string) {
    return new Promise<any[]> ((resolve, reject) => {
      this.afs.collection('users').doc(recruiterUid).ref.get().then(async doc => {
        if(doc.exists) {
          this.user = doc.data();
          this.positionUIDs = this.user.positions;
          for(let i = 0; i < this.positionUIDs.length; i++) {
            if (this.positionUIDs[i] == positionUID) {
              this.positionUIDs.splice(i, 1);
              break; 
            }
          }
          this.afs.collection('users').doc(recruiterUid).update({positions: this.positionUIDs}).then(() => {
            this.afs.collection('positions').doc(positionUID).delete().then(() => {
              resolve();
            })
            .catch(err => reject(err));
          })
          .catch(err => reject(err));
        }
      })
      .catch(err => reject(err));
    });
  }

  clearPositions() {
/*     this.positions = [];
    this.positions$.next(this.positions);
    this.positionsExpired = [];
    this.positionsExpired$.next(this.positionsExpired);
    this.positionLogo = null;
    this.positionLogo$.next(this.positionLogo); */
  }

  getPosition(positionId) {
    return new Promise<Position> ((resolve) => {
      this.afs.collection("positions").doc(positionId).valueChanges().subscribe((positionData: Position) => {
        resolve(positionData);
      });
    })
  }

  getRecruiter(recruiterId) {
    return new Promise<User> ((resolve) => {
      this.afs.collection("users").doc(recruiterId).valueChanges().subscribe((recruiterData: User) => {
        resolve(recruiterData);
      });
    })
  }

  async createPosition(recruiterId:string, job: Position) {
    return new Promise<string[]> (async (resolve) => {
      const positions = (await this.getRecruiter(recruiterId)).positions;
      this.afs.collection('positions').add(job).then((res => {
          var id = { uid: res.id }
          this.updateJob(res.id, id);
          for (let i = 0; i < positions.length; i++) {
            if (positions[i] === "xx") {
              positions[i] = res.id;
            }
          }
          let jobList = positions;
          
          if (this.checkPositionList(jobList, res.id)) {
              jobList.push(res.id);
              var data = { positions: jobList };
              this.afs.collection('users').doc(recruiterId).update(data);
          }
          resolve(jobList)
      })).catch(error => this.toast.error(error.message));
    })

    
  }

  updateJob(uid: String, data: any) {
    const doc = `positions/${uid}`;
    const jobRef: AngularFirestoreDocument<any> = this.afs.doc(doc);
    jobRef.update(data).catch(error => this.toast.error(error.message));
  }

  checkPositionList(jobList: String[], id: string) {
    for (let i in jobList) {
        if (id == jobList[i]) {
            return false;
        }
    }
    return true;
  }

  updatePosition(positionId, data) {
    return new Promise(async (resolve) => {
      const doc = `positions/${positionId}`;
      const jobRef: AngularFirestoreDocument<any> = this.afs.doc(doc);
      await jobRef.update(data);
      resolve();
    })
  }

  getPositionDoc(positionIDs) {
    return new Promise<any>(async (resolve) => {
      const positions = []
      if (positionIDs) {
        positionIDs.forEach(async posID => {
          positions.push(await this.getPosition(posID))
        });
      }
      resolve(positions);
    })

  }

}
