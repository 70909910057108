import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule, MatCheckboxModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { JobListComponent } from './job-list/job-list.component';
import { RouterModule } from '@angular/router';
import { UiModule } from '../ui/ui.module';
import { SharedModule } from '../shared/shared.module';
import { JobSearchResultComponent } from './job-search-result/job-search-result.component';
import { JobsRoutingModule } from './jobs-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    UiModule,
    AngularEditorModule,
    SharedModule,
    JobsRoutingModule
  ],
  declarations: [
    // JobLinkComponent,
    // UserLinkComponent,
    // JobDeleteComponent,
    JobListComponent,
    JobSearchResultComponent,
    // JobSearchResultComponent,
  ]
})
export class JobsModule { }
