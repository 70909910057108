import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CoderProfileBasicComponent } from '../coder-profile/coder-profile-basic/coder-profile-basic.component';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CoderProfileWorkHistoryComponent } from './coder-profile-work-history/coder-profile-work-history.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-coder-profile',
  templateUrl: './coder-profile.component.html',
  styleUrls: ['./coder-profile.component.scss']
})
export class CoderProfileComponent implements OnInit, OnDestroy {

  @ViewChild(CoderProfileBasicComponent, {static: false})
  private userProfileBasic: CoderProfileBasicComponent;

  @ViewChild(CoderProfileWorkHistoryComponent, {static: false})
  private userProfileHistory: CoderProfileWorkHistoryComponent;

  showSpinner: boolean = true;
  loggedIn: boolean = true;
  editing: boolean = false;
  save: boolean = false;
  userInfo: any;

  // user
  user: User;
  userSubscribe: Subscription;
  uid: string;
  uidParam: string;
  hideEdit: boolean = true;
  hasEducationAndHistoryAvailable: boolean = false;

  currentUser: any;
  userData: any;
  workHistory: any[] = [];
  educationHistory: any[] = [];

  constructor(private afs: AngularFirestore, private auth: AuthService, private route: ActivatedRoute, private toast: ToastService, private router: Router) { }

  ngOnInit() {
    this.uidParam = this.route.snapshot.paramMap.get("uid");
    this.setCoderProfile();
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      if (user) { // logged in
        this.uid = user.uid;
        if (this.uid === this.uidParam) {
          this.hideEdit = false;
        }
      }  
    });   
  }

  async setCoderProfile() {
    this.user = await this.auth.getUserInfo(this.uidParam);
    if (this.user) {
      this.workHistory = this.user.workHistory;
      this.educationHistory = this.user.educationHistory;
      this.checkForHistoryAndEducation();
      this.changeSpinner();
    } 
    else if (this.router.url.search('coder') == 1) { // accessing coder profile that does not exist
      this.toast.error("User does not exist");
      this.router.navigate(['/'])
    }
  }

  changeSpinner(){
    if(this.user && this.uidParam){ // && this.uid
      this.showSpinner = false;
    }
  }

  checkForHistoryAndEducation(){
  if(this.workHistory && this.educationHistory){
    if (this.workHistory.length === 0 && this.educationHistory.length === 0){
      this.hasEducationAndHistoryAvailable = false;
    } else {
      this.hasEducationAndHistoryAvailable = true;
      }
    }
  }

  toggleMode () {
    this.editing = !this.editing;
  }

  saveData() {
    this.userProfileBasic.saveProfile();
    this.userProfileHistory.saveProfile();
    this.editing = !this.editing;
    this.save = false;
    this.toast.success("Profile Changes Were Successfully Saved");
    //this.checkForHistoryAndEducation();
  }

  cancelEdit() {
    this.editing = !this.editing;
    this.save = false;
    this.userProfileHistory.resetElements();
    this.toast.info("No Profile Changes Were Made")
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
  }
}
