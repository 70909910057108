import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';

@Component({
  selector: 'admin-global-stats',
  templateUrl: './admin-global-stats.component.html',
  styleUrls: ['./admin-global-stats.component.scss']
})
export class AdminGlobalStatsComponent implements OnInit, OnDestroy {
  userSubscribe: Subscription
  shares = []
  repos = 0

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.userSubscribe = this.afs.collection(`share`).valueChanges().subscribe(share => {
      if (share) {
        console.log(share);
        this.shares = share;
      }
    })
  }

  async function() {
    this.repos = 0;
    for (let i = 0; i < this.shares.length; i++) {
      if (this.shares[i].bitbucketRepos) {
        this.repos = this.repos + this.shares[i].bitbucketRepos;
      }
      if (this.shares[i].githubRepos) {
        this.repos = this.repos + this.shares[i].githubRepos;
      }
      if (this.shares[i].gtilab) {
        this.repos = this.repos + this.shares[i].gitlabRepos;
      }
    }
    console.log(this.repos)
    const Path = `admin/stats`;
    this.afs.doc(Path).update({total: this.repos})

  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

}
