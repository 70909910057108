import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Observable,of, from, BehaviorSubject } from 'rxjs';
//Environment variables
import { environment } from '../../../environments/environment';
import { CodinGameProfile } from './codingame';

@Injectable({
  providedIn: 'root'
})
export class CodingameService {

  private httpHeaders: HttpHeaders;
  private authUrl = "https://www.codingame.com/profile/";
  private codingameAPIURL = `${environment.apiAddress}/codingame`;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
  }

  async signIn() {
    return new Promise<string>((resolve, reject) => {
      this.auth.launchAuthPopup(`${window.location.origin}/codingame-auth`, 'CodinGame Auth')
      .then(href => {
        const codingameUsername: string = new URL(href).searchParams.get('username');
        if (codingameUsername) {
          resolve(codingameUsername);
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {reject(new Error((err)))});
    });

  }

  async getUser(username: string) {
    return new Promise<CodinGameProfile>((resolve, reject) => {
      const params = `?username=${username}`;
      this.http.get<CodinGameProfile>(this.codingameAPIURL + '/auth' + params, {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async updateUser() {
    return new Promise<CodinGameProfile>((resolve, reject) => {
      this.http.get<CodinGameProfile>(this.codingameAPIURL + '/update', {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async deleteUser() {
    return new Promise<CodinGameProfile>((resolve, reject) => {
      this.http.get<CodinGameProfile>(this.codingameAPIURL + '/delete', {headers: this.httpHeaders}).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

}
