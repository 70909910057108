import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import {environment} from '../../../environments/environment'
import { User, TopCoderAchievements } from '../../core/user';
import { Subscription } from 'rxjs';

import { Router, RouterLink } from '@angular/router';
@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  user: User;
  userSubscribe: Subscription;
  roleSub: Subscription;
  profilelink: string;
  transparent: boolean = false;
  Roles: any

  private key = environment.bitbucketConfig.key
  private secret = environment.bitbucketConfig.secret

  extraClasses;

  constructor(
    public auth: AuthService,
    private router: Router // to check which page user is currently at
  ) { }


  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(async user => {
      if (user) {
        this.user = user;

        this.roleSub = this.auth.roles.subscribe(roles => {
          if (roles) {
            this.Roles = roles;
            if (this.user) {
              if (roles.coder) {
                this.profilelink = '/coder';
              }
              if (roles.recruiter) {
                this.profilelink = '/recruiter';
              }
              if (!roles.coder && !roles.recruiter) {
                console.log('No account type');
              }
            }
          }
        });
      } else {
        this.user = null;
      }
    });

    
    if (this.router.url === "/") {
      this.transparent = true;
    } else {
      this.transparent = false;
    }
  }

  async retrieveRole(uid) {
    const roles = await this.auth.getRolesByUID(uid)
    console.log(roles)
    console.log(uid)
    if (uid) {
      if (roles.coder) {
        this.profilelink = '/coder';
      }
      if (roles.recruiter) {
        this.profilelink = '/recruiter';
      }
      if (!roles.coder && !roles.recruiter) {
        console.log('No account type');
      }
    }
  }

  logout() {
    this.auth.signOut();
  }

  goToProfile () {
    this.auth.loginAndRoute("/profile");
  }

  async delete() {
    await this.auth.deleteProfile()
    .then(res => {console.log(`delete result ${JSON.stringify(res)}`)})
    .catch(err => {console.log("delete error")});
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
    if (this.roleSub) {
      this.roleSub.unsubscribe();
    }
  }


}
