import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlaceData } from '../../core/google-places/google-places';
import { AuthService } from '../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-signup-coder',
  templateUrl: './signup-coder.component.html',
  styleUrls: ['./signup-coder.component.scss']
})
export class SignupCoderComponent implements OnInit, OnDestroy {
  userSubscribe: Subscription;
  email: string;
  password: string;
  finalLocation: PlaceData;
  termsAccept = false;
  component = 'userForm';
  validationForm: FormGroup;
  loading: boolean = false;
  
  constructor(private auth: AuthService, private router: Router, public fb: FormBuilder, private toast: ToastService) {
    this.validationForm = fb.group({
      emailFormEx: [null, [Validators.required, Validators.email]],
      passwordFormEx: [null, Validators.required],
    });
  }

  ngOnInit() {
  }

  changeTerms() {
    this.termsAccept = !this.termsAccept;
  }

  locationChanged(newLocation: PlaceData) {
    this.finalLocation = newLocation;
  }

  onSubmit(formData: any) {
    const hacker = true;
    this.loading = true;
    this.auth.emailSignUp(this.emailFormEx.value, this.passwordFormEx.value, hacker, this.finalLocation)
      .then(async () => {
        this.loading = false;
        this.router.navigate(['/connect']);
        await this.auth.setNewUser()
      })
      .catch(error => {
        this.loading = false;
        // this.toast.error(error); 
        // console.log(error);
    });
  }

  get emailFormEx() { return this.validationForm.get('emailFormEx'); }
  get passwordFormEx() { return this.validationForm.get('passwordFormEx'); }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }
}
