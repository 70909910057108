import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';

@Component({
  selector: 'admin-name-lower-case',
  templateUrl: './admin-name-lower-case.component.html',
  styleUrls: ['./admin-name-lower-case.component.scss']
})
export class AdminNameLowerCaseComponent implements OnInit, OnDestroy {
  UserSubscribe: Subscription
  users = []
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.UserSubscribe = this.afs.collection(`users`).valueChanges().subscribe(users => {
      if (users) {
        console.log(users)
        this.users = users
      }
    })
  }
  function(){
    console.log(this.users)

    for(let i=0; i<this.users.length;i++){
      let firstName = this.users[i]['firstName'].toLowerCase()
      let lastName = this.users[i]['lastName'].toLowerCase()
      console.log(firstName)
      console.log(lastName)
      this.afs.doc(`users/${this.users[i].uid}`).update({firstNameLower: firstName, lastNameLower: lastName})
    }
  }
  ngOnDestroy(){
    this.UserSubscribe.unsubscribe()
  }
}
