import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User } from 'src/app/core/user';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';
import { TwitterService } from '../twitter.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-twitter-profile',
  templateUrl: './twitter-profile.component.html',
  styleUrls: ['./twitter-profile.component.scss']
})
export class TwitterProfileComponent implements OnInit, OnChanges, OnDestroy {
  twitterSubscribe: Subscription;
  user: User;
  twitterUser: any;

  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  APIKey: string = "";
  isCollapsed: any[];
  uid: any;
  screen_name: string;
  loaded: any;
  followers: string;
  following: string;
  numTweets: number;
  name: string;
  entities: any[] = [];
  latestTweet: string;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true
  loading: boolean = false;

  // New chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;
  @Input() disable: boolean = true;
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private toast: ToastService,
    private afs: AngularFirestore,
    private _http: Http,
    private twitter: TwitterService) { }

  private twitterHtml() {

  }

  linkTwitterAccount(){
  }

  async Update() {
    this.loading = true;
    await this.twitter.updateUser()
    .then(res => {
      this.toast.success("Successfully Updated Twitter");
      this.loading = false;
    })
    .catch(err => {
      this.toast.error("Twitter Update Failed");
      console.log(err);
      this.loading = false;
    });
  }

  ngOnInit() {
    if(this.uidParam){
      this.twitterSubscribe = this.afs.collection('twitter').doc(this.uidParam).valueChanges().subscribe(doc => {
        if (doc) {
          this.twitterUser = doc;
          this.screen_name = this.twitterUser.screen_name;
          this.followers = this.twitterUser.followers_count;
          this.numTweets = this.twitterUser.numberOfTweets;
          this.name = this.twitterUser.name;
          // this.latestTweet = this.twitterUser.status.text;
        }
      })
    }
  }

  ngOnChanges() {

  }
  ngOnDestroy() {
    if (this.twitterSubscribe) {
      this.twitterSubscribe.unsubscribe();
    }
  }

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  private updatePieChart() {

  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }


  async Delete() {
    this.loading = true;
    await this.twitter.deleteUser()
    .then(res => {
      this.loading = false;
      this.toast.success("Successfully Deleted Twitter");
    })
    .catch(err => {
      this.loading = false;
      console.log(err);
    });

  }

}
