import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';

import { GitLabRepo, GitLabLanguage, GitLabUser } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { GitlabService } from '../gitlab.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { PieChartConfig } from 'src/app/core/charts/PieChartConfig';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-gitlab-profile',
  templateUrl: './gitlab-profile.component.html',
  styleUrls: ['./gitlab-profile.component.scss']
})
export class GitlabProfileComponent implements OnChanges, OnDestroy {

  @Input() enableEditParam = true;
  @Input() uidParam: string;
  @Input() disable: boolean = true;

  gitlabUser: GitLabUser = null;
  gitlabUserSubscribe: Subscription;
  gitlabRepos: GitLabRepo[] = [];
  gitlabReposSubscribe: Subscription;
  gitlabProgressSubscribe: Subscription;
  gitlabLanguages: Array<GitLabLanguage> = null;
  // gitlabLanguages: GitLabLanguage[] = null;
  gitlabLanguagesSubscribe: Subscription;

  data1: any[] = [];
  listData: any[] = [];
  percentageData: any[] = [];
  config1: PieChartConfig;
  elementId1: string;
  mdbChartComplete: boolean = true;
  loading: boolean = false;

  languageTotal = 0;
  listColours: string[] = [];
  edit = false;
  tags: any;
  uid: any;
  gitlabToggle: boolean = false;
  deleteSub: Subscription;
  refresh: boolean = false;
  progressInfo:string
  DataProcessing = false
  //new chart
  mdbChartLabel: string[] = [];
  mdbChartData: any[] = [];
  TotalCommits: any[] = []
  TotalCommitsArray: any
  TotalCommitsDates: any[] = []

 
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  public chartType: string = 'line';
  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(74, 107, 119, .2)',
      borderColor: 'rgba(74, 107, 119, .7)',
      borderWidth: 2,
    },

  ];

  public chartOptions: any = {
    responsive: true, scales: {
      yAxes: [{
        ticks: { beginAtZero: true }
      }]
    }
  };
  public chartLabels: Array<any> = ['Dates']

  constructor(private auth: AuthService, public gitlab: GitlabService, private afs: AngularFirestore, private toast: ToastService, ) {

  }

  linkGitlabAccount() {
    // this.auth.linkGitlab(false);
    this.gitlab.signIn();
  }

  private connectPrivateRepos() {
    // this.auth.enablePrivateRepos();
  }

  async Update() {
    this.loading = true;
    await this.gitlab.update().then(res => {
      // do something with github here
      this.toast.success("Successfully Updated GitHub");
      this.loading = false;
    })
    .catch(err => {
      // handle failure in the connection of github
      this.toast.error("Gitlab Update Failed");
      console.log(err);
      this.loading = false;
    });
  }

  ngOnChanges() {
    if (this.uidParam) {
      this.uid = this.uidParam;
      this.gitlabToggle = this.enableEditParam;

      this.gitlabUserSubscribe = this.afs.doc<GitLabUser>(`gitlab/${this.uidParam}`).valueChanges().subscribe(gitlab => {
        if (gitlab) {
          this.gitlabUser = gitlab;
          this.gitlabLanguages = gitlab.languages;
            if(this.gitlabLanguages.length > 0){
              this.updatePieChart(this.gitlabLanguages);
            }

        }
        if (gitlab == null) {
          this.gitlabUser = null;
        }
      });

      this.gitlabReposSubscribe = this.afs.collection<GitLabRepo>(`gitlab/${this.uidParam}/repos`).valueChanges().subscribe(repos =>{
        if (repos) {
          this.gitlabRepos = repos;
          this.TotalCommitsArray = []
            this.TotalCommitsDates = []
            this.TotalCommits = []
            for(let j=0; j<repos.length;j++){
              let owner = this.gitlabUser['email']//repos[j].url.split("/")[3]
              let userLocation 
              //console.log(repos[j])
              if(repos[j]['commits']){
                
                for(let i=0; i<repos[j]['commits'].length; i++){
                  if(repos[j]['commits'][i]['committer_email'] == owner){
                    userLocation = i
                    //console.log(repos[j].name)
                    //console.log(userLocation)
                    //console.log(repos[j]['contributors'][i].total)
                    this.gitlabRepos[j].commitsTotal = repos[j]['commits'].length
                    let a=0,c=0,d=0,w=0
                    let commits = []
                    let dates = []
                    
                    for(let k=0; k<repos[j]['commits'].length; k++){
                      a = a+ repos[j]['commits'][i]['additions']
                      c = c+ 1
                      d = d+ repos[j]['commits'][i]['deletions']
                      commits[k] = 1
                      //console.log(new Date(repos[j]['commits'][i]['commited_date']).getDay())
                      //console.log(new Date(repos[j]['commits'][i]['commited_date']).getTime())
                      let date = new Date(new Date(repos[j]['commits'][i]['commited_date']))
                      let day = date.getDay()
                      let diff = date.getDate() - day + (day==0 ? -6:1)
                      date =  new Date(date.setDate(diff))
                      let startofWeek = new Date(date.toDateString()).getTime()
                      //console.log(startofWeek)
                      //console.log(new Date(startofWeek).toDateString())
                      dates[k] = repos[j]['commits'][i]['commited_date']
                      const Totalcommits = [{
                        commits: 1,
                        timestamp: startofWeek//repos[j]['commits'][i]['commited_date']
                      }]
                      //console.log(Totalcommits)
                      this.TotalCommits.push(Totalcommits)
                      w = repos[j]['commits'].length
                    
                    }
                    this.gitlabRepos[j].a = a
                    this.gitlabRepos[j].c = c
                    this.gitlabRepos[j].d = d
                    this.gitlabRepos[j].w = w
                    this.gitlabRepos[j].commitsTotal = w
                    this.gitlabRepos[j].commitsArray = [{data: commits, label: 'Commits'}]
                    //console.log(commits)
                    this.gitlabRepos[j].datesArray = dates
                    //console.log(this.gitlabRepos[j])
                    
                  }
                }
                
              }
              
            }
            this.TotalCommits.sort(function(x,y){
              return x[0].timestamp -y[0].timestamp;
            })
            let temp: any[] = []
            let commit: number = 0
            let date = 0
            //console.log(this.TotalCommits)
            for( let j=1;j<this.TotalCommits.length;j++){
              //console.log(this.TotalCommits[j][0])
              if(j===1 && (this.TotalCommits[j-1][0].timestamp == this.TotalCommits[j][0].timestamp )){
                commit = Number(this.TotalCommits[j-1][0].commits) + Number(this.TotalCommits[j][0].commits)
                date = this.TotalCommits[j][0].timestamp
              }
              if(j===1 && (this.TotalCommits[j-1][0].timestamp !== this.TotalCommits[j][0].timestamp )){
                commit = Number(this.TotalCommits[j-1][0].commits)
                date = this.TotalCommits[j-1][0].timestamp
              }
              if(this.TotalCommits[j-1][0].timestamp !== this.TotalCommits[j][0].timestamp || j===this.TotalCommits.length-1){
                
                //console.log(this.TotalCommits[j][0].timestamp)
                if(commit !== 0){
                  let conv = new Date(date).toLocaleDateString()
                  let year = conv.split('/')[2]
                  let month = conv.split('/')[0]
                  let day = conv.split('/')[1]
                  //console.log(day+'/'+month+'/'+year)
                  const sumCommit = [{
                    commits: commit,
                    timestamp: day+'/'+month+'/'+year
                  }]
                  temp.push(sumCommit)
                  commit = 0
                  date = 0
                }
                else{
                  let date = new Date(this.TotalCommits[j][0].timestamp).toLocaleDateString()
                  let year = date.split('/')[2]
                  let month = date.split('/')[0]
                  let day = date.split('/')[1]
                  const sumCommit = [{
                    commits: this.TotalCommits[j][0].commits,
                    timestamp: day+'/'+month+'/'+year
                  }]
                  //console.log(sumCommit)
                  temp.push(sumCommit)
                }
                
              }
              else{
                //console.log(this.TotalCommits[j][0].timestamp)
                
                commit = commit + Number(this.TotalCommits[j][0].commits)
                date = this.TotalCommits[j][0].timestamp
                //console.log(commit)
                
              }
            }
            let commitData = []
            for(let i=0; i<temp.length; i++){
              commitData.push(temp[i][0].commits)
              this.TotalCommitsDates.push(temp[i][0].timestamp)
            }
            this.TotalCommitsArray = [{data: commitData, label: 'Commits'}]
            this.DataProcessing = true
            //console.log(this.TotalCommitsArray)
        }
      });

      
      const pathProgress = `gitlab/${this.uidParam}/progress/data`;
      this.gitlabProgressSubscribe = this.afs.doc(pathProgress).valueChanges().subscribe(progress =>{
        if(progress){
          //console.log(progress)
          this.progressInfo = progress['info']
        }
      })
    }
  }

  async Delete() {
    this.loading = true;
    await this.gitlab.delete().then(res => {
      this.toast.success("Successfully Deleted Gitlab");
      this.loading = false;
    })
    .catch(err => {
      this.toast.error("Gitlab Delete Failed");
      console.log(err);
      this.loading = false;
    });
  }

  gitlabToggleSetting(){
    this.gitlabToggle = !this.gitlabToggle
  }
  enableEdit() {
    this.edit = true;

  }

  public chartClicked(e:any):void {
    //console.log(e);
  }

  public chartHovered(e:any):void {
    //console.log(e);
  }

  disableEdit() {
    this.edit = false;

    // Automatically Updates Repo Count for User
    let repoCount = 0;
    let repoTotal = 0;
    for (const repo of this.gitlabRepos) {
      if (repo.show) {
        repoCount++;
      }
      repoTotal++
    }
    this.afs.doc<GitLabUser>(`gitlab/${this.uidParam}`).update(
      {
        repoCount: repoCount,
        repoTotal: repoTotal,
        languages: this.gitlabUser.languages
      }
    );
  }

  changeRepoCheckbox(repo) {
    repo.show = !repo.show;
    this.afs.collection('gitlab').doc(this.uid).collection('repos').doc(repo.id.toString()).update({
        show: repo.show
      });
  }

  changeLanguageCheckbox(language) {
    language.show = !language.show;
  }

  ngOnDestroy() {
    if (this.gitlabUserSubscribe) {
      this.gitlabUserSubscribe.unsubscribe();
    }
    if (this.gitlabReposSubscribe) {
      this.gitlabReposSubscribe.unsubscribe();
    }
    if (this.gitlabProgressSubscribe) {
      this.gitlabProgressSubscribe.unsubscribe();
    }
    if (this.gitlabLanguagesSubscribe) {
      this.gitlabLanguagesSubscribe.unsubscribe();
    }
    if (this.deleteSub) {
      this.deleteSub.unsubscribe()
    }
  }

  private updatePieChart(languages) {
    if (languages) {
      this.mdbChartData = [];
      this.mdbChartLabel = [];
      for (let i = 0; i < languages.length; i++) {
        if (languages[i].show) {
          this.mdbChartData.push((languages[i].score*100).toFixed(2));
          this.mdbChartLabel.push(languages[i].language);
        }
      }
      this.mdbChartComplete = false;
      setTimeout(() => {
        this.mdbChartComplete = true;
      })
    }

    
  }

}
