import { Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService} from '../auth/auth.service';
import {tap, map, take} from 'rxjs/operators';
import { User } from '../user';

@Injectable({
  providedIn: 'root'
})
export class ProfileVerifyGuard implements CanActivate {

  
  user: User;
  roles: any;
  siteUID: string;
  IdToken: any;

  constructor(private auth: AuthService, private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      return this.auth.user.pipe(
        take(1),
        map(user => !!user),
        tap(async loggedIn => {
          if (!loggedIn) {
              if (isPlatformBrowser(this.platformId)) {
                this.auth.loginAndRoute('/profile');
                return false;
              } else {
                return true;
              }
            } else {
              this.siteUID = next.paramMap.get("uid")
              this.user = await this.auth.getUser();
              let uid = this.user ? this.user.uid : '';
              if (this.siteUID === uid) {
                this.roles = await this.auth.getRole();
                if (this.roles.recruiter === true) {
                  this.router.navigate([`/recruiter/${this.user.uid}`]);
                } else {
                  this.router.navigate([`/coder/${this.user.uid}`]);
                }
              } else {
                this.roles = await this.auth.getRolesByUID(this.siteUID)
                if (this.roles.recruiter === true) {
                  this.router.navigate([`/recruiter/${this.siteUID}`]);
                } else {
                  this.router.navigate([`/coder/${this.siteUID}`]);
                }
              }
              return true;
            }
          })
      );
  }
}
