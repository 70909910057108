import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-recruiter',
  templateUrl: './recruiter.component.html',
  styleUrls: ['./recruiter.component.scss']
})
export class RecruiterComponent implements OnInit {

  userUID: string;

  constructor(private route: ActivatedRoute) { 
    this.userUID = this.route.snapshot.paramMap.get("uid")
  }

  ngOnInit() {
  }


}
