import { Component, OnInit } from '@angular/core';
import { PlaceData } from 'src/app/core/google-places/google-places';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastService } from 'ng-uikit-pro-standard';
import { Position, User, RecruiterJob } from '../../core/user';
import * as firebase from 'firebase/app'
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PositionServiceService } from '../recruiter-position/position-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recruiter-jobs',
  templateUrl: './recruiter-jobs.component.html',
  styleUrls: ['./recruiter-jobs.component.scss']
})
export class RecruiterJobsComponent implements OnInit {

  add: boolean = false;
  isRecruiter: boolean = true;
  userData: any;
  positionUIDs: any[] = [];
  positions: any[] = [];
  positionLogo: string;
  positionExpired: boolean[];
  position: any;
  newCompany: string;
  recruiterUid: string;
  recruiterParam: string;
  newTitle: string;
  newMaxPay: number;
  newMinPay: number;
  newDate: string;
  newDescription: string;
  newSkills: string;
  newCity: string;
  newLogo: string = "";
  lat: any;
  lng: any;
  todaysDate: Date;
  maxDate: Date;
  component: string = "positionLocation"
  visibility: boolean = true;
  descConfig: AngularEditorConfig;
  excConfig: AngularEditorConfig;
  newPosition: Position;
  dateCreated: number;
  userSubscribe: Subscription;
  position$: Subscription;
  positionEx$: Subscription;
  positionLog$: Subscription;

  constructor(
    private toast: ToastService, 
    private auth: AuthService, 
    private router: Router,
    private route: ActivatedRoute, 
    private positionService: PositionServiceService) { }

  ngOnInit() {
    this.todaysDate = new Date();
    this.maxDate = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth() + 1, this.todaysDate.getDate())
    this.descConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      width: '100%',
      placeholder: 'Enter detailed description here...',
      translate: 'no',
    };
    this.excConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      width: '100%',
      placeholder: 'Enter a brief description here...',
      translate: 'no',
    };
    this.positionExpired = new Array();
    this.recruiterParam = this.route.snapshot.paramMap.get("uid");
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.positions = [];
      if (user) {
        this.recruiterUid = user.uid;
        this.positionLogo = user.companyLogo;
        if (this.recruiterParam === this.recruiterUid) {
          this.isRecruiter = true;
          this.retrieveRecruiterJobs();
          this.positionEx$ = this.positionService.positionsExpired$.subscribe(posEx => {
            this.positionExpired = posEx;
          });
        } else {
          this.isRecruiter = false;
        }
      } else {
        this.isRecruiter = false;
      }
    });
  }

  async retrieveRecruiterJobs() {
    const recruiter = await this.positionService.getRecruiter(this.recruiterUid);
    const recruiterPositions = recruiter.positions;
    this.positions = await this.positionService.getPositionDoc(recruiterPositions) //await this.positionService.retrievePositionDocs(recruiterPositions);
  }
  
  locationChanged(newLocation: PlaceData, duplicate: boolean) {
    if (!duplicate) {
      if (newLocation['instance'] == this.component) {
        this.newCity = newLocation.description;
        this.lat = newLocation.lat
        this.lng = newLocation.lng
      }
    }
  }

  updateDate(event: MatDatepickerInputEvent<Date>) {
    this.newDate = new Date(event.value).toDateString();
  }

  async addPosition() {
    if(this.checkForm()) {
      this.dateCreated = new Date().getTime();
      if(this.newMaxPay || this.newMinPay) {
        this.newPosition = {
          uid: "xx", //Needs proper uid code
          creator: this.recruiterUid,
          title: this.newTitle,
          description: this.newDescription,
          company: this.newCompany,
          companyLowercase: this.newCompany.toLowerCase(),
          candidates: [],
          applicants: [],
          removed_applicants: [],
          skillsNeeded: this.newSkills,
          dateCreated: this.dateCreated,
          dateClosing: this.newDate,
          minPay: this.newMinPay,
          maxPay: this.newMaxPay,
          city: this.newCity,
          visibility: this.visibility,
          location: new firebase.firestore.GeoPoint(this.lat, this.lng),
          positionLogo: this.positionLogo
        }
      } else {
        this.newPosition = {
          uid: "xx", //Needs proper uid code
          creator: this.recruiterUid,
          title: this.newTitle,
          description: this.newDescription,
          company: this.newCompany,
          companyLowercase: this.newCompany.toLowerCase(),
          candidates: [],
          applicants: [],
          removed_applicants: [],
          skillsNeeded: this.newSkills,
          dateCreated: this.dateCreated,
          dateClosing: this.newDate,
          city: this.newCity,
          visibility: this.visibility,
          location: new firebase.firestore.GeoPoint(this.lat, this.lng),
          positionLogo: this.positionLogo
        }
      }
      await this.positionService.createPosition(this.recruiterUid, this.newPosition).then(async (positions) => {
        this.positions = await this.positionService.getPositionDoc(positions)
        console.log(this.positions)
        this.add = false;
      });
      this.newCompany = null;
      this.newTitle = null;
      this.newMaxPay = null;
      this.newMinPay = null;
      this.newDate = null;
      this.newDescription = null;
      this.newCity = null;
      this.newPosition = null;
      this.newSkills = null;
      this.dateCreated = null;
      this.add = false;
      this.lat = null;
      this.lng = null;
      this.visibility = true;
    }
  }

  addButton() {
    this.add = true;
  }

  cancelButton() {
    this.add = false;
    this.newCompany = null;
    this.newTitle = null;
    this.newMaxPay = null;
    this.newMinPay = null;
    this.newDate = null;
    this.newDescription = null;
    this.newSkills = null;
    this.newPosition = null;
    this.dateCreated = null;
    this.newCity = null;
    this.lat = null;
    this.lng = null;
  }

  checkForm() {
    if (!this.newTitle || !this.newDescription || !this.newCompany || !this.newSkills || !this.newDate || !this.newCity) {
      if (!this.newTitle) {
        this.toast.error("Please Enter a job title");
      }
      if (!this.newDescription) {
        this.toast.error("Please Enter a description");
      }
      if (!this.newCompany) {
        this.toast.error("Please Enter a company");
      }
      if (!this.newSkills) {
        this.toast.error("Please Enter the skills needed");
      }
      if (!this.newDate) {
        this.toast.error("Please Enter a date");
      }
      if (!this.newCity) {
        this.toast.error("Please Enter a location");
      }
      return false;
    }
    else {
      return true;
    }
  }

  updateJobExpire(event: MatDatepickerInputEvent<Date>, index: number, uid: string) {
    this.newDate = new Date(event.value).toDateString();
    let data = { dateClosing: this.newDate }

    this.positionService.updatePosition(uid, data).then(() => {
      this.positions[index].dateClosing = this.newDate;
      this.newDate = "";
      this.positionExpired[index] = false;
    }).catch(error => console.log(error));
  }

  viewPosition(positionUID: string) {
    if(positionUID !== "xx") {
      this.router.navigate([`position/${positionUID}`]);
    }
  }

  deletePosition(positionUID: string) {
    if(positionUID !== "xx") {
      this.positionService.deletePosition(this.recruiterUid, positionUID).then(() =>{
        this.toast.success("Position Deleted!");
      })
      .catch(err => {
        this.toast.error("Position could not be removed");
        console.log(err);
      });
    }
  }


  duplicatePosition(position: any) {
    this.add = true;
    this.newCompany = position.company;
    this.newTitle = position.title;
    this.newMaxPay = position.maxPay;
    this.newMinPay = position.minPay;
    this.newDate = position.dateClosing;
    this.newDescription = position.description;
    this.newSkills = position.skillsNeeded;
    this.visibility = position.visibility;
    this.newCity = position.city;
    this.lat = position.location._lat;
    this.lng = position.location._long;
  }

  ngOnDestroy() {
    //this.positionService.clearPositions();
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.positionEx$) {
      this.positionEx$.unsubscribe();
    }
  }
}
