import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { PositionServiceService } from '../recruiter-position/position-service.service';
import { AuthService } from 'src/app/core/auth/auth.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recruiter-report',
  templateUrl: './recruiter-report.component.html',
  styleUrls: ['./recruiter-report.component.scss']
})
export class RecruiterReportComponent implements OnInit, OnDestroy {

  positionUID: string;
  data: any;
  uid: string;
  sendCopy: boolean = false;
  position: any;
  title: string;
  company: String;
  dateClosing: any;
  minPay: number;
  maxPay: number;
  creator: string;
  skillsNeeded: String;
  description: String;
  city: String;
  userId: string;
  recruiterUID: string;
  recruiterEmail: string;
  activeCandidateFName: string;
  activeCandidateLName: string;
  activeCandidateEmail: string;
  activeCandidateEmailSplice: string;
  activeCandidateCity: string;
  applicantsUID: string[] = [];
  candidatesUID: string[] = [];
  applicants: any[] = [];
  activeCandidate: any[] = [];
  activeCandidateWorkHistory: any[] = [];
  activeCandidateEducationHistory: any[] = [];
  candidates: any[] = [];
  checkPositions: any[] = [];
  assessmentEmails: string[] = [];
  workHistory: any[] = [];
  educationHistory: any[] = [];
  user: any;
  recruiter: boolean = false;
  coder: boolean = false;
  noUser: boolean = false;
  applied: boolean = false;
  ownsPosition: boolean = false;

  positions: any[] = [];

  positionSubscribe: Subscription;
  candidateSubscribe: Subscription;

  constructor(
    private afs: AngularFirestore, 
    private route: ActivatedRoute, 
    private auth: AuthService,
    private positionService: PositionServiceService) { }

  ngOnInit() {
    this.positionUID = this.route.snapshot.paramMap.get("uid");
    this.positionSubscribe = this.positionService.positions$.subscribe(pos => {
      if (pos) {
        this.positions = pos;
        for (let i = 0 ; i < this.positions.length ; i++) { // get position data 
          if (this.positionUID === this.positions[i].uid) {
            this.position = this.positions[i];
            this.title = this.position.title;
            this.company = this.position.company;
            this.dateClosing = this.position.dateClosing;
            this.minPay = this.position.minPay;
            this.maxPay = this.position.maxPay;
            this.skillsNeeded = this.position.skillsNeeded;
            this.description = this.position.description;
            this.city = this.position.city;
            this.applicantsUID = this.position.applicants;
            this.candidatesUID = this.position.candidates;
            this.creator = this.position.creator;

            this.positionService.getCandidates(this.candidatesUID);

            this.candidates = [];
            this.candidateSubscribe = this.positionService.candidates$.subscribe(result => {
              if (result) {
                for (let i = 0; i < result.length; i++) {
                  if (i === 0) {
                    this.activeCandidate.push(result[i]);
                    this.activeCandidateWorkHistory = result[i].workHistory;
                    this.activeCandidateEducationHistory = result[i].educationHistory;
                    this.uid = result[i].uid;
                    if(this.activeCandidateEmail) {
                      this.activeCandidateEmailSplice = this.activeCandidateEmail;
                      this.activeCandidateEmailSplice.slice().lastIndexOf("@");
                    }
                  } else {
                    this.candidates.push(result[i]);
                  }
                }
              }
            });
          }
        }
      }
    });

  }

  ngOnDestroy() {
    if (this.positionSubscribe) {
      this.positionSubscribe.unsubscribe()
    }
    if (this.candidateSubscribe) {
      this.candidateSubscribe.unsubscribe()
    }
  }

}
