import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { stringify } from 'querystring';

//Environment variables
import { environment } from '../../../environments/environment';
import { StackOverflowAccessToken } from './stackoverflow';


@Injectable({
  providedIn: 'root'
})
export class StackoverflowService {
  private stackOverflowKey: string = environment.stackConfig.client_id;
  private stackOverflowRedirectURI = encodeURI(environment.stackConfig.redirect_uri);
  private stackOverflowApiUrl = `${environment.apiAddress}/stackoverflow`; 
  private authUrl = `https://stackoverflow.com/oauth?client_id=${this.stackOverflowKey}&state=cyferlinx&response_type=code&redirect_uri=${this.stackOverflowRedirectURI}` 

  public accesstoken: BehaviorSubject<any> = new BehaviorSubject(null);
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<StackOverflowAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'StackOverflow Auth')
      .then(href => {
        const stackoverflowCode = new URL(href).searchParams.get('code');
        console.log(stackoverflowCode);
        if (stackoverflowCode) {
          this.getAccessToken(stackoverflowCode)
          .then(accesstoken => {
            resolve (accesstoken);
          })
          .catch(err => {
            console.log(err)
            reject(new Error('Retrieving accesstoken failed'));
          }); 
        } else {
          console.log('err' )
          reject(new Error('No code found'));
        }
      })
      .catch(err => {
        reject(new Error(stringify(err)));
      });
    });
  }
  
  private getAccessToken(code: string) {
    return new Promise<StackOverflowAccessToken>((resolve, reject) => {
      const params = `/${code}`; 
      this.http.get<StackOverflowAccessToken>(this.stackOverflowApiUrl + '/auth' + params, {headers: this.httpHeaders})
      .subscribe(res => {
        if (res) {
          resolve(res);
        } else{
          reject(new Error('auth failed'));
        }
      });
    });
  }

  //////////////
  // Update and Delete functions

  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<StackOverflowAccessToken>(this.stackOverflowApiUrl + '/update', {headers: this.httpHeaders})
      .subscribe(res => {
        if (res) {
          resolve(res);
        } else{
          reject(new Error('auth failed'));
        }
      });
    });
  }

  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.stackOverflowApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
