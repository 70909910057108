import { NgModule, Optional, SkipSelf,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth/auth.service';
import { WindowService } from './window/window.service';
import { GooglePlacesService } from './google-places/google-places.service'


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [AuthService,
    WindowService,
    GooglePlacesService,],
    schemas: [NO_ERRORS_SCHEMA]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() core: CoreModule) {
    if(core) {
      console.log("Core module already running!");
    }
  }
 }
