import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import {  User } from '../../core/user';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  params = {};
  config = {};
  email: any
  mode: string
  user: any;
  password: string;
  url: string;

  routeSub: Subscription;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private afs: AngularFirestore,
    private toast: ToastService,
    private auth: AuthService //
  ) { }

  ngOnInit() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      this.params = {...params['params']};
      this.mode= this.params['mode']
      switch (this.params['mode']) {
        case 'resetPassword':
          this.handleResetPassword(this.params['oobCode']);
          break;
        case 'recoverEmail':
          break;
        case 'verifyEmail':
          this.url = window.location.origin + '/profile'
          this.handleVerifyEmail(this.params['continueUrl'], this.params['oobCode']);
          break;
        default:
      }
    });
  }
  async handleVerifyEmail(url, actionCode) {
    let emailAddress;
    if (url) {
      const emailParam = String(url).search("email=")
      emailAddress = String(url).substr(emailParam + 6) // extract email address
    }
    
    firebase.auth().applyActionCode(actionCode).then(info => {
      if (emailAddress) {
        this.router.navigate(['/profile'], {queryParams: {'email': emailAddress}});
      } else {
        this.router.navigate(['/profile']);
      }
    }).then(() => {
        // this.toast.success('Successfully verified email address')
      })
    .catch(error => {
      this.router.navigate(['/login']);
      this.toast.error('Verify Email link has expired. Please try again');
      console.log(error)
    });
  }

  handleResetPassword(actionCode) {
    firebase.auth().verifyPasswordResetCode(actionCode).then(info=> {
      if(info){
        this.email = info
        const col = this.afs.collection<User>('users', ref => ref.where('email', '==', this.email).limit(1));
        this.sub = col.valueChanges().subscribe(users =>{
          if(users){
            this.user = users[0]
          }
        })
      }

    }).catch(error=> {
      this.router.navigate(['/reset']);
      this.toast.error('Reset password link has expired. Please try again');
    });
  }
  onSubmit(){
    firebase.auth().confirmPasswordReset(this.params['oobCode'], this.password).then(() =>
        firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(()=>
        this.router.navigate(['/profile'])).then(() =>
        this.toast.success('Successfully reset password')
        )
    ).catch(error => {
      console.log(error)
    })
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
