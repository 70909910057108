import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

// Environment variables
import { environment } from '../../../environments/environment';
import {YouTubeToken} from './youtube';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  private youtubeClientId = environment.youtubeConfig.key;
  private youtubeApiUrl = `${environment.apiAddress}/youtube`;
  private redirectURI = environment.youtubeConfig.redirect_uri;
  private rootURL = 'https://accounts.google.com/o/oauth2/v2/auth';
  private scopes = 'https://www.googleapis.com/auth/youtube.readonly';
  private authUrl: string = `${this.rootURL}?client_id=${this.youtubeClientId}&redirect_uri=${this.redirectURI}&scope=${this.scopes}&response_type=code`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<YouTubeToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'YouTube Auth')
      .then(href => {
        const youtubeCode = new URL(href).searchParams.get('code');
        if (youtubeCode) {
          this.getAccessToken(youtubeCode)
          .then(accesstoken => {
            resolve (accesstoken);
          })
          .catch(err => {
            reject(new Error('auth failed'));
          });
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {
        reject(new Error((err)));
      });
    });

  }

  private getAccessToken(code: string) {
    return new Promise<YouTubeToken>((resolve, reject) => {
      console.log(code);
      const params = `?code=${code}`;
      this.http.get<YouTubeToken>(this.youtubeApiUrl + '/access_token' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
}
