import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

// Environment variables
import { environment } from '../../../environments/environment';
import {GitlabAccessToken} from './gitlab';


@Injectable({
  providedIn: 'root'
})
export class GitlabService {

  private gitlabClientId = environment.gitLabConfig.client_id;
  private gitlabApiUrl = `${environment.apiAddress}/gitlab`;
  private rootURL = 'https://gitlab.com/oauth';
  private authUrl = `${this.rootURL}/authorize?client_id=${this.gitlabClientId}&response_type=code&redirect_uri=${environment.gitLabConfig.redirect_uri}`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<GitlabAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'Gitlab Auth')
      .then(href => {
        const gitlabCode = new URL(href).searchParams.get('code');
        if (gitlabCode) {
/*           if (gitlabStatus == "success") {
            resolve(gitlabStatus);
          }
          else {
            reject(new Error(gitlabStatus));
          } */
          this.getAccessToken(gitlabCode)
          .then(accesstoken => {
            resolve(accesstoken);
          })
          .catch(err => {
            reject(new Error('auth failed'));
          });
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {
        reject(new Error((err)));
      });
    });

  }

  public getAccessToken(code: string) {
    return new Promise<GitlabAccessToken>((resolve, reject) => {
      console.log(code);
      const params = `/${code}`;
      this.http.get<GitlabAccessToken>(this.gitlabApiUrl + '/auth' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.gitlabApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.gitlabApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
