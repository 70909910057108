import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { AdminNameLowerCaseComponent } from './admin-name-lower-case/admin-name-lower-case.component';
import { AdminRolesComponent } from './admin-roles/admin-roles.component';
import { AdminStatsComponent } from './admin-stats/admin-stats.component';
import { AdminLocationStatsComponent } from './admin-location-stats/admin-location-stats.component';
import { AdminUserTallyComponent } from './admin-user-tally/admin-user-tally.component';
import { AdminGlobalStatsComponent } from './admin-global-stats/admin-global-stats.component';
import { AdminRoutingModule } from './admin-routing.module';
@NgModule({
  declarations: [AdminComponent, AdminNameLowerCaseComponent, AdminRolesComponent, AdminStatsComponent, AdminLocationStatsComponent, AdminUserTallyComponent, AdminGlobalStatsComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    SharedModule,
    AdminRoutingModule
  ]
})
export class AdminModule { }
