import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, contactMessage } from '../../core/user';
import { AuthService } from '../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { ToastService } from 'ng-uikit-pro-standard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  name: string;
  email: string;
  message: string;
  user: User = null;
  contactMessage: contactMessage = null;
  userSubscribe: Subscription;

  constructor(public auth: AuthService,
              private http: HttpClient,
              private afs: AngularFirestore,
              private toast: ToastService) {

               }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }
  async send() {
    const Timestamp =  new Date().getTime();
    const userRef: AngularFirestoreDocument<contactMessage> = this.afs.doc<contactMessage>(`contactMessages/${Timestamp}`);
    if (this.user) {
      if (this.message != null) {
        const ContactMessage: contactMessage = {
          uid: this.user.uid,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          message: this.message,
          timestamp: Timestamp
        };
        await userRef.set(ContactMessage);
        await this.sendMessage(Timestamp);
        this.toast.success('Message Sent');
        this.message = null;
      } else {
        this.toast.error('Please enter a message');
      }
    } else {
      if (this.name != null && this.email != null && this.message != null) {
        const ContactMessage: contactMessage = {
          uid: 'Not Authenticated',
          firstName: this.name,
          lastName: '',
          email: this.email,
          message: this.message,
          timestamp: Timestamp
        };
        await userRef.set(ContactMessage);
        await this.sendMessage(Timestamp);
        this.toast.success('Message Sent');
        this.message = null;
        this.name = null;
        this.email = null;
      } else {
        this.toast.error('Please make sure all fields have data entered');
      }
    }
  }

  async sendMessage(timestamp) {
    return new Promise<any>((resolve, reject) => {
      const params = `/${timestamp}`;
      this.http.get<any>(environment.apiRoute + '/contactMessage' + params).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }
}
