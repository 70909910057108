// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDRjNCq-DzXzc22SBCuufC47UmSiwLhVhE",
    authDomain: "hackerlinxdev-a70df.firebaseapp.com",
    databaseURL: "https://hackerlinxdev-a70df.firebaseio.com",
    projectId: "hackerlinxdev-a70df",
    storageBucket: "hackerlinxdev-a70df.appspot.com",
    messagingSenderId: "83511232378",
    appId: "1:83511232378:web:5aef76d61f9199fd"
  },
  githubConfig: {
    // local
    // client_id: '7108d33f7311cf95b8ce',
    // client_secret: 'e554b4078942dfdfa133a17536c14a5c1f591526'
    // dev
    client_id: 'fb3ce23233f29a902e02',
    client_secret: '7acbe6bc69a6d6a9bc8e272c469f07639f8a6ccb'
    
  },
  bitbucketConfig: {
    key: '9KqDtnZEqcwQBTBZEm',
    secret: 'p3vYeGKCtHGuDFBLy6CwSAW3wjKnPbuX' 
  },
  gitLabConfig: {
    client_id: '1ce9aff1ec530ce4ffb01dfb994e74e2462b0fc0e4bff933efabefcce3f550c1',
    //redirect_uri: 'http://localhost:4200/gitlab-redirect'
    redirect_uri: 'https://dev.cyferlinx.com/gitlab-redirect'
  },
  stackConfig: {
    client_id: '12575',
    client_secret: 'cLXYtkFfjgJApDu66lHhEg((',
    key: 'oNqFHowRkpyxSnQ0n)AvyA((',
    // redirect_uri: 'http://local.cyferlinx.com/stackoverflow-redirect'
    redirect_uri: 'https://dev.cyferlinx.com/stackoverflow-redirect'
    // redirect_uri: 'https://hackerlinxdev-a70df.firebaseapp.com/stackauth'
  },
  linkedinConfig: {
    client_id: '775gfisqsekn2i',
    client_secret: 'N6fPZqXRj0Don4Pr',
    // redirect_uri: 'http://localhost:4200/linkedin-redirect',
    redirect_uri: 'https://dev.cyferlinx.com/linkedin-redirect',
  },
  cloudFunctionConfig: {
    bitbucketLogin: 'https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/BitbucketLogin',
    githubUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/github/",
    stackUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/stack/",
    bitbucketUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/bitbucket/",
    topcoderLink: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/topcoder/link/",
    topcoderUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/topcoder/update/",
    gitlabUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/gitlab/",
    codewarsUpdate: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api/codewars/"
  },
  developmentEnv: "Development",
  metadata: {
    name: 'CYFERLINX',
    url: "https://dev.cyferlinx.com",
    image: 'https://www.cyferlinx.com/assets/images/LogoSquare.png',
    site: '@CYFERLINX',
    video: 'https://www.youtube.com/watch?v=0X-QBCT_syA'
  },
  meetupConfig: {
    key: 's5o0o7mismg55kloutj479k1kb',
    secret: '541hehfhjc5uhkcc8ocvgoeuka',
    //redirect_uri: 'http://localhost:4200/meetup-redirect'
    redirect_uri: 'https://dev.cyferlinx.com/meetup-redirect'
  },
  mediumConfig: {
    client_id: '8d3f81a21949',
    client_secret: '53e85af971e066ed4c0db50bf4bce6e071ac693a',
    // redirect_uri: 'http://local.cyferlinx.com/medium-redirect'
    redirect_uri: 'https://dev.cyferlinx.com/medium-redirect'
  },
  twitterConfig: {
    key: 'sqV3owuDJkQiCC4tT8O20uGC9 ',
    secret: 'l0mV7npvZ3w6KMBW6IKZAHhkAf9CJ7uA2EWDWY5gfNeg9phu2f',
  },
  youtubeConfig: {
    key: '617112645830-0fuhhpftnde8c9oj5okt1mrgisora33d.apps.googleusercontent.com', //'83511232378-huon0lsskgi5fujumsmatvj4b7dvp0c7.apps.googleusercontent.com',
    redirect_uri: 'http://localhost:4200/youtube-redirect'
  },
  googleAPIKey: "AIzaSyDsSo1aQWDo5qSnPlRDYkKHV04fU2swgCI",
  apiRoute: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net/api",
  apiAddress: "https://us-central1-hackerlinxdev-a70df.cloudfunctions.net",
  //apiAddress: "https://dev.cyferlinx.com",
};
