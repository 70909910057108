import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard'; 

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  error: any;
  constructor(public auth: AuthService,
    private router: Router,
    private toast: ToastService,) { }

  ngOnInit() {}
  
  onSubmit(formData: any) {
    this.auth.resetPassword(this.email)
    .then((message) => this.afterReset(message))
    .catch((error) => this.handleError(error));
  }
  //Reset Password
  resetPassword(){
    //console.log(this.email)
    this.auth.resetPassword(this.email)
  }
  private afterReset(message): void {
    //console.log(message)
    if(message){
      if(message.message = "Password Reset Email Sent"){
        this.router.navigate(['/login']);
      }
    }    
  }
  private handleError(error) {
    this.toast.error(error.message)
  }
}
