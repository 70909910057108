import { Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService} from '../auth/auth.service';
import {tap, map, take} from 'rxjs/operators';
import { User } from '../user';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  user: User;
  roles: any;

  constructor(private auth: AuthService, private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
      return this.auth.user.pipe(
        take(1),
        map(user => !!user),
        tap(async loggedIn => {
            if (!loggedIn) {
              if (isPlatformBrowser(this.platformId)) {
                this.auth.loginAndRoute('/profile');
                return false;
              } else{
                return true;
              }
            }
            else {
              this.user = await this.auth.getUser();
              this.roles = await this.auth.getRole();

              if (this.roles['recruiter'] === true) {
                this.router.navigate([`/recruiter/${this.user.uid}`]);
              }
              else if (this.roles['coder'] === true) {
                this.router.navigate([`/coder/${this.user.uid}`],{queryParams: next.queryParams});
              }
              return true;
            }
        })
    )
  }
  
}
