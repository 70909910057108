import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User, RecruiterJob } from '../../core/user';
import { AuthService } from '../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';


@Component({
  selector: 'app-job-search-result',
  templateUrl: './job-search-result.component.html',
  styleUrls: ['./job-search-result.component.scss']
})
export class JobSearchResultComponent implements OnInit,OnDestroy {

  @Input() job;

  recruiter: any;
  private recruiterSub: Subscription;
  avatarPath: String;
  user: User;
  userSubscribe: Subscription;
  roleSub: Subscription;
  profile: string;
  Roles: any;
  applied = false;
  uidParam: any;
  userName: any;
  showMoreInfo: boolean = false;


  constructor(private auth: AuthService,  private afs: AngularFirestore) { }

  ngOnInit() {
    this.recruiterSub = this.afs.doc<any>(`users/${this.job.creator}`).valueChanges().subscribe(user =>{//this.auth.getPublicUserData(this.job.creator).subscribe(user => {
      if(user) {
        this.recruiter = user;
        // this.avatarPath = this.recruiter.companyLogo;
      }
    });
  }
  locationLink(city: string) {
    let location = "https://www.google.com/maps/search/?api=1&query=" + city;
    let win = window.open(location,"_blank")
    win.focus()
  }

  toggleMoreInfo() {
    this.showMoreInfo = !this.showMoreInfo;
  }

  ngOnDestroy(){
    if(this.recruiterSub){
      this.recruiterSub.unsubscribe()
    }
    if(this.userSubscribe){
      this.userSubscribe.unsubscribe();
    }
    if(this.roleSub){
      this.roleSub.unsubscribe();
    }
  }

}
