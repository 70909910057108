import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
//firebase.initializeApp(environment.firebaseConfig)


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userSubscribe: Subscription;
  email: string;
  password: string;
  error: any
  screenTrace : firebase.performance.Trace;
  isBrowser: boolean;
  perf:any;
  redirectRoute: string;

  constructor( @Inject(PLATFORM_ID) platformId: Object,
    public auth: AuthService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute)
  { this.isBrowser = isPlatformBrowser(platformId); }

  onSubmit(formData: any) {
    this.auth.emailLogin(this.email, this.password)
      .then(() => {

      })
      .catch(error => { console.log(error); this.error = error});
  }

  ngOnInit() {
    this.title.setTitle('CYFERLINX LOGIN')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + '/login'},
      { property: 'og:title', content: 'CYFERLINX LOGIN'},
      { property: 'og:description', content: 'Login to Cyferlinx to create your tech passport'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'Login to Cyferlinx to create your tech passport'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content: 'CYFERLINX LOGIN'},
      { name: 'twitter:description', content: 'Login to Cyferlinx to create your tech passport'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
    //console.log("Is browser: " + this.isBrowser);
    /*if(this.isBrowser){
      this.perf = firebase.performance();
      this.screenTrace = this.perf.trace('loginScreen')
      this.screenTrace.start();
      console.log("In Browser")
    }*/

    let params = this.route.snapshot.queryParams
    if (params['redirectRoute']) {
        this.redirectRoute = params['redirectRoute'];
    }

    this.userSubscribe = this.auth.currentUser.subscribe((user) => {
      if (user) {
        console.log("Signed In")
        if (this.redirectRoute){
          this.router.navigate([this.redirectRoute])
          .catch(() => {this.router.navigate(['/profile'])})
        } else {
          this.router.navigate(['/profile']);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    /*if(this.isBrowser){
      this.screenTrace.stop();
    }*/

  }

}
