import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './signup.component';
import { SignupRecruiterComponent } from './signup-recruiter/signup-recruiter.component';
import { SignupRecruiterCompanyComponent } from './signup-recruiter-company/signup-recruiter-company.component';
import { SignupRecruiterProfileComponent } from './signup-recruiter-profile/signup-recruiter-profile.component';
import { SignupCoderComponent } from './signup-coder/signup-coder.component';
import { SignupCoderProfileInfoComponent } from './signup-coder-profile-info/signup-coder-profile-info.component';
import { SignupCoderPositionComponent } from './signup-coder-position/signup-coder-position.component';

const routes: Routes = [
    { path: '', component: SignupComponent },
    { path: 'recruiter', component: SignupRecruiterComponent },
    { path: 'recruiter-company', component: SignupRecruiterCompanyComponent },
    { path: 'recruiter-profile', component: SignupRecruiterProfileComponent },
    { path: 'coder', component: SignupCoderComponent },
    { path: 'coder-profile', component: SignupCoderProfileInfoComponent},
    { path: 'coder-position', component: SignupCoderPositionComponent},

  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class SignupRoutingModule { }