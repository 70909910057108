
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component'

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
//import { AgmCoreModule } from '@agm/core';


import { environment } from '../../environments/environment';
export const googleAPIKey = environment.googleAPIKey;
export const firebaseConfig = environment.firebaseConfig;

import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { AcceptComponent } from './terms/accept/accept.component';
import { PrivacyComponent } from './terms/privacy/privacy.component';
import { TermsOfUseComponent } from './terms/terms-of-use/terms-of-use.component';



@NgModule({
  declarations: [NavbarComponent, FooterComponent, TermsComponent, AcceptComponent, PrivacyComponent, TermsOfUseComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    //AgmCoreModule.forRoot({ apiKey: googleAPIKey, libraries: ["places"] }),
    RouterModule
  ],
  exports: [NavbarComponent, FooterComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
