import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-invite',
  templateUrl: './home-invite.component.html',
  styleUrls: ['./home-invite.component.scss']
})
export class HomeInviteComponent implements OnInit {

  invites : [
    { src: string, title: string, description: string }, 
    { src: string, title: string, description: string }, 
    { src: string, title: string, description: string }
  ];


  values : any;

  constructor() { }

  ngOnInit() {
    this.invites = [
      {src: "invite_1.svg", title: "Share position link", description: "Invite candidates to complete their profile for a position"},
      {src: "invite_2.svg", title: "Coders sync their profile", description: "Coders connect their Cyferlinx profile or login to an existing account"},
      {src: "invite_3.svg", title: "Review Candidates", description: "Get a clear picture of a coder's skills & experience to find the best fit"}
    ]
  }

}
