import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

// Environment variables
import { environment } from '../../../environments/environment';
import {TwitterRequestToken, TwitterAccessToken} from './twitter';


@Injectable({
  providedIn: 'root'
})
export class TwitterService {

  private twitterClientId = environment.twitterConfig.key;
  private twitterApiUrl = `${environment.apiAddress}/twitter`;
  private redirectURI = environment.youtubeConfig.redirect_uri;
  private rootURL = 'https://api.twitter.com/oauth';
  private signinURL = 'https://api.twitter.com/oauth';
  private scopes = 'https://www.googleapis.com/auth/youtube.readonly';
  //private authUrl: string = `${this.rootURL}?client_id=${this.youtubeClientId}&redirect_uri=${this.redirectURI}&scope=${this.scopes}&response_type=code`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<TwitterAccessToken>((resolve, reject) => {
      this.getRequestToken()
      .then(requesttoken => {
        const twitterConnectURL = `${this.signinURL}/authorize?oauth_token=${requesttoken.token}`;
        this.auth.launchAuthPopup(twitterConnectURL, 'Twitter Auth')
          .then(href => {
            const twitterVerifier = new URL(href).searchParams.get('oauth_verifier');
            if (twitterVerifier) {
            this.getAccessToken(requesttoken.token, requesttoken.secret, twitterVerifier)
              .then(accesstoken => {
                resolve (accesstoken);
              })
              .catch(err => {
                reject(new Error('auth failed'));
              });
            } else {
              reject(new Error('auth failed'));
            }
          })
          .catch(err => {
            reject(new Error((err)));
          });
        })
      .catch(err => {
        reject(new Error('request token failed'));
      });

    });

  }

  private getAccessToken(requestToken: string, requestSecret: string, verifier: string) {
    return new Promise<TwitterAccessToken>((resolve, reject) => {
      const params = `?requestToken=${requestToken}&requestSecret=${requestSecret}&verifier=${verifier}`;
      this.http.get<TwitterAccessToken>(this.twitterApiUrl + '/access_token' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  private getRequestToken() {
    return new Promise<TwitterRequestToken>((resolve, reject) => {
      this.http.get<TwitterRequestToken>(this.twitterApiUrl + '/request_token', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  getUser() {
    return new Promise<TwitterRequestToken>((resolve, reject) => {
      this.http.get<TwitterRequestToken>(this.twitterApiUrl + '/auth', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  updateUser() {
    return new Promise<TwitterRequestToken>((resolve, reject) => {
      this.http.get<TwitterRequestToken>(this.twitterApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  deleteUser() {
    return new Promise<TwitterRequestToken>((resolve, reject) => {
      this.http.get<TwitterRequestToken>(this.twitterApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
}
