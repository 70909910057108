import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Observable,of, from, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { stringify } from 'querystring';
//Environment variables
import { environment } from '../../../environments/environment';
import {BitbucketAccessToken} from './bitbucket';

@Injectable({
  providedIn: 'root'
})
export class BitbucketService {

  private bitbucketKey: string = environment.bitbucketConfig.key;
  private bitbucketApiUrl = `${environment.apiAddress}/bitbucket`;
  private authUrl = `https://bitbucket.org/site/oauth2/authorize?client_id=${this.bitbucketKey}&response_type=code`;
  private accesstokenUrl = 'https://bitbucket.org/site/oauth2/access_token'
  public accesstoken: BehaviorSubject<any> = new BehaviorSubject(null);
  private httpHeaders: HttpHeaders;
  

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<BitbucketAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'Bitbucket Auth')
      .then(href => {
        const bitbucketCode = new URL(href).searchParams.get('code');
        if (bitbucketCode) {
          this.getAccessToken(bitbucketCode)
          .then(accesstoken => {
            resolve (accesstoken);
          })
          .catch(err => {
            reject(new Error('auth failed'));
          });
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {
        reject(new Error(stringify(err)));
      });
    });
  }

  private getAccessToken(code: string) {
    return new Promise<BitbucketAccessToken>((resolve, reject) => {
      console.log(code);
      const params = `?code=${code}`;
      this.http.get<BitbucketAccessToken>(this.bitbucketApiUrl + '/auth' + params, {headers: this.httpHeaders})
      .subscribe(res => {
        if (res) {
          resolve(res);
        } else{
          reject(new Error('auth failed'));
        }
      });
    });
  }
  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.bitbucketApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }


  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.bitbucketApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
