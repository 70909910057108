import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { UiModule} from '../ui/ui.module'
import { SignupComponent } from './signup.component';
import { SignupRecruiterComponent } from './signup-recruiter/signup-recruiter.component';
import { SignupCoderComponent } from './signup-coder/signup-coder.component';

import { AuthPopupComponent } from './auth-popup/auth-popup.component';
import { RedirectComponent } from './redirect/redirect.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SignupCoderIntegrationsComponent } from './signup-coder/signup-coder-integrations/signup-coder-integrations.component';
import { SignupCoderProfileInfoComponent } from './signup-coder-profile-info/signup-coder-profile-info.component';
import { SignupRecruiterCompanyComponent } from './signup-recruiter-company/signup-recruiter-company.component';
import { SignupRecruiterProfileComponent } from './signup-recruiter-profile/signup-recruiter-profile.component';
import { UploadDirective } from '../core/upload/upload.directive';
import { SignupCoderPositionComponent } from './signup-coder-position/signup-coder-position.component';
import { SignupRoutingModule } from './signup-routing.module';
@NgModule({
  declarations: [SignupComponent, SignupRecruiterComponent, SignupCoderComponent, AuthPopupComponent, RedirectComponent, SignupCoderIntegrationsComponent, SignupRecruiterCompanyComponent, SignupRecruiterProfileComponent, UploadDirective, SignupCoderProfileInfoComponent, SignupCoderPositionComponent],
  imports: [
    CommonModule,
    SharedModule,
    UiModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SignupRoutingModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class SignupModule { }
