import { Component, OnInit,Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AngularFirestore, AngularFirestoreCollection, DocumentData } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { WindowService } from '../../../core/window/window.service';
import {User} from '../../../core/user';
import { PlaceData } from '../../../core/google-places/google-places';


@Component({
  selector: 'app-coder-profile-basic',
  templateUrl: './coder-profile-basic.component.html',
  styleUrls: ['./coder-profile-basic.component.scss']
})
export class CoderProfileBasicComponent implements OnInit, OnDestroy {
  @Input()  isBeingEdited : boolean = false;
  @Input() saveData: boolean;
  @Input() loggedInUID: string;


  currentUser: any;
  userData: any;
  profileImage: string; // optional for now
  firstName: string;
  lastName: string;
  blurb: string;
  location: string;
  email: string;
  newEmail: string;
  verifiedEmails: string[];
  phone: number;
  website: string;
  video: string;
  skills: string;
  isPublic: boolean;
  coderInfo: any;
  loading: boolean = false;

  address: PlaceData; ///
  city: PlaceData;///

  // new stuff added by user
  userCollection: AngularFirestoreCollection<any>;

  // for upload
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  downloadURLString: String;
  isHovering: boolean;
  ifAuth = false;

  emailSelect: Array<any> = [];
  downloadSubscribe: Subscription;
  
  constructor(private afs: AngularFirestore, 
              public auth: AuthService, 
              private route: ActivatedRoute, 
              private storage: AngularFireStorage, 
              private router: Router, 
              private toast: ToastService,
              private popupWindow: WindowService) { }

  ngOnInit() {
    this.isPublic = false;
    this.currentUser = this.route.snapshot.paramMap.get('uid');
    if(this.currentUser != null){
      this.afs.collection('users').doc<User>(this.currentUser).ref.get().then(doc => {
        if (doc.exists) {
          this.userData = doc.data();
          this.profileImage = this.userData.profileImage;
          this.firstName = this.userData.firstName;
          this.lastName = this.userData.lastName;
          this.blurb = this.userData.blurb;
          this.location = this.userData.city;
          this.email = this.userData.email;
          this.phone = this.userData.phone;
          this.website = this.userData.website;
          this.video = this.userData.video;
          this.skills = this.userData.skills ? this.userData.skills[0] : "";
          this.isPublic = this.userData.isPublic;
          this.ifAuth = true;
        }
        else {
          this.toast.warning("User doesn't exist");
          this.router.navigate(['/']);
        }
      });
    }
    this.checkCodeConnections(); // for avatar
    this.setVerifiedEmails();
  }

  locationChanged(newLocation: PlaceData) {
    this.location = newLocation.description;
  }

  setVerifiedEmails() {
    setTimeout(() => {
      this.auth.getUserInfo(this.currentUser).then(user => {
        if (user.verifiedEmails) {
          this.verifiedEmails = user.verifiedEmails;
          for (let i = 0; i < this.verifiedEmails.length; i++) {
            // this.emailSelect.push({
            //     value: i, label: this.verifiedEmails[i], disabled: true
            //   })
            this.emailSelect = [...this.emailSelect, {value: i, label: this.verifiedEmails[i], disabled: true}]
          }
        }
      })
    }, 5000);      
  }



  saveProfile () {
    this.checkFields();
    const newSkills: string[] = [];
    newSkills.push(this.skills);
    this.userCollection = this.afs.collection('users');
    this.afs.collection('users').doc(this.currentUser).update({
      profileImage: this.profileImage,
      firstName: this.firstName,
      lastName: this.lastName,
      blurb: this.blurb,
      city: this.location,
      location: this.location, 
      email: this.email,
      phone: this.phone,
      website: this.website,
      video: this.video,
      skills: newSkills,
      isPublic: this.isPublic
    });
  }

  async verifyEmail() {
    if (this.newEmail) {
      await this.auth.verifyEmail(this.newEmail).then(() => {
        this.toast.success("Sent verification link")
      })
    } else {
      this.toast.error("Email field cannot be empty")
    }
  }

  checkFields() {
    if (!this.profileImage) {
      this.profileImage = null;
    }
    if (!this.firstName) {
      this.firstName = null;
    }
    if (!this.lastName) {
      this.lastName = null;
    }
    if (!this.blurb) {
      this.blurb = null;
    }
    if (!this.location) {
      this.location = null;
    }
    if (!this.email) {
      this.email = null;
    }
    if (!this.phone) {
      this.phone = null;
    }
    if (!this.website) {
      this.website = null;
    }
    if (!this.video) {
      this.video = null;
    }
    if (!this.skills) {
      this.skills = null;
    }
    if (this.isPublic === undefined) {
      this.isPublic = false;
    }
  }

  startUpload(event: FileList) {
    const file = event.item(0);
    if(file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
    }
    const path = `ProfileImages/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    const ref = this.storage.ref(path);
    this.downloadSubscribe = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(value => this.profileImage = value);
      })
    ).subscribe();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  openVideo() {
    this.popupWindow.createWindow(this.video, "Coder Profile Vide");
  }

  authCheck() {
    if (this.ifAuth === false) {
      this.router.navigate(['/login']);
    }
  }
  async delete() {
    this.loading = true;
    await this.auth.deleteProfile()
    .then(res => {
      this.loading = false;
      this.toast.success('Successfully Deleted Account');
      this.router.navigate(['/']);
    })
    .catch(err => {
      this.loading = false;
      this.toast.success('Account Deletion Failed');
      console.log(err)
    });
  }

  ///////////// AVATAR STUFF

  hasProjects: boolean = true;
  hasBitbucket: boolean;
  hasGithub: boolean;
  hasGitlab: boolean;
  hasCodinggame: boolean;
  hasLinkedin: boolean;
  hasMedium: boolean;
  hasMeetup: boolean;
  hasTwitter: boolean;

  bitbucketUser: any;
  githubUser: any;
  gitlabUser: any;
  codinggameUser: any;
  linkedinUser: any;
  mediumUser: any;
  meetupUser: any;
  twitterUser: any;

  photoLink(integration: string) {
    if (integration === 'bitbucket') this.profileImage =  this.bitbucketUser.links.avatar.href;
    else if (integration === 'github') this.profileImage = this.githubUser.photoURL;
    else if (integration === 'gitlab') this.profileImage = this.gitlabUser.photoURL;
    else if (integration === 'linkedin') this.profileImage = this.linkedinUser.profilePicture.displayImage;
    else if (integration === 'medium') this.profileImage = this.mediumUser.data.imageUrl;
    else if (integration === 'meetup') this.profileImage = this.meetupUser.photo.photo_link;
  }

  checkCodeConnections() {
    this.afs.collection('bitbucket').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasBitbucket = true;
        this.bitbucketUser = doc.data();
      } else {
        this.hasBitbucket = false;
      }
    });


    this.afs.collection('github').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasGithub = true;
        this.githubUser = doc.data();
      } else {
        this.hasGithub = false;
      }
    });
  
    this.afs.collection('gitlab').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasGitlab = true;
        this.gitlabUser = doc.data();
      } else {
        this.hasGitlab = false;
      }
    });

    this.afs.collection('linkedin').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasLinkedin = true;
        this.linkedinUser = doc.data();
      } else {
        this.hasLinkedin = false;
      }
    });

    this.afs.collection('medium').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasMedium = true;
        this.mediumUser = doc.data();
      } else {
        this.hasMedium = false;
      }
    });

    this.afs.collection('meetup').doc(this.currentUser).ref.get().then(doc => {
      if (doc.exists) {
        this.hasMeetup = true;
        this.meetupUser = doc.data();
      } else {
        this.hasMeetup = false;
      }
    });

    // this.afs.collection('twitter').doc(this.currentUser).ref.get().then(doc => {
    //   if (doc.exists) {
    //     this.hasTwitter = true;
    //     this.twitterUser = doc.data();
    //   } else {
    //     this.hasTwitter = false;
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }
  }
  
}
