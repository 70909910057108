import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { LocationStrategy, Location } from '@angular/common';


@Component({
  selector: 'app-coder-profile-share',
  templateUrl: './coder-profile-share.component.html',
  styleUrls: ['./coder-profile-share.component.scss']
})
export class CoderProfileShareComponent implements OnInit, OnDestroy {

  coderURL: string;
  uid: string;

  constructor(private _clipboardService: ClipboardService, private route: ActivatedRoute, private toast: ToastService, private location: LocationStrategy) {

  }

  ngOnInit() {
  }

  copy() {
    const appPath = location.origin + this.location.path();
    this._clipboardService.copyFromContent(appPath);
    this.toast.success('Profile link copied to clipboard');
  }

  ngOnDestroy() {

  }

}
