import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//Environment variables
import { environment } from '../../../environments/environment';
import {MeetupAccessToken} from './meetup';

@Injectable({
  providedIn: 'root'
})
export class MeetupService {

  private meetupClientId = environment.meetupConfig.key;
  private meetupApiUrl = `${environment.apiAddress}/meetup`;
  private rootURL = 'https://secure.meetup.com/oauth2';
  private authUrl = `${this.rootURL}/authorize?client_id=${this.meetupClientId}&response_type=code&redirect_uri=${environment.meetupConfig.redirect_uri}`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

  async signIn() {
    return new Promise<MeetupAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'Gitlab Auth')
      .then(href => {
        const meetupCode = new URL(href).searchParams.get('code');
        if (meetupCode) {
          this.getAccessToken(meetupCode)
          .then(accesstoken => {resolve (accesstoken)})
          .catch(err => {reject(new Error('auth failed'))});
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {reject(new Error((err)))});
    });

  }

  private getAccessToken(code: string) {
    return new Promise<MeetupAccessToken>((resolve, reject) => {
      const params = `/${code}`;
      console.log(code);
      this.http.get<MeetupAccessToken>(this.meetupApiUrl + '/auth' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.meetupApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.meetupApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
