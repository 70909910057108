import { Component, OnInit, OnDestroy } from '@angular/core';
import { WindowService } from 'src/app/core/window/window.service';
import { AngularFirestore} from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit, OnDestroy {

  homeSubscribe: Subscription;
  data: any;
  video: any;

  constructor(private popupWindow: WindowService, private db: AngularFirestore, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.homeSubscribe = this.db.doc(`admin/links`).valueChanges().subscribe(data =>{
        if (data){
          this.data = data;
          // this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.youtube)
          this.video = this.data.youtube
        }
      })
  }

  openVideo() {
    this.popupWindow.createWindow(this.video, "CYFERLINX")
  }

  ngOnDestroy() {
    if (this.homeSubscribe) {
      this.homeSubscribe.unsubscribe();
    }
  }

}
