import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mdb-pie-chart',
  templateUrl: './mdb-pie-chart.component.html',
  styleUrls: ['./mdb-pie-chart.component.scss']
})
export class MdbPieChartComponent implements OnInit {
  
  @Input() chartData: any[];
  @Input() chartLabels: string[];
  @Input() show: boolean;
  constructor() { }

  ngOnInit() {
    this.chartColors.push({
      hoverBorderColor: ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)'],
      hoverBorderWidth: 0,
      backgroundColor: this.generateColours(this.chartData.length),
      hoverBackgroundColor: this.generateBackgroundColours(this.chartData.length)
  });
  }

  public chartType:string = 'doughnut';

/*   public chartData:Array<any> = [300, 50, 100, 40, 120];

  public chartLabels:Array<any> = ['Red', 'Green', 'Yellow', 'Grey', 'Dark Grey']; */

  public chartColors:Array<any> = [];

  public chartOptions: any = {
    responsive: true, scales: {
      yAxes: [{
        ticks: { beginAtZero: true }
      }]
    }
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  private generateColours(items: number): string[]{
    const colours: string[] = [];
    //Declare RGB values of the start colour
    const startR: number = 74;
    const startG: number = 107;
    const startB: number = 119;
    //Declare RGB values of the end colour
    const endR: number = 224;
    const endG: number = 231;
    const endB: number = 234;

    for (let i=0; i < items; i++){
      //calculate the R, G, and B values by incrementing them by a divsor of the items
      const red = Math.round(startR + (((endR - startR)/items)*i));
      const hexRed = (red.toString(16).length == 1) ? "0"+ red.toString(16) : red.toString(16);

      const green = Math.round(startG + (((endG - startG)/items)*i));
      const hexGreen = (green.toString(16).length == 1) ? "0"+ green.toString(16) : green.toString(16);

      const blue = Math.round(startB + (((endB - startB)/items)*i));
      const hexBlue = (blue.toString(16).length == 1) ? "0"+ blue.toString(16) : blue.toString(16);

      colours.push("#" + hexRed + hexGreen + hexBlue);
    }
    return colours;
  }

  private generateBackgroundColours(items: number): string[]{
    const colours: string[]=[];
    for(let i=0;i<items;i++){
      colours.push("#0BE4B6");
    }
    return colours;
  }

}
