import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';



@Component({
  selector: 'admin-user-tally',
  templateUrl: './admin-user-tally.component.html',
  styleUrls: ['./admin-user-tally.component.scss']
})
export class AdminUserTallyComponent implements OnInit, OnDestroy {
  UserSubscribe: Subscription
  users = []
  coder = 0;
  recruiter = 0;
  total = 0;
  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.UserSubscribe = this.afs.collection(`users`).valueChanges().subscribe(users => {
      if (users) {
        console.log(users)
        this.users = users
      }
    })
  }
  async function() {
    console.log(this.users)
    for (let i = 0; i < this.users.length; i++) {
      //await firebase.auth().fetchSignInMethodsForEmail(this.users[i].email)
      //.then((user) => {
        //if (user) {
        //  console.log(user);
        //  if (user[0] != null) {
            if (this.users[i].companyName != null) {
              this.recruiter = this.recruiter  + 1;
            } else {
              this.coder = this.coder + 1;
            }
            this.total = this.total + 1;
         // }
        //}
      //})
    }
    console.log("Coder: " + this.coder);
    console.log("Recruiter: " + this.recruiter);
    console.log("Total: " + this.total);
    const Path = `admin/users`;
    this.afs.doc(Path).update({hacker: this.coder, recruiter: this.recruiter, total: this.total})
  }

  ngOnDestroy() {
    this.UserSubscribe.unsubscribe()
  }
}
