import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medium-redirect',
  templateUrl: './medium-redirect.component.html',
  styleUrls: ['./medium-redirect.component.scss']
})
export class MediumRedirectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.close();
  }

}
