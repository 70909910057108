import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twitter-redirect',
  templateUrl: './twitter-redirect.component.html',
  styleUrls: ['./twitter-redirect.component.scss']
})
export class TwitterRedirectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.close();
  }

}
