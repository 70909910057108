import { Component, OnInit, OnDestroy } from '@angular/core';
import { TopcoderService } from '../topcoder.service';

@Component({
  selector: 'app-topcoder-auth',
  templateUrl: './topcoder-auth.component.html',
  styleUrls: ['./topcoder-auth.component.scss']
})
export class TopcoderAuthComponent implements OnInit {

  username: string;
  password: string;
  error: any;

  constructor(private topcoder: TopcoderService) {}

  ngOnInit() {
    const topCoderToken = new URL(window.location.href).searchParams.get('token');
    if(topCoderToken) {
      window.close();
    }
  }

  async authTopCoder() {
    await this.topcoder.getAccessToken(this.username, this.password)
    .then(res => {
      window.location.href = `${window.location.href}?token=${res}`;
    })
    .catch(err => {
      this.error = err;
    });
  }

}
