import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { User, CodewarsUser, CodewarsRanks, CodewarsOverall, CodewarsLanguages } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CodewarsService } from '../codewars.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Http } from '@angular/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-codewars-profile',
  templateUrl: './codewars-profile.component.html',
  styleUrls: ['./codewars-profile.component.scss']
})
export class CodewarsProfileComponent implements OnChanges, OnDestroy {
  user: User;
  userSubscribe: Subscription;
  codewarsUserSubscribe: Subscription;
  linkcodewarsUserSubscribe: Subscription;
  codewarsUser: CodewarsUser = null;
  codewarsRanks: CodewarsRanks = null;
  codewarsLanguages: CodewarsLanguages = null;
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [350, 450, 100];
  public doughnutChartType: string = 'doughnut';
  username: string = "";
  APIKey: string = "";
  isCollapsed: any[];
  localCodewarsRanks;
  uid: any;
  codewarsToggle: boolean = false;
  loaded: any;
  edit = false;
  mdbChartComplete: boolean = true;
  waiting: boolean = false
  signin: boolean = true
  //new chart
  mdbChartLabel: string[];
  mdbChartData: number[];
  loading: boolean = false;

  @Input() uidParam: string;
  @Input() enableEditParam: boolean = true;
  @Input() disable: boolean = true;
  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: ['#00695c', '#0d47a1', '#9933CC'],
    }
  ];

  constructor(private auth: AuthService,
    private codewars: CodewarsService,
    private toast: ToastService,
    private afs: AngularFirestore,
    // private fun: AngularFireFunctions,
    private _http: Http) { }

  private codewarsHtml() {
    let url = "https://www.codewars.com/users/edit";
    window.open(url);
  }

  private linkCodewarsAccount() {
    this.waiting = true
    this.signin = false
    //console.log(this.waiting)
    //this.codewars.signIn(this.user.uid, this.username , this.APIKey);  //'ericjohns' , 'qAe6e_5jsqu6dJc69n6V'
    if (this.username != "" && this.APIKey != "") {
      //this.codewars.addAccount(this.uid, this.username , this.APIKey)
      let url = environment.cloudFunctionConfig.codewarsUpdate;
      url += this.uidParam + "/" + this.APIKey + "/" + this.username;
      this._http.get(url, {}).subscribe(res => {
        if (res) {
        }
      },
        err => {
          console.log(err)
          this.toast.error("There was an error conencting to Codewars, please try again")
          return
        })
    }


  }
  async Update() {
    this.loading = true;
    await this.codewars.update()
    .then(res => {
      this.toast.success("Successfully Updated Codewars");
      this.loading = false;
    })
    .catch(err => {
      this.toast.error("CodeWars Update Failed");
      console.log(err);
      this.loading = false;
    });
  }

  /*ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe((user) => {
      this.user = user;
      if (this.user){
        this.codewarsUserSubscribe =  this.codewars.getUser(user.uid).subscribe(codewars => {
          if (codewars){
            this.codewarsUser = codewars;
            this.updatePieChart()//codewars
          }
        });
      }
    })
  }*/
  ngOnInit() {

  }

  ngOnChanges() {
    if (this.uidParam) {
      this.uid = this.uidParam;
      this.codewarsToggle = false;
      //this.codewarsUserSubscribe =  this.codewars.getUser(this.uid).subscribe(codewars => {
      const path = `codewars/${this.uidParam}`;
      const userRef: AngularFirestoreDocument<any> = this.afs.doc<User>(path);
      this.codewarsUserSubscribe = this.afs.doc<CodewarsUser>(path).valueChanges().subscribe(codewars => {
        if (codewars) {
          this.codewarsUser = codewars;
          //console.log(codewars.ranks['overall'])
          this.loaded = codewars.leaderboardPosition
          this.waiting = false
          this.signin = false
          this.updatePieChart()//codewars


        }
        if (codewars == null) {
          this.codewarsUser = null
          this.mdbChartData = [];
          this.mdbChartLabel = [];
        }
      });
      if (this.enableEditParam == true) {
        this.codewarsToggle = true;
      }

    }
  }
  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.codewarsUserSubscribe) {
      this.codewarsUserSubscribe.unsubscribe();
    }
    if (this.linkcodewarsUserSubscribe) {
      this.linkcodewarsUserSubscribe.unsubscribe();
    }
  }
  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  private updatePieChart() { //languages: CodewarsUser[]
    //Piechart1 Data & Config
    if (this.codewarsUser) {//languages
      this.mdbChartData = [];
      this.mdbChartLabel = [];
      let LanguageArray = Object.keys(this.codewarsUser.ranks["languages"])
      let ScoreArray = [];
      let ArrayLang = this.codewarsUser.ranks["languages"];
      let i = 0
      Object.keys(this.codewarsUser.ranks["languages"]).forEach(key => {
        ScoreArray[i] = ArrayLang[key].score;
        i++;
      });
      for (let i = 0; i < Object.keys(this.codewarsUser.ranks["languages"]).length; i++) {
        /*           this.data1.push([LanguageArray[i].charAt(0).toUpperCase()+LanguageArray[i].slice(1),ScoreArray[i]]); */
        this.mdbChartLabel.push(LanguageArray[i].charAt(0).toUpperCase() + LanguageArray[i].slice(1));
        this.mdbChartData.push(ScoreArray[i]);
      }
      this.mdbChartComplete = false;
      setTimeout(() => {
        this.mdbChartComplete = true;
      })
    }
  }

  enableEdit() {
    this.edit = true;
  }
  disableEdit() {
    this.edit = false;
  }
  codewarsToggleSetting() {
    this.codewarsToggle = !this.codewarsToggle
    //console.log(this.githubToggle)
  }
  async Delete() {
    this.loading = true;
    await this.codewars.delete()
    .then(res => {
      this.loading = false;
      this.toast.success("Successfully Deleted Codewars");
    })
    .catch(err => {
      this.toast.error("CodeWars Delete Failed");
      this.loading = false;
      console.log(err);
    });


  }

}
