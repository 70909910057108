import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-websummit',
  templateUrl: './websummit.component.html',
  styleUrls: ['./websummit.component.scss']
})
export class WebsummitComponent implements OnInit, OnDestroy {
  checkModel = { Recuriter: false, Employer: false, show: true};
  roles = ["Recuriter", "Employer"];
  multiSelect: string[] = [
    "Finding talent",
    "Slow hiring processes",
    "Accurately assessing coders",
    "Competition",
    "Candidate experience",
    "Poor hiring",
    "Diversity",
  ];
  empSelect: string[] = this.multiSelect;
  json = {};
  question1: string[] = [];
  question2: string = "";
  question3: string = "";
  question4: string = "";
  email: string = "";
  company: string = "";
  other: string = "";

  submitSubscribe: Subscription;

  private apiUrl = `${environment.apiRoute}`;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService,private toast: ToastService) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders)
  }

  ngOnInit() {
    this.empSelect.push("Retention");
  }
 
  show(role){
    this.checkModel.show = false;
    switch (role) {
      case "Recuriter":
        this.checkModel.Recuriter = !this.checkModel.Recuriter;
        break;
      case "Employer":
        this.checkModel.Employer = !this.checkModel.Employer;
        break;
    }
  }

  selected(selection){
    var index = this.question1.indexOf(selection);
    if (index > -1) {
      this.question1.splice(index, 1);
    } else {
      this.question1.push(selection);
    }
  }

  async onSubmit(formData: any) {
    this.json["question1"] = this.question1.join(' , ');
    this.json["Other"] = this.other;
    this.json["question2"] = this.question2;
    this.json["question3"] = this.question3;
    this.json["question4"] = this.question4;
    this.json["email"] = encodeURIComponent(this.email);
    this.json["company"] = this.company;
    this.json["subject"] = 'WebSummit Survey Result';
    const params: string = `?answers=${JSON.stringify(this.json)}`;
    this.submitSubscribe = await this.http.get<any>(this.apiUrl + '/user/submitWebsummitSurvey' + params).subscribe(res => {
      if (res) {
        //console.log(res);
        this.question1 = [];
        this.question2 = "";
        this.question3 = "";
        this.question4 = "";
        this.other = "";
        this.email = "";
        this.company = "";
        this.toast.success("Survey Results Sent!");
      } else {
        //console.log('send failed');
        this.toast.error("Survey Results Failed to Send");
      }
    });
  }

  ngOnDestroy() {
    if (this.submitSubscribe) {
      this.submitSubscribe.unsubscribe();
    }
  }
}