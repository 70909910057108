import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard/auth.guard';
import { AdminGuard } from './core/admin.guard/admin.guard';
import { ProfileGuard } from './core/profile.guard/profile.guard';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './ui/login/login.component';
import { AdminComponent } from './admin/admin.component';
import { TermsComponent } from './shared/terms/terms.component';
import { ContactComponent } from './ui/contact/contact.component';
import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component'
import { ResetPasswordComponent } from './ui/reset-password/reset-password.component';
import { VerifyEmailComponent } from './ui/verify-email/verify-email.component';
import { VerifyEmailSuccessComponent } from './ui/verify-email/verify-email-success/verify-email-success.component'
import { CoderComponent } from './coder/coder.component'
import { RecruiterComponent } from './recruiter/recruiter.component'
import { WebsummitComponent } from './ui/websummit/websummit.component';

//Integration Route Components
import { GithubRedirectComponent} from './integrations/github/github-redirect/github-redirect.component'
import { BitbucketRedirectComponent } from './integrations/bitbucket/bitbucket-redirect/bitbucket-redirect.component';
import { GitlabRedirectComponent } from './integrations/gitlab/gitlab-redirect/gitlab-redirect.component';
import { MeetupRedirectComponent } from './integrations/meetup/meetup-redirect/meetup-redirect.component';
import { TopcoderAuthComponent } from './integrations/topcoder/topcoder-auth/topcoder-auth.component';
import { SignupCoderIntegrationsComponent } from './signup/signup-coder/signup-coder-integrations/signup-coder-integrations.component';
import { CodewarsAuthComponent } from './integrations/codewars/codewars-auth/codewars-auth.component';
import { YoutubeRedirectComponent } from './integrations/youtube/youtube-redirect/youtube-redirect.component';
import { TwitterRedirectComponent } from './integrations/twitter/twitter-redirect/twitter-redirect.component';
import { CodingameAuthComponent } from './integrations/codingame/codingame-auth/codingame-auth.component';
import { LinkedinRedirectComponent } from './integrations/linkedin/linkedin-redirect/linkedin-redirect.component';
import { MediumRedirectComponent } from './integrations/medium/medium-redirect/medium-redirect.component';
import { BetaSignupComponent } from './ui/beta-signup/beta-signup.component';
import { StackoverflowRedirectComponent } from './integrations/stackoverflow/stackoverflow-redirect/stackoverflow-redirect.component';


import { LocalSyncComponent } from './local-sync/local-sync.component';


const routes: Routes = [
  { path: '', component: HomeComponent, },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: 'connect', component: SignupCoderIntegrationsComponent, canActivate: [AuthGuard]},
  { path: 'github-redirect', component: GithubRedirectComponent },
  { path: 'bitbucket-redirect', component: BitbucketRedirectComponent},
  { path: 'gitlab-redirect', component: GitlabRedirectComponent},
  { path: 'meetup-redirect', component: MeetupRedirectComponent},
  { path: 'topcoder-auth', component: TopcoderAuthComponent},
  { path: 'codingame-auth', component: CodingameAuthComponent},
  { path: 'codewars-auth', component: CodewarsAuthComponent},
  { path: 'youtube-redirect', component: YoutubeRedirectComponent},
  { path: 'twitter-redirect', component: TwitterRedirectComponent},
  { path: 'linkedin-redirect', component: LinkedinRedirectComponent},
  { path: 'medium-redirect', component: MediumRedirectComponent},
  { path: 'stackoverflow-redirect', component: StackoverflowRedirectComponent},
  { path: 'login', component: LoginComponent },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: 'terms/:id', component: TermsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'verify', component: VerifyEmailComponent},
  { path: 'verify-success', component: VerifyEmailSuccessComponent},
  { path: 'profile', component: CoderComponent, canActivate: [ProfileGuard]},
  { path: 'coder', loadChildren: () => import('./coder/coder.module').then(m => m.CoderModule)},
  { path: 'recruiter', loadChildren: () => import('./recruiter/recruiter.module').then(m => m.RecruiterModule)},
  { path: 'position', loadChildren: () => import('./recruiter/recruiter-position/position.module').then(m => m.PositionModule)},
  { path: 'jobs', loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule)},
  { path: 'websummit', component: WebsummitComponent},
  { path: 'beta-signup', component: BetaSignupComponent},
  { path: 'localsync', component: LocalSyncComponent},
  { path: '404', component: PageNotFoundComponent},
  { path: '**', redirectTo: '/404'},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule { }
