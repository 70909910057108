import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'verify-email-success',
  templateUrl: './verify-email-success.component.html',
  styleUrls: ['./verify-email-success.component.scss']
})
export class VerifyEmailSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
