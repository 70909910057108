import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { LocationStrategy, Location } from '@angular/common';


@Component({
  selector: 'app-recruiter-report-share',
  templateUrl: './recruiter-report-share.component.html',
  styleUrls: ['./recruiter-report-share.component.scss']
})
export class RecruiterReportShareComponent implements OnInit {

  coderURL: string;
  uid: string;

  constructor(private _clipboardService: ClipboardService, private route: ActivatedRoute, private toast: ToastService, private location: LocationStrategy) {

  }

  ngOnInit() {
  }

  copy() {
    const appPath = location.origin + this.location.path();
    this._clipboardService.copyFromContent(appPath);
    this.toast.success('Report Link Copied to clipboard');
  }

}
