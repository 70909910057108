import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import { User } from '../core/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  user: User = null;
  userSubscribe: Subscription;

  constructor(public auth: AuthService,) { }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }
  ngOnDestroy(){
    this.userSubscribe.unsubscribe();
  }
}
