import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PlaceData } from 'src/app/core/google-places/google-places';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';

@Component({
  selector: 'app-signup-coder-position',
  templateUrl: './signup-coder-position.component.html',
  styleUrls: ['./signup-coder-position.component.scss']
})
export class SignupCoderPositionComponent implements OnInit {
  validationForm: FormGroup;
  termsAccept: boolean;
  finalLocation: any;
  title: string;
  creator: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  positionLogo: string;
  candidates: any[] = [];
  positions: any[] = [];
  applicants: any[] = [];
  error: any;
  user: any;
  userUid: string;
  positionData: any;
  userData: any;
  positionUID: string;
  positions$: Subscription;
  component = 'userForm';
  httpHeaders: HttpHeaders;
  httpHeadersSub: Subscription;
  loadingCreate: boolean = false;
  loadingLogin: boolean = false;

  constructor(
    private fb: FormBuilder, 
    private auth: AuthService, 
    private router: Router, 
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private toast: ToastService,
    private http: HttpClient) { 
      this.validationForm = fb.group({
        emailFormEx: [null, [Validators.required, Validators.email]],
        passwordFormEx: [null, Validators.required],
      });
      
  }

  ngOnInit() {
    this.positionUID = new URL(window.location.href).searchParams.get('positionUID');
    // console.log(this.positionUID)
    this.afs.collection('positions').doc(this.positionUID).ref.get().then(doc => {
      if (doc.exists) {
        this.positionData = doc.data();
        this.title = this.positionData.title;
        this.creator = this.positionData.creator;
        this.positionLogo = this.positionData.positionLogo;
        this.afs.collection('users').doc(this.creator).ref.get().then(doc => {
          if (doc.exists) {
            this.user = doc.data();
            this.firstName = this.user.firstName;
            this.lastName = this.user.lastName;
          }
        });
      }
    });


  }

  changeTerms() {
    this.termsAccept = !this.termsAccept;
  }

  locationChanged(newLocation: PlaceData) {
    this.finalLocation = newLocation;
  }

  async onSubmit(formData: any) {
    const hacker = true;
    this.loadingCreate = true;
    await this.auth.emailSignUp(this.emailFormEx.value, this.passwordFormEx.value, hacker, this.finalLocation)
    .then(() => {
      this.auth.emailLogin(this.emailFormEx.value, this.passwordFormEx.value)
        .then(async () => {
          await this.auth.applicationSubmision(this.positionUID).then(async () => {
            this.loadingCreate = false;
            this.toast.success("Successfully Submitted An Application");
            this.router.navigate(['connect'], { queryParams: {positionUID: this.positionUID}});
            await this.auth.setNewUser();
          })
          .catch(err => {
            this.loadingCreate = false;
            this.toast.success("Thanks for signing in! Hit Apply to finish");
            console.log(err);
            this.router.navigate([`position/${this.positionUID}`])
          });
        })
    }).catch(err => console.log(err))
  }

  get emailFormEx() { return this.validationForm.get('emailFormEx'); }
  get passwordFormEx() { return this.validationForm.get('passwordFormEx'); }

  login() {
    this.loadingLogin = true;
    this.auth.emailLogin(this.email, this.password)
    .then(async () => {
      this.auth.getVerifyToken().then(async token => {
        await this.auth.applicationSubmision(this.positionUID).then(res => {
          // do something with github here
          this.loadingLogin = false
          this.toast.success("Successfully Submitted An Application");
          this.router.navigate([`position/${this.positionUID}`])
          //console.log('Message: ' + (res));
        })
        .catch(err => {
          // handle failure in the connection of github
          this.loadingLogin = false
          this.toast.success("Thanks for signing in! Hit Apply to finish");
          console.log(err);
          this.router.navigate([`position/${this.positionUID}`])
        });
      })


    }).catch(error => { console.log(error); this.error = error});
  }

  ngOnDestroy () {
    if (this.httpHeadersSub) {
      this.httpHeadersSub.unsubscribe();
    }
    
    
  }


}
