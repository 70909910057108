import { Component, OnInit, OnDestroy } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'admin-stats',
  templateUrl: './admin-stats.component.html',
  styleUrls: ['./admin-stats.component.scss']
})
export class AdminStatsComponent implements OnInit, OnDestroy {
  UserSubscribe: Subscription
  githubSubscribe: Subscription
  githubRepoSubscribe: Subscription
  gitlabSubscribe: Subscription
  gitlabRepoSubscribe: Subscription
  bitbucketSubscribe: Subscription
  bitbucketRepoSubscribe: Subscription
  blastfurnaceSubscribe: Subscription
  codewarsSubscribe: Subscription
  users = []
  constructor(private afs: AngularFirestore) { }
  

  ngOnInit() {
    this.UserSubscribe = this.afs.collection(`users`).valueChanges().subscribe(users => {
      if (users) {
        console.log(users)
        this.users = users
      }
    })
  }
  function(){
    console.log(this.users)
    for(let i = 0; i<this.users.length; i++){
      console.log(this.users[i].uid)
      let lat: number, long :number
      let uid = this.users[i].uid
      if(this.users[i].location != null){
        lat = this.users[i].location.latitude
        long = this.users[i].location.longitude
      }
      else{
        lat = 0
        long = 0
      }
      const locationData = new firebase.firestore.GeoPoint(lat, long)
      const Path = `share/${uid}`;
      let dataShared = {};
      let dataPast = {};
      let githubLanguages = []
      let githubRepos = 0
      let gitlabLanguages = []
      let gitlabRepos = 0
      let bitbucketLanguages = []
      let bitbucketRepos = 0
      let blastFurnaceLanguages = []
      let codewarsLanguages = []
      let languages = []
      let languageList = []
      let shared = {}
      let Total = 0
     

      this.githubSubscribe = this.afs.doc(`githubLanguages/${uid}`).valueChanges().pipe(take(1)).subscribe(github => {
        if(github){
          let githubLang = Object.keys(github)
          for(let k=0; k<githubLang.length; k++){
            if(githubLang[k] != "uid" && githubLang[k] != "location"){
              githubLanguages.push(githubLang[k])
            }
          }
          languageList = _.uniq([...languageList, ...githubLanguages])
          //console.log("github lang "+githubLanguages)
        }
      })
      this.githubRepoSubscribe = this.afs.collection( `github/${uid}/repos`).valueChanges().pipe(take(1)).subscribe(repos =>{
        if(repos){
          Total = Total + repos.length
          githubRepos = repos.length
          //console.log("Total "+Total)
        }
      })

      this.gitlabSubscribe = this.afs.doc(`gitlabLanguages/${uid}`).valueChanges().pipe(take(1)).subscribe(gitlab => {
        if(gitlab){
          let gitlabLang = Object.keys(gitlab)
          for(let k=0; k<gitlabLang.length; k++){
            if(gitlabLang[k] != "uid" && gitlabLang[k] != "location"){
              gitlabLanguages.push(gitlabLang[k])
            }
          }
          languageList = _.uniq([...languageList, ...gitlabLanguages])
          //console.log("gitlab lang "+gitlabLanguages)
          //console.log("lang list "+languageList)
        }
      })
      this.gitlabRepoSubscribe = this.afs.collection( `gitlab/${uid}/repos`).valueChanges().pipe(take(1)).subscribe(repos =>{
        if(repos){
          Total = Total + repos.length
          gitlabRepos = repos.length
          //console.log("gitlab Repos "+gitlabRepos)
          //console.log("Total "+Total)
        }
      })

      this.bitbucketSubscribe = this.afs.doc(`bitbucketLanguages/${uid}`).valueChanges().pipe(take(1)).subscribe(bitbucket => {
        if(bitbucket){
          let bitbucketLang = Object.keys(bitbucket)
          for(let k=0; k<bitbucketLang.length; k++){
            if(bitbucketLang[k] != "uid" && bitbucketLang[k] != "location"){
              bitbucketLanguages.push(bitbucketLang[k])
            }
          }
          languageList = _.uniq([...languageList, ...bitbucketLanguages])
          //console.log("bitbucket lang "+bitbucketLanguages)
          //console.log("lang list "+languageList)
        }
      })
      this.bitbucketRepoSubscribe = this.afs.collection( `bitbucket/${uid}/repos`).valueChanges().pipe(take(1)).subscribe(repos =>{
        if(repos){
          Total = Total + repos.length
          bitbucketRepos = repos.length
          //console.log("bitbucket Repos "+bitbucketRepos)
          //console.log("Total "+Total)
        }
      })

      this.blastfurnaceSubscribe = this.afs.doc(`blastFurnaceUserLanguage/${uid}`).valueChanges().pipe(take(1)).subscribe(blastFurnace => {
        if(blastFurnace){
          let blastFurnaceLang = Object.keys(blastFurnace)
          for(let k=0; k<blastFurnaceLang.length; k++){
            if(blastFurnaceLang[k] != "uid" && blastFurnaceLang[k] != "location" && blastFurnaceLang[k] != "total"){
              if(blastFurnace[blastFurnaceLang[k]]>0){
                blastFurnaceLanguages.push(blastFurnaceLang[k])
              }
            }
          }
          languageList = _.uniq([...languageList, ...blastFurnaceLanguages])
          //console.log("blastFurnace lang "+blastFurnaceLanguages)
          //console.log("lang list "+languageList)
        }
      })

      this.codewarsSubscribe = this.afs.doc(`codewarsLanguages/${uid}`).valueChanges().pipe(take(1)).subscribe(codewars => {
        if(codewars){
          let codewarsLang = Object.keys(codewars)
          for(let k=0; k<codewarsLang.length; k++){
            if(codewarsLang[k] != "uid" && codewarsLang[k] != "location"){
              codewarsLanguages.push(codewarsLang[k])
            }
          }
          languageList = _.uniq([...languageList, ...codewarsLanguages])
          //console.log("codewars lang "+codewarsLanguages)
          //console.log("lang list "+languageList)
        }
      })
      
      setTimeout(() => {
        dataPast['pastList'] = []
        dataPast['pastList'].push("default")
        dataPast['pastTotal'] = 0 
        this.afs.doc(`sharePast/${uid}`).set(dataPast);
        const share = {
          uid: uid,
          location: locationData,
          bitbucketLanguages: bitbucketLanguages,
          bitbucketRepos: bitbucketRepos,
          blastFurnaceLanguages: blastFurnaceLanguages,
          codewarsLanguages: codewarsLanguages,
          githubLanguages: githubLanguages,
          githubRepos: githubRepos,
          gitlabLanguages: gitlabLanguages,
          gitlabRepos: gitlabRepos,
          languageList: languageList,
          totalRepos: Total
        };
        console.log(share)
        this.afs.doc(Path).set(share)

        
      },  i*15000+ (Math.floor(Math.random() * 10000) + 1000) )

    }
  }

  ngOnDestroy(){
    if(this.UserSubscribe){
      this.UserSubscribe.unsubscribe()
    }
    if(this.githubSubscribe){
      this.githubSubscribe.unsubscribe()
    }
    if(this.githubRepoSubscribe){
      this.githubRepoSubscribe.unsubscribe()
    }
    if(this.gitlabSubscribe){
      this.gitlabSubscribe.unsubscribe()
    }
    if(this.gitlabRepoSubscribe){
      this.gitlabRepoSubscribe.unsubscribe()
    }
    if(this.bitbucketSubscribe){
      this.bitbucketSubscribe.unsubscribe()
    }
    if(this.bitbucketRepoSubscribe){
      this.bitbucketRepoSubscribe.unsubscribe()
    }
    if(this.blastfurnaceSubscribe){
      this.blastfurnaceSubscribe.unsubscribe()
    }
    if(this.codewarsSubscribe){
      this.codewarsSubscribe.unsubscribe()
    }
  }

}
