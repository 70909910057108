import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecruiterComponent } from './recruiter.component';
import { SharedModule } from '../shared/shared.module';
import { RecruiterProfileComponent } from './recruiter-profile/recruiter-profile.component';
import { RecruiterInfoComponent } from './recruiter-info/recruiter-info.component';
import { RecruiterJobsComponent } from './recruiter-jobs/recruiter-jobs.component';
import { UiModule } from '../ui/ui.module';
import { RecruiterInfoUserComponent } from './recruiter-info/recruiter-info-user/recruiter-info-user.component';
import { RecruiterInfoWorkplaceComponent } from './recruiter-info/recruiter-info-workplace/recruiter-info-workplace.component';
import { RecruiterInfoAvatarComponent } from './recruiter-info/recruiter-info-avatar/recruiter-info-avatar.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule, MatAutocompleteModule, MatButtonModule, MatCheckboxModule } from '@angular/material';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RecruiterPositionComponent } from './recruiter-position/recruiter-position.component';
import { RecruiterReportComponent } from './recruiter-report/recruiter-report.component';
import { IntegrationsModule } from '../integrations/integrations.module';
import { RecruiterReportShareComponent } from './recruiter-report/recruiter-report-share/recruiter-report-share.component';
import { RecruiterRoutingModule } from './recruiter-routing.module';

@NgModule({
  declarations: [RecruiterComponent, RecruiterProfileComponent, RecruiterInfoComponent, RecruiterJobsComponent, RecruiterInfoUserComponent, RecruiterInfoWorkplaceComponent, RecruiterInfoAvatarComponent, RecruiterPositionComponent, RecruiterReportComponent, RecruiterReportShareComponent],
  imports: [
    CommonModule,
    AngularEditorModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    SharedModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    UiModule,
    FormsModule,
    IntegrationsModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro.forRoot(),
    AngularEditorModule,
    RecruiterRoutingModule
  ],
  exports: [RecruiterPositionComponent, RecruiterReportComponent],
  providers: []
})
export class RecruiterModule {}

