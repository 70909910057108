import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter-info-workplace',
  templateUrl: './recruiter-info-workplace.component.html',
  styleUrls: ['./recruiter-info-workplace.component.scss']
})
export class RecruiterInfoWorkplaceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
