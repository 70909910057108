import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { GooglePlacesService } from '../../core/google-places/google-places.service';
import {PlaceData} from "../../core/google-places/google-places";
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss']
})
export class LocationFormComponent implements OnInit, OnDestroy {

  city: string;
  predictions: PlaceData[]=[];
  userEntry: string;
  predictionSub: Subscription;
  selectionSub: Subscription;
  ID: string = "city";
  componentName: string;

  @Input() initCity: string; 
  @Input() uniqueID: string;
  @Input() icon: boolean;
  @Input() address: boolean;
  @Input() signUp: boolean; // signup page
  @Input() name: string;
  @Output() selectedPlace: EventEmitter<PlaceData> = new EventEmitter<PlaceData>();
  //@Output() selectedPlace = new EventEmitter<PlaceData>();

  constructor(private googlePlace: GooglePlacesService) { }

  onKey(searchCity: string) {
    if (searchCity){
      if(this.address) {
        this.googlePlace.getAutocompleteResults(searchCity, "address", this.uniqueID);
      }
      else {
        this.googlePlace.getAutocompleteResults(searchCity, "(cities)", this.uniqueID);
      }
    }
    else{
      this.googlePlace.clearPlaces();
    }
    this.getNewPredicitons();
  }

  placeSelected(newPlace: PlaceData){
    this.googlePlace.selectPlace(newPlace, this.uniqueID);
    this.city = newPlace.description;
    this.initCity = newPlace.description;
    this.googlePlace.clearPlaces();
    this.getNewPredicitons();
  }

  getNewPredicitons(){
    this.predictionSub = this.googlePlace.placeResults.subscribe(newPredictions => {
        this.predictions = newPredictions;
    });
  }

  ngOnInit() {
    this.componentName = this.name
    // console.log(this.name)
    if(this.uniqueID !=null){
      this.ID = this.uniqueID
    }
    this.city = this.initCity;
    // console.log("init city: " + this.initCity);
    this.selectionSub = this.googlePlace.placeResultSelected.subscribe(selection =>{
      if(selection){
        selection['name'] = this.componentName
        // console.log(selection)
        this.selectedPlace.emit(selection);
      }
    });
  }

  ngOnDestroy(){
    if(this.predictionSub){this.predictionSub.unsubscribe();};
    if(this.selectionSub){this.selectionSub.unsubscribe();};
    this.googlePlace.clearSelection();
  }

}
