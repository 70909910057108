import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

// Environment variables
import { environment } from '../../../environments/environment';
import {LinkedinAccessToken, LinkedInWorkandEducation} from './linkedin';

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {

  private linkedinClientId = environment.linkedinConfig.client_id;
  private linkedinApiUrl = `${environment.apiAddress}/linkedin`;
  private rootURL = 'https://www.linkedin.com/oauth/v2';
  private authUrl = `${this.rootURL}/authorization?client_id=${this.linkedinClientId}&response_type=code&redirect_uri=${environment.linkedinConfig.redirect_uri}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
   }

   async signIn() {
    return new Promise<LinkedinAccessToken>((resolve, reject) => {
      this.auth.launchAuthPopup(this.authUrl, 'LinkedIn Auth')
      .then(href => {
        console.log(href);
        const linkedinCode = new URL(href).searchParams.get('code');
        const linkedinName = new URL(href).searchParams.get('name');
        if (linkedinCode && linkedinName) {
          this.getAccessToken(linkedinCode)
          .then(accesstoken => {
            accesstoken.vanityName = linkedinName;
            resolve (accesstoken);
          })
          .catch(err => {
            reject(new Error('access token failed'));
          });
        } else {
          reject(new Error('code or token missing'));
        }

      })
      .catch(err => {
        reject(new Error((err)));
      });
    });
  }


  private getAccessToken(code: string) {
    return new Promise<LinkedinAccessToken>((resolve, reject) => {
      const params = `?code=${code}`;
      this.http.get<LinkedinAccessToken>(this.linkedinApiUrl + '/access_token' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  getUser(vanityName: string) {
    return new Promise<LinkedInWorkandEducation>((resolve, reject) => {
      const params = `?vanityName=${vanityName}`;
      this.http.get<LinkedInWorkandEducation>(this.linkedinApiUrl + '/auth' + params, {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }

  updateUser() {
    return new Promise<LinkedinAccessToken>((resolve, reject) => {
      this.http.get<LinkedinAccessToken>(this.linkedinApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
  deleteUser() {
    return new Promise<LinkedinAccessToken>((resolve, reject) => {
      this.http.get<LinkedinAccessToken>(this.linkedinApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      });
    });
  }
}



