import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PositionRoutingModule } from './position-routing.module';
import { RecruiterModule } from '../recruiter.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PositionRoutingModule,
    RecruiterModule
    ],
  providers: []
})
export class PositionModule {}