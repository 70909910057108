import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linkedin-redirect',
  templateUrl: './linkedin-redirect.component.html',
  styleUrls: ['./linkedin-redirect.component.scss']
})
export class LinkedinRedirectComponent implements OnInit {

  username: string;
  code: string;

  constructor() { }

  ngOnInit() {
    this.code = new URL(window.location.href).searchParams.get('code');
    const linkedinName = new URL(window.location.href).searchParams.get('name');
    if (linkedinName || !this.code) {
      window.close();
    }
  }

  public setUsername() {
    if (this.username) {
      window.location.href = `${window.location.origin}/linkedin-redirect?code=${this.code}&&name=${this.username}`;
    }
  }
}
