import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruiter-info-avatar',
  templateUrl: './recruiter-info-avatar.component.html',
  styleUrls: ['./recruiter-info-avatar.component.scss']
})
export class RecruiterInfoAvatarComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
