import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Component, Inject } from '@angular/core';
import { Position, RecruiterJob } from '../user';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { ToastService, CollapseComponent } from 'ng-uikit-pro-standard';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class JobsService {
    jobObs: Observable<Position> = of(null);
    companyObs: Observable<RecruiterJob> = of(null);
    job: BehaviorSubject<Position> = new BehaviorSubject(null);
    company: BehaviorSubject<RecruiterJob> = new BehaviorSubject(null);
    jobCollection: AngularFirestoreCollection<Position>;
    companyCollection: AngularFirestoreCollection<RecruiterJob>;
    items: Observable<Position[]>;

    private userSubscribe: Subscription;

    constructor(private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private storage: AngularFireStorage,
        private toast: ToastService,
        private auth: AuthService, ) { }


    getJobData(uid: String): BehaviorSubject<Position> {
        this.jobObs = this.afs.doc<Position>(`jobs/${uid}`).valueChanges();
        this.jobObs.subscribe(this.job);
        return this.job;
    }

    createJob(job: Position) {
        this.jobCollection = this.afs.collection('jobs');
        this.jobCollection.add(job).then((res => {
            var id = { uid: res.id }
            this.updateJob(res.id, id);
            this.userSubscribe = this.auth.currentUser.subscribe((user) => {
                if (user) {
                    let jobList = []
                    if (user.positions) {
                        jobList = user.positions;
                    }
                    if (this.checkPositionList(jobList, res.id)) {
                        jobList.push(res.id);
                        var data = { positions: jobList };
                        this.jobCollection.doc(user.uid).update(data);
                    }
                }
            });
        }))
            .catch(error => this.handleError(error));
    }

    checkPositionList(jobList: String[], id: string) {
        for (let i in jobList) {
            if (id == jobList[i]) {
                return false;
            }
        }
        return true;
    }

    updateJob(uid: String, data: any) {
        const doc = `jobs/${uid}`;
        //console.log(data)
        const jobRef: AngularFirestoreDocument<any> = this.afs.doc(doc);
        jobRef.update(data).catch(error => this.handleError(error));
    }

    deleteJob(uid: String) {
        const jobRef: AngularFirestoreDocument<any> = this.afs.doc(`jobs/${uid}`);
        jobRef.delete();
    }

    createRecruiterCompany(job: RecruiterJob) {
        this.companyCollection = this.afs.collection('companies');
        this.companyCollection.add(job).then((res => {
            var id = { uid: res.id }
            this.updateCompany(res.id, id);
            this.userSubscribe = this.auth.currentUser.subscribe((user) => {
                if (user) {
                    var data = { companyId: res.id };
                    this.jobCollection.doc(user.uid).update(data);
                    var creator = { creator: user.uid };
                    this.updateCompany(res.id, creator);
                }
            });
        }))
            .catch(error => this.handleError(error));
    }

    updateCompany(uid: String, data: any) {
        const doc = `companies/${uid}`;
        const companyRef: AngularFirestoreDocument<any> = this.afs.doc(doc);
        companyRef.update(data).catch(error => this.handleError);
    }

    deleteCompany(uid: String) {
        const companyRef: AngularFirestoreDocument<any> = this.afs.doc(`companies/${uid}`);
        companyRef.delete();
    }

    private handleError(error) {
        this.toast.error(error.message)
    }
}
