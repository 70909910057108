import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { JobsService } from '../../core/jobs/jobs.service';
import { Position, RecruiterJob } from '../../core/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CitiesSearchService } from '../../core/jobs/cities-search.service';
import * as firebase from 'firebase/app'
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit, OnDestroy {

  jobIds: String[];
  jobList: Position[];
  jobSubscribe: Subscription;
  jobsSubscribe: Subscription;
  jobLocationSubscribe: Subscription;
  jobsLocationSubscribe: Subscription;
  jobCompanySubscribe: Subscription;
  jobsCompanySubscribe: Subscription;

  shortDesc: string[];
  shortSkills: string[];
  selected: string = "mostRecent";
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  cityData$: Observable<any[]>;
  cityData: any
  cityAuto: any;
  selectedCity: string;
  cityLat: number;
  cityLng: number;
  cityAutoLang: any;
  cityLangLat: number;
  cityLangLng: number;
  myControl: FormControl = new FormControl()
  myControlCity: FormControl = new FormControl()
  city = "City..."
  searchText: string = ""
  radius: number = 20;
  filteredOptions: Observable<any>;
  companyName: string = ""
  companySub: Subscription;
  companies: string[];
  myControlCompany: FormControl = new FormControl()
  filteredCompanies: Observable<string[]>;
  component = 'userForm';
  displayFn: any;

  constructor(
    private cities: CitiesSearchService,
    public jobs: JobsService,
    private toast: ToastService,
    public afs: AngularFirestore) { }

  ngOnInit() {
    this.jobIds = new Array();
    this.jobList = new Array();
    this.companies = new Array();
    this.getExistingCompanies();
    this.filteredCompanies = this.myControlCompany.valueChanges
      .pipe(
        startWith(''),
        map(company => company ? this.filterCompanies(company) : this.companies.slice())
      );
    if (this.selected == 'mostRecent') {
      this.getRecentJobList()
    }
    this.cityData$ = this.cities.getCities(this.startAt)
  }

  filterCompanies(name: string) {
    return this.companies.filter(company =>
      company.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getExistingCompanies() {
    this.companySub = this.afs.collection<Position>('positions').valueChanges().subscribe(jobs => {
      if (jobs) {
        jobs.forEach(job => {
          if (!this.duplicateCompanyName(job.company) && job.company) {
            this.companies.push(job.company)
          }
        }
        )
        this.companies.sort(function (a, b) {
          if (a > b) {
            return 1;
          }
          else if (b > a) {
            return -1;
          }
          else {
            return 0;
          }
        });
      }
    });
  }

  getRecentJobList() {
    let currentDate = new Date().getTime();
    this.jobsSubscribe = this.afs.collection<Position>('positions').valueChanges().subscribe(jobs => {
      if (jobs) {

        jobs.forEach(job => {
          if (job.visibility) {
            this.jobIds.push(job.uid)
            if (!this.duplicateCompanyName(job.company)) {
              if (Date.parse(job.dateClosing + (60 * 60 * 24 * 1000)) >= currentDate) {
                this.companies.push(job.company);
              }
            }
          }
        })
        for (let i in this.jobIds) {
          this.jobSubscribe = this.afs.doc<Position>(`positions/${this.jobIds[i]}`).valueChanges().subscribe(job => {//this.jobs.getJobData(this.jobIds[i]).subscribe(job => {
            if (job) {
              if (!this.checkDuplicate(job)) {
                //console.log(Date.parse(job.dateClosing))
                if ((Date.parse(job.dateClosing) + (60 * 60 * 24 * 1000)) >= currentDate) {
                  this.jobList.push(job);
                  this.jobList.sort(function (a, b) {
                    if (a.dateCreated > b.dateCreated) {
                      return -1;
                    }
                    else if (b.dateCreated > a.dateCreated) {
                      return 1;
                    }
                    else {
                      return 0;
                    }
                  });
                }
              }
            }
          })
        }
      }
    });
  }

  duplicateCompanyName(name: string) {
    for (let i in this.companies) {
      var check = false;
      if (name == this.companies[i]) {
        check = true;
        break;
      }
      else {
        check = false;
      }
    }
    return check;
  }

  //Retrieving jobs causes duplicates for some reason, this removes them from list
  checkDuplicate(job: Position) {
    for (let i in this.jobList) {
      var check = false;
      if (job.uid == this.jobList[i].uid) {
        check = true;
        break;
      }
      else {
        check = false;
      }
    }
    return check;
  }

  setTab(tabName: string) {
    this.selected = tabName;
    if (tabName == 'mostRecent') {
      this.getRecentJobList()
    }
    else {
      this.jobIds = new Array();
      this.jobList = new Array();
    }
  }

  companySelected(company: string) {
    this.companyName = company;
    this.jobIds = new Array();
    this.jobList = new Array();
  }

  Search() {
    this.jobIds = new Array();
    this.jobList = new Array();
    let currentDate = new Date().getTime();
    switch (this.selected) {
      case "location":
        if (this.cityLng != null) {//this.finalLocation != null){
          const KM_PER_DEGREE_LATITUDE = 110.574;
          const latDegrees = this.radius / KM_PER_DEGREE_LATITUDE;
          const latitudeNorth = Math.min(90, this.cityLat + latDegrees);
          const latitudeSouth = Math.max(-90, this.cityLat - latDegrees);
          // calculate longitude based on current latitude
          const longDegsNorth = this.metersToLongitudeDegrees(this.radius, latitudeNorth);
          const longDegsSouth = this.metersToLongitudeDegrees(this.radius, latitudeSouth);
          const longDegs = Math.max(longDegsNorth, longDegsSouth);
          const swGeopoint = new firebase.firestore.GeoPoint(latitudeSouth, this.wrapLongitude(this.cityLng - longDegs));
          const neGeopoint = new firebase.firestore.GeoPoint(latitudeNorth, this.wrapLongitude(this.cityLng + longDegs));
          this.jobsLocationSubscribe = this.afs.collection<Position>('positions', ref => ref.where('location', '>=', swGeopoint).where('location', '<=', neGeopoint)).valueChanges().subscribe(jobs => {
            if (jobs) {
              jobs.forEach(job => {
                if ((Date.parse(job.dateClosing) + (60 * 60 * 24 * 1000)) >= currentDate) {
                  if (job.visibility) {
                    this.jobIds.push(job.uid)
                    this.jobList.push(job)
                  }
                }
              })
              this.jobList.sort(function (a, b) {
                if (a.dateCreated > b.dateCreated) {
                  return -1;
                }
                else if (b.dateCreated > a.dateCreated) {
                  return 1;
                }
                else {
                  return 0;
                }
              });

              /*for (let i in this.jobIds) {
                this.jobLocationSubscribe = this.jobs.getJobData(this.jobIds[i]).subscribe(job => {
                  if (job) {
                    if (!this.checkDuplicate(job)) {
                      this.jobList.push(job);
                      this.jobList.sort(function(a, b) {
                        if(a.dateCreated > b.dateCreated) {
                          return -1;
                        }
                        else if(b.dateCreated > a.dateCreated) {
                          return 1;
                        }
                        else {
                          return 0;
                        }
                      });
                    }
                  }
                })
              }*/
            }
          });
        }
        else {
          this.toast.error("Please Enter A Location")
        }
        break;
      case "company":
        if (this.companyName != null) {
          let Name = this.companyName.toLowerCase()
          this.jobsCompanySubscribe = this.afs.collection<Position>('positions', ref => ref.where('companyLowercase', '==', Name)).valueChanges().subscribe(jobs => {
            if (jobs) {
              jobs.forEach(job => {
                if ((Date.parse(job.dateClosing) + (60 * 60 * 24 * 1000)) >= currentDate) {
                  if (job.visibility) {
                    this.jobIds.push(job.uid)
                    this.jobList.push(job)
                  }
                }
              })
              this.jobList.sort(function (a, b) {
                if (a.dateCreated > b.dateCreated) {
                  return -1;
                }
                else if (b.dateCreated > a.dateCreated) {
                  return 1;
                }
                else {
                  return 0;
                }
              });
              /*for (let i in this.jobIds) {
                this.jobCompanySubscribe = this.jobs.getJobData(this.jobIds[i]).subscribe(job => {
                  if (job) {
                    if (!this.checkDuplicate(job)) {
                      this.jobList.push(job);
                      this.jobList.sort(function(a, b) {
                        if(a.dateCreated > b.dateCreated) {
                          return -1;
                        }
                        else if(b.dateCreated > a.dateCreated) {
                          return 1;
                        }
                        else {
                          return 0;
                        }
                      });
                    }
                  }
                })
              }*/
            }
          });
        }
        else {
          this.toast.error("Please Enter A Company Name")
        }

        break;
      default:
      //console.log("No search criteria!");
    }
  }

  citySelected(city) {
    this.cityAuto = city
    this.cityLat = city.lat
    this.cityLng = city.lng
    this.myControlCity = new FormControl(city.city, Validators.required)
    if (city.admin_name != "") {
      this.city = city.city + ", " + city.admin_name + ", " + city.country
    }
    else {
      this.city = city.city + ", " + city.country
    }
    document.getElementById("city").blur();
  }
  search(searchText) {
    this.searchText = searchText
    this.startAt.next(searchText.charAt(0).toUpperCase() + searchText.slice(1));

  }
  clearCity() {
    this.searchText = ""
    this.city = "City..."
  }
  locationChanged(city) {
    this.cityAuto = city
    this.cityLat = city.lat
    this.cityLng = city.lng
  }
  metersToLongitudeDegrees(distance, latitude) {
    const EARTH_EQ_RADIUS = 6378137.0;
    const E2 = 0.00669447819799;
    const EPSILON = 1e-12;
    const radians = (latitude * Math.PI) / 180;
    const num = Math.cos(radians) * EARTH_EQ_RADIUS * Math.PI / 180;
    const denom = 1 / Math.sqrt(1 - E2 * Math.sin(radians) * Math.sin(radians));
    const deltaDeg = num * denom;
    if (deltaDeg < EPSILON) {
      return distance > 0 ? 360 : 0;
    }
    // else
    return Math.min(360, distance / deltaDeg);
  }
  wrapLongitude(longitude) {
    if (longitude <= 180 && longitude >= -180) {
      return longitude;
    }
    const adjusted = longitude + 180;
    if (adjusted > 0) {
      return (adjusted % 360) - 180;
    }
    // else
    return 180 - (-adjusted % 360);
  }
  changeRadius(radius) {
    this.radius = radius
  }
  clearCompany() {
    this.companyName = null
  }

  ngOnDestroy() {
    if (this.jobsSubscribe) {
      this.jobsSubscribe.unsubscribe()
    }
    if (this.jobSubscribe) {
      this.jobSubscribe.unsubscribe()
    }
    if (this.jobsLocationSubscribe) {
      this.jobsLocationSubscribe.unsubscribe()
    }
    if (this.jobsCompanySubscribe) {
      this.jobsCompanySubscribe.unsubscribe()
    }
    if (this.jobCompanySubscribe) {
      this.jobCompanySubscribe.unsubscribe()
    }
    if (this.companySub) {
      this.companySub.unsubscribe();
    }
  }
}
