import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from '../../../environments/environment';
import { CodeWarsAuth} from './codewars'
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CodewarsService {

  private codewarsApiUrl = `${environment.apiAddress}/codewars`;
  private httpHeaders: HttpHeaders;

  constructor(private auth: AuthService, private http: HttpClient) {
    this.auth.httpHeadersSubject.subscribe(newHeaders => this.httpHeaders = newHeaders);
  }

  async signIn() {
    return new Promise<CodeWarsAuth>((resolve, reject) => {
      this.auth.launchAuthPopup(`${window.location.origin}/codewars-auth`, 'CodeWars Auth')
      .then(async href => {
        const CodeWarsToken = new URL(href).searchParams.get('token');
        const CodeWarsUser = new URL(href).searchParams.get('user');
        if (CodeWarsToken && CodeWarsUser) {
          const newCodeWarsAuth: CodeWarsAuth = {
            access_token: CodeWarsToken,
            username: CodeWarsUser
          };
          const params = `?username=${CodeWarsUser}&accessToken=${CodeWarsToken}`;
          await this.http.get<any>(this.codewarsApiUrl + '/auth' + params, {headers: this.httpHeaders}).subscribe(res => {
            if (res) {
              resolve(res);
            } else {
              reject(new Error('auth failed'));
            }
          });
        } else {
          reject(new Error('auth failed'));
        }
      })
      .catch(err => {
        reject(new Error((err)))
      });
    });
  }

  async update() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.codewarsApiUrl + '/update', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }

  async delete() {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.codewarsApiUrl + '/delete', {headers: this.httpHeaders}).subscribe(res => {
        if (res) {
          resolve(res);
        } else {
          reject(new Error('auth failed'));
        }
      },
      error => {
        reject(error);
      });
    });
  }
}
