import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coder-profile-integrations',
  templateUrl: './coder-profile-integrations.component.html',
  styleUrls: ['./coder-profile-integrations.component.scss']
})
export class CoderProfileIntegrationsComponent implements OnInit, OnChanges, OnDestroy {

  hasProjects: boolean = true;
  hasCommunityStats: boolean = true;
  hasCompetitions: boolean = true;
  hasSocial: boolean = true;
  hasBitbucket: boolean;
  hasGithub: boolean;
  hasGitlab: boolean;
  hasStack: boolean;
  hasMedium: boolean;
  hasMeetup: boolean;
  hasCodewars: boolean;
  hasTopcoder: boolean;
  hasCodingame: boolean;
  hasYoutube: boolean;
  hasTwitter: boolean;
  hasTwitch: boolean;
  uid: string;
  userId: string;
  editPermission: boolean = false;

  @Input() loggedInUID : string;

  userSubscribe: Subscription;


  constructor(private afs: AngularFirestore, private route: ActivatedRoute, private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get("uid");
    if(this.uid){
      this.userSubscribe = this.auth.currentUser.subscribe(user => {
        if (user) {
          this.userId = user.uid;
          if(this.uid === this.userId) {
            this.editPermission = true;
          }
        }
      });
      this.checkCodeConnections();
      this.checkCommunityConnections();
      this.checkCompete();
      this.checkSocial();
    }
  }

  ngOnChanges(){

  }

  checkCodeConnections() {
    this.afs.collection('bitbucket').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasBitbucket = true;
      } else {
        this.hasBitbucket = false;
      }
      this.afs.collection('github').doc(this.uid).ref.get().then(doc => {
        if (doc.exists) {
          this.hasGithub = true;
        } else {
          this.hasGithub = false;
        }
        this.afs.collection('gitlab').doc(this.uid).ref.get().then(doc => {
          if (doc.exists) {
            this.hasGitlab = true;
          } else {
            this.hasGitlab = false;
          }
          if ((this.hasBitbucket || this.hasGithub || this.hasGitlab)) {
            this.hasProjects = true;
          } else {
            this.hasProjects = false;
          }
        });
      });
    });
  }

  checkCommunityConnections() {
    this.afs.collection('stackoverflow').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasStack = true;
      } else {
        this.hasStack = false;
      }
    });


    this.afs.collection('medium').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasMedium = true;
      } else {
        this.hasMedium = false;
      }
    });


    this.afs.collection('meetup').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasMeetup = true;
      } else {
        this.hasMeetup = false;
      }
      if (this.hasStack || this.hasMedium || this.hasMeetup) {
        this.hasCommunityStats = true;
      } else {
        this.hasCommunityStats = false;
      }
    });

  }

  checkCompete() {
      this.afs.collection('codewars').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasCodewars = true;
      } else {
        this.hasCodewars = false;
      }
      this.afs.collection('topcoder').doc(this.uid).ref.get().then(doc => {
        if (doc.exists) {
          this.hasTopcoder = true;
        } else {
          this.hasTopcoder = false;
        }
        this.afs.collection('codingame').doc(this.uid).ref.get().then(doc => {
          if (doc.exists) {
            this.hasCodingame = true;
          } else {
            this.hasCodingame = false;
          }
          if (this.hasTopcoder || this.hasCodewars || this.hasCodingame) {
            this.hasCompetitions = true;
          } else {
            this.hasCompetitions = false;
          }
        });
      });
    });

  }

  checkSocial() {
    // this.afs.collection('youtube').doc(this.uid).ref.get().then(doc => {
    //   if (doc.exists) {
    //     this.hasYoutube = true;
    //   } else {
    //     this.hasYoutube = false;
    //   }
    // });

    this.afs.collection('twitter').doc(this.uid).ref.get().then(doc => {
      if (doc.exists) {
        this.hasTwitter = true;
      } else {
        this.hasTwitter = false;
      }
      if (this.hasTwitch || this.hasTwitter || this.hasYoutube) {
        this.hasSocial = true;
      } else {
        this.hasSocial = false;
      }
    });

    // this.afs.collection('social').doc(this.uid).ref.get().then(doc => {
    //   if (doc.exists) {
    //     this.hasTwitch = true;
    //   } else {
    //     this.hasTwitch = false;
    //   }
    // });
  }

  connectApps() {
    this.router.navigate(['/connect'], {queryParams: {profile: true}});
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

}
