import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-recruiter-company',
  templateUrl: './signup-recruiter-company.component.html',
  styleUrls: ['./signup-recruiter-company.component.scss']
})
export class SignupRecruiterCompanyComponent implements OnInit, OnDestroy {

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  downloadURLString: String;
  isHovering: boolean;
  validationForm: FormGroup;
  currentUser: any;
  fsCollection: AngularFirestoreCollection<any>;
  userSubscribe: Subscription;
  downloadSubscribe: Subscription;

  constructor(
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    public auth: AuthService,
    private afs: AngularFirestore,
    private router: Router)
    {
      this.validationForm = fb.group({
      nameFormEx: [null, [Validators.required]],
      websiteFormEx: [null, Validators.required],
      });
  }

  ngOnInit() {
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.currentUser = user;
    })
  }


  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);
    if(file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type');
    }
    const path = `CompanyLogos/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    const ref = this.storage.ref(path);
    this.downloadSubscribe = this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        this.downloadURL.subscribe(value => this.downloadURLString = value);
      })
    ).subscribe();
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransfered < snapshot.totalBytes;
  }


  onSubmit({value}: {value: any}) {
    this.afs.collection('users').doc(this.currentUser.uid).update({
      companyName: value.nameFormEx,
      companyWebsite: value.websiteFormEx,
      companyLogo: this.downloadURLString
    }).then(() => {this.router.navigate(['/signup/recruiter-profile'])});
  }

  skip() {
    this.router.navigate(['/signup/recruiter-profile']);
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe()
    }
    if (this.downloadSubscribe) {
      this.downloadSubscribe.unsubscribe();
    }
  }
}
