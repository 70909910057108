import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { BitbucketLanguage, BitbucketUser, BitbucketLinks, BitbucketRepo, BitbucketTeam, BitbucketTeamLinks, BitbucketRepoLinks, BitbucketRepoMainbranch, BitbucketRepoOwner, BitbucketRepoProject, BitbucketRepoOwnerLinks, BitbucketRepoProjectLinks } from 'src/app/core/user';
import { Subscription } from 'rxjs';
import { User } from 'firebase';
import { BitbucketService } from '../bitbucket.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-bitbucket-profile',
  templateUrl: './bitbucket-profile.component.html',
  styleUrls: ['./bitbucket-profile.component.scss']
})
export class BitbucketProfileComponent implements  OnInit, OnChanges, OnDestroy{ // OnInit


  user: User;
  userSubscribe: Subscription;
  bitbucketUserSubscribe: Subscription;
  bitbucketReposSubscribe: Subscription;
  bitbucketTeamsSubscribe: Subscription;
  bitbucketProgressSubscribe: Subscription;
  bitbucketUser: BitbucketUser = null;
  bitbucketRepos: BitbucketRepo[] = [];
  bitbucketTeams: BitbucketTeam[] = [];
  uid:any;
  bitbucketToggle: boolean = false;
  edit = false;
  modal = false;
  name:string;
  languageTotal: number = 0;
  mdbChartComplete: boolean = true;
  progressInfo: string
  userLink: string
  toEdit: boolean;
  loading: boolean = false;

  //new chart
  mdbChartLabel: string[];
  mdbChartData: number[];

  @Input() enableEditParam: boolean = true;
  @Input() uidParam: string;
  @Input() disable: boolean = true;

  

  constructor(private bitbucket: BitbucketService, private afs: AngularFirestore, private toast: ToastService,) { }

  linkBitbucketAccount(){
    this.bitbucket.accesstoken;
  }
  private updateBitbucketAccount(){
    //console.log("updating")
    this.bitbucket.signIn();
  }
  async Update(){
    this.loading = true;
    if(this.bitbucketUser != null){
      //this.bitbucket.authenticate()
      await this.bitbucket.update().then(res => {
        this.toast.success("Successfully Updated BitBucket");
        console.log('Message: ' + (res));
        this.loading = false;
      })
      .catch(err => {
        this.toast.error(JSON.stringify(err));
        console.log(err);
        this.loading = false;
      });
    }
  }

  ngOnInit () {
  }

  ngOnChanges(){
    if (this.uidParam){
      this.uid = this.uidParam;
      this.bitbucketToggle = false;
      //this.bitbucketUserSubscribe =  this.bitbucket.getUser(this.uid).subscribe(bitbucket => {
      const path = `bitbucket/${this.uidParam}`;
      this.bitbucketUserSubscribe = this.afs.doc<BitbucketUser>(path).valueChanges().subscribe(bitbucket =>{
        if (bitbucket){
          this.bitbucketUser = bitbucket;
          this.userLink = bitbucket.links['html'].href
          // alert(this.uidParam);
          if(this.bitbucketUser != null){
            if(this.bitbucketUser.languages){
              for(let i=0; i<this.bitbucketUser.languages.length; i++){
              this.bitbucketUser.languages[i].language = this.bitbucketUser.languages[i].language.charAt(0).toUpperCase()+ this.bitbucketUser.languages[i].language.slice(1)
            }
            this.updatePieChart(this.bitbucketUser.languages)
            }

          }
        }
        if(bitbucket == null){
          this.bitbucketUser = null;
        }
      });
      const pathProgress = `bitbucket/${this.uidParam}/progress/data`;
      this.bitbucketProgressSubscribe = this.afs.doc(pathProgress).valueChanges().subscribe(progress => {
        if (progress) {
          //console.log(progress)
          this.progressInfo = progress['info']
        }
      })
      const repoLocation = `bitbucket/${this.uidParam}/repos`;
      this.bitbucketReposSubscribe = this.afs.collection<BitbucketRepo>(repoLocation).valueChanges().subscribe(repos =>{
        if(repos){
          this.bitbucketRepos = repos;

        }
      });

      const teamLocation = `bitbucket/${this.uidParam}/teams`;
      this.bitbucketTeamsSubscribe = this.afs.collection<BitbucketTeam>(teamLocation).valueChanges().subscribe(teams =>{
        if(teams){
          this.bitbucketTeams = teams

        }
      })
      if(this.enableEditParam==true){
        this.bitbucketToggle=true;
      }

    }
  }

  bitbucketToggleSetting(){
    this.bitbucketToggle = !this.bitbucketToggle
    //console.log(this.bitbucketToggle)
  }
  async Delete(){
    this.loading = true;
    await this.bitbucket.delete().then(res => {
      this.toast.success("Successfully Deleted Bitbucket");
      console.log('Message: ' + (res));
      this.loading = false;
    })
    .catch(err => {
      this.toast.error(JSON.stringify(err));
      console.log(err);
      this.loading = false;
    });
    this.bitbucketUser = null;
  }
  enableEdit() {
    this.edit = true;
   }

  disableEdit() {
    this.edit = false;
    // this.bitbucket.changeShow(this.uid, this.bitbucketUser.languages);

    let repoCount = 0;
    let repoTotal = 0;
    for (const repo of this.bitbucketRepos) {
      if (repo.show) {
        repoCount++;
      }
      repoTotal++;
    }
    this.afs.doc<BitbucketUser>(`bitbucket/${this.uidParam}`).update(
      {
        repoCount: repoCount,
        repoTotal: repoTotal,
        languages: this.bitbucketUser.languages
      }
    );
  }

  changeCheckbox(i) {
    if (this.bitbucketRepos) {
      this.bitbucketRepos[i].show = !this.bitbucketRepos[i].show;
      this.afs.collection('bitbucket').doc(this.uid).collection('repos').doc(this.bitbucketRepos[i].slug).update({
        show: this.bitbucketRepos[i].show
      });
    }
  }
  changeCheckboxTeam(i) {
    if (this.bitbucketTeams) {
      this.bitbucketTeams[i].show = !this.bitbucketTeams[i].show;
      this.afs.collection('bitbucket').doc(this.uid).collection('teams').doc(this.bitbucketTeams[i].username).update({
        show: this.bitbucketTeams[i].show
      });
      // this.bitbucket.changeVisibility(this.uid, this.bitbucketTeams[i].username, this.bitbucketTeams[i].show)
    }
  }
  
  changeLanguageCheckbox(i) {
    if (this.bitbucketUser) {
      this.bitbucketUser.languages[i].show = !this.bitbucketUser.languages[i].show;
    }
  }

  updateModal(){
    this.modal = !this.modal
    console.log(this.modal)
  }

  // Doughnut
  // events
  public chartClicked(e:any):void {
    //console.log(e);
  }

  public chartHovered(e:any):void {
    //console.log(e);
  }

  private updatePieChart(languages: BitbucketLanguage[]){
    //Piechart1 Data & Config
    if (languages){
      this.mdbChartData = [];
      this.mdbChartLabel=[];
      for (let i=0; i < languages.length; i++){
        if(languages[i].show){
          this.mdbChartData.push(languages[i].score);
          this.mdbChartLabel.push(languages[i].language);
        }
      }
      this.mdbChartComplete = false;
      setTimeout(()=>{
        this.mdbChartComplete = true;
      })
    }
  }
  ngOnDestroy(){
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
    if (this.bitbucketUserSubscribe) {
      this.bitbucketUserSubscribe.unsubscribe();
    }
    if (this.bitbucketReposSubscribe) {
      this.bitbucketReposSubscribe.unsubscribe();
    }
    if (this.bitbucketTeamsSubscribe) {
      this.bitbucketTeamsSubscribe.unsubscribe();
    }
    if (this.bitbucketProgressSubscribe) {
      this.bitbucketProgressSubscribe.unsubscribe();
    }
  }

}


