import { Component, OnInit, Input, SystemJsNgModuleLoader, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { merge, Subscription } from 'rxjs';
import { LinkedinService } from 'src/app/integrations/linkedin/linkedin.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { LinkedinAccessToken } from 'src/app/integrations/linkedin/linkedin';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-coder-profile-work-history',
  templateUrl: './coder-profile-work-history.component.html',
  styleUrls: ['./coder-profile-work-history.component.scss']
})
export class CoderProfileWorkHistoryComponent implements OnInit, OnDestroy {

  @Input() isBeingEdited: boolean = false;
  @Input() saveData: boolean;

  currentUser: any;
  userData: any;
  userUid: string;
  profileImage: string;
  firstName: string;
  lastName: string;
  blurb: string;
  location: string;
  email: string;
  phone: number;
  website: string;
  video: string;
  skills: string;
  coderInfo: any;
  workHistory: any[] = [];
  educationHistory: any[] = [];
  removedWork: any[] = [];
  removedEducation: any[] = [];
  workForm: FormGroup;
  educationForm: FormGroup;
  linkedIn: any;
  todaysDate: Date;
  maxDate: Date;
  minDate: Date;
  completionDate: Date;
  jobStartDate: Date;
  jobEndDate: Date;
  userSubscribe: Subscription;
  linkedInSubscribe: Subscription;
  startDates: FormControl[] = [];
  endDates: FormControl[] = [];
  completionDates: FormControl[] = [];

  constructor(private dateAdapter: DateAdapter<Date>,private afs: AngularFirestore, private auth: AuthService, private route: ActivatedRoute, private fb: FormBuilder, private toast: ToastService, private linkedin: LinkedinService,)  { 
    this.dateAdapter.setLocale('en-GB');           //set date format to great britain
  }

  ngOnInit() {
    this.startDates[0] = new FormControl();
    this.startDates[1] = new FormControl();
    this.endDates[0] = new FormControl();
    this.endDates[1] = new FormControl();
    this.completionDates[0] = new FormControl();
    this.completionDates[1] = new FormControl();

    this.todaysDate = new Date();
    this.maxDate = new Date(this.todaysDate.getFullYear(), this.todaysDate.getMonth() + 1, this.todaysDate.getDate());
    this.workForm = this.fb.group({
      workHistoryForm: this.fb.array([this.createWorkGroup()])
    });
    this.educationForm = this.fb.group({
      educationHistoryForm: this.fb.array([this.createEducationGroup()])
    });
    this.currentUser = this.route.snapshot.paramMap.get('uid');
    if (this.currentUser) {
      this.userSubscribe = this.auth.currentUser.subscribe(async currentUser => {
        const user = currentUser ? currentUser : await this.auth.getUserInfo(this.currentUser);
        this.workHistory = user.workHistory;
        this.educationHistory = user.educationHistory;
        this.initWorkArray();
        this.initEducationArray();
        this.getDates();
      });
      this.linkedInSubscribe = this.afs.collection('linkedin').doc(this.currentUser).valueChanges().subscribe(doc => {
        if (doc) {
          this.linkedIn = doc;
        }
      });
    }
  }

  getDates() {
    // For each work history date object, convert timestamp into formcontrol to populate datepicker
    if (this.workHistory) {
      for (let i = 0; i < this.workHistory.length; i++) {
        // save the start date in a readable format for the datepicker
        if ((typeof this.workHistory[i].startDate) === 'string') {
          let month = this.workHistory[i].startDate.split(' ')[0];
          const year = this.workHistory[i].startDate.split(' ')[1];
          if (month === 'Jan') { month = 1; }
          if (month === 'Feb') { month = 2; }
          if (month === 'Mar') { month = 3; }
          if (month === 'Apr') { month = 4; }
          if (month === 'May') { month = 5; }
          if (month === 'Jun') { month = 6; }
          if (month === 'Jul') { month = 7; }
          if (month === 'Aug') { month = 8; }
          if (month === 'Sep') { month = 9; }
          if (month === 'Oct') { month = 10; }
          if (month === 'Nov') { month = 11; }
          if (month === 'Dec') { month = 12; }
          const date = month + '/1/' + year;
          delete this.workHistory[i].startDate;
          this.workHistory[i].startDate = { seconds: (new Date(date).getTime()) / 1000};
          this.startDates[i] = new FormControl((new Date(new Date(date).getTime())));
        } else {
          this.startDates[i] = new FormControl(new Date(this.workHistory[i].startDate.seconds * 1000));
          // save the end date in a readable format for the datepicker
        }
        if ((typeof this.workHistory[i].endDate) === 'string') {
          if (this.workHistory[i].endDate !== 'Present') {
            let month = this.workHistory[i].endDate.split(' ')[0];
            const year = this.workHistory[i].endDate.split(' ')[1];
            if (month === 'Jan') { month = 1; }
            if (month === 'Feb') { month = 2; }
            if (month === 'Mar') { month = 3; }
            if (month === 'Apr') { month = 4; }
            if (month === 'May') { month = 5; }
            if (month === 'Jun') { month = 6; }
            if (month === 'Jul') { month = 7; }
            if (month === 'Aug') { month = 8; }
            if (month === 'Sep') { month = 9; }
            if (month === 'Oct') { month = 10; }
            if (month === 'Nov') { month = 11; }
            if (month === 'Dec') { month = 12; }
            const date = month + '/1/' + year;
            delete this.workHistory[i].endDate;
            this.workHistory[i].endDate = { seconds: (new Date(date).getTime()) / 1000};
            this.endDates[i] = new FormControl(new Date(new Date(date).getTime()));
          } else {
            this.endDates[i] = new FormControl();
          }
          
        } else {
          // save the end date in a readable format for the datepicker
          this.endDates[i] = new FormControl(new Date(this.workHistory[i].endDate.seconds * 1000));
        }
      }
      
      // For each education History date object, convert timestamp into formcontrol to populate datepicker
      for (let i = 0; i < this.educationHistory.length; i++) {
        // save the completion date in a readable format for the datepicker
        if ((typeof this.educationHistory[i].completionDate) === 'string') {
          const year = this.educationHistory[i].completionDate;
          const date = '1/1/' + year;
          delete this.educationHistory[i].completionDate;
          this.educationHistory[i].completionDate = { seconds: (new Date(date).getTime()) / 1000};
          this.completionDates[i] = new FormControl(new Date(new Date(date).getTime()));
        }
        this.completionDates[i] = new FormControl(new Date(this.educationHistory[i].completionDate.seconds * 1000));
      }
    }
  }

  updateJobStartDate(event: MatDatepickerInputEvent<Date>, index: number) {
    this.jobStartDate = new Date(event.value);
    // console.log(this.jobStartDate + " " + typeof this.jobStartDate);
    this.workHistoryForm.value[index].startDate = this.jobStartDate;
    this.saveProfile();
  }

  updateJobEndDate(event: MatDatepickerInputEvent<Date>, index: number) {
    this.jobEndDate = new Date(event.value);
    // console.log(this.jobEndDate + " " + typeof this.jobEndDate);
    this.workHistoryForm.value[index].endDate = this.jobEndDate;
    this.saveProfile();
  }

  updateCompletionDate(event: MatDatepickerInputEvent<Date>, index: number) {
    this.completionDate = new Date(event.value);
    // console.log(this.completionDate + " " + typeof this.completionDate);
    this.educationHistoryForm.value[index].completionDate = this.completionDate;
  }

  async updateLinkedin() {
    if (this.linkedIn) {
      await this.linkedin.updateUser()
      .then(res => {
        this.toast.success('Successfully Updated Linkedin');
      })
      .catch(err => {
        this.toast.error('LinkedIn Update Failed');
        console.log(err);
      });
    } else {
      let accesstoken: LinkedinAccessToken;
      await this.linkedin.signIn()
      .then(res => {
        accesstoken = res;
        this.toast.success('Successfully Authenticated Linkedin');
      })
      .catch(err => {
        this.toast.error('LinkedIn Auth Failed');
        console.log(err);
      });
      await this.linkedin.getUser(accesstoken.vanityName)
      .then(res => {
        this.toast.success('LinkedIn Success');
      })
      .catch(err => {
        this.toast.error('LinkedIn Update Failed');
        console.log(err);
      });
    }
  }
  createWorkGroup() {
    return this.fb.group({
      title: [''],
      employer: [''],
      startDate: [''],
      endDate: [''],
      description: ['']
    });
  }

  createWorkGroupInit(workObject: any) {
    return this.fb.group({
      title: [workObject.title],
      employer: [workObject.employer],
      startDate: [workObject.startDate],
      endDate: [workObject.endDate],
      description: [workObject.description]
    });
  }

  createEducationGroup() {
    return this.fb.group({
      schoolName: [''],
      degreeOrCert: [''],
      completionDate: [''],
      description: ['']
    });
  }

  createEducationGroupInit(eduObject: any) {
    return this.fb.group({
      schoolName: [eduObject.schoolName],
      degreeOrCert: [eduObject.degreeOrCert],
      completionDate: [eduObject.completionDate],
      description: [eduObject.description]
    });
  }

  get educationHistoryForm() {
    if (this.educationForm !== undefined) {
      return this.educationForm.get('educationHistoryForm') as FormArray;
    }
  }

  addEducationHistory() {
    this.educationHistoryForm.push(this.createEducationGroup());
  }

  removeLastEducationHistory() {
    let last = this.educationHistoryForm.length;
    this.removedEducation.push(this.educationHistoryForm.value[last - 1]);
    this.educationHistoryForm.removeAt(last - 1);
  }

  get workHistoryForm() {
    if (this.workForm !== undefined) {
      return this.workForm.get('workHistoryForm') as FormArray;
    }
  }

  addWorkHistory() {
    this.workHistoryForm.push(this.createWorkGroup());
  }

  removeLastWorkHistory() {
    let last = this.workHistoryForm.length;
    // this will reset the stored dates
    this.startDates[last - 1] = new FormControl();
    this.endDates[last - 1] = new FormControl();
    this.removedWork.push(this.workHistoryForm.value[last - 1]);
    this.workHistoryForm.removeAt(last - 1);
  }

  saveProfile() {
    this.checkNullHistory();
    this.afs.collection('users').doc(this.currentUser).update({
      workHistory: this.workHistoryForm.value,
      educationHistory: this.educationHistoryForm.value
    });
    this.workHistory = [];
    this.educationHistory = [];
    for (let i = 0; i < this.workHistoryForm.value.length; i++) {
      this.workHistory[i] = this.workHistoryForm.value[i];
    }
    for (let i = 0; i < this.educationHistoryForm.value.length; i++) {
      this.educationHistory[i] = this.educationHistoryForm.value[i];
    }
    this.checkArrayLengths();
  }

  checkNullHistory() {
    for (let i = 0; i < this.workHistoryForm.length; i++) {
      if (this.workHistoryForm.value[i].title === '' && this.workHistoryForm.value[i].employer === '' &&
          this.workHistoryForm.value[i].startDate == '' && this.workHistoryForm.value[i].endDate == '' && this.workHistoryForm.value[i].description == '')  {
        this.workHistoryForm.removeAt(i);
      }
    }
    if(this.workHistoryForm.value.length > 0) {
      if (this.workHistoryForm.value[0].title === '' && this.workHistoryForm.value[0].employer === '' &&
        this.workHistoryForm.value[0].startDate == '' && this.workHistoryForm.value[0].endDate == '' && this.workHistoryForm.value[0].description == '')  {
        this.workHistoryForm.removeAt(0);
      }
    }
    for (let i = 0; i < this.educationHistoryForm.length; i++) {
      if (this.educationHistoryForm.value[i].completionDate === '' && this.educationHistoryForm.value[i].degreeOrCert === '' &&
          this.educationHistoryForm.value[i].description === '' && this.educationHistoryForm.value[i].schoolName === '')  {
        this.educationHistoryForm.removeAt(i);
      }
    }
    if (this.educationHistoryForm.value.length > 0) {
      if (this.educationHistoryForm.value[0].completionDate === '' && this.educationHistoryForm.value[0].degreeOrCert === '' &&
        this.educationHistoryForm.value[0].description === '' && this.educationHistoryForm.value[0].schoolName === '')  {
        this.educationHistoryForm.removeAt(0);
      }
    }
  }

  checkArrayLengths() {
    if (this.workHistory) {
      if (this.workHistoryForm.length === 0) {
        this.workHistoryForm.push(this.createWorkGroup());
      } else if (this.workHistoryForm.length === 1) {
        this.workHistoryForm.push(this.createWorkGroup());
      }
    }
    if (this.educationHistory) {
      if (this.educationHistoryForm.length === 0) {
        this.educationHistoryForm.push(this.createEducationGroup());
      } else if (this.educationHistoryForm.length === 1) {
        this.educationHistoryForm.push(this.createEducationGroup());
      }
    }
  }

  initWorkArray() {
    if (this.workHistory) {
      if (this.workHistory.length > 0) {
        this.workForm = this.fb.group({
          workHistoryForm: this.fb.array([this.createWorkGroupInit(this.workHistory[0])])
        });
        for (let i = 1; i < this.workHistory.length + 1; i++) {
          if (i < this.workHistory.length) {
            this.workHistoryForm.push(this.createWorkGroupInit(this.workHistory[i]));
          }
        }
      } else {
        this.workForm = this.fb.group({
          workHistoryForm: this.fb.array([this.createWorkGroup()])
        });
        this.workHistoryForm.push(this.createWorkGroup());
      }
    }
  }

  initEducationArray() {
    if (this.educationHistory) {
      if (this.educationHistory.length > 0) {
        this.educationForm = this.fb.group({
          educationHistoryForm: this.fb.array([this.createEducationGroupInit(this.educationHistory[0])])
        });
        for (let i = 1; i < this.educationHistory.length + 1; i++) {
          if (i < this.educationHistory.length) {
            this.educationHistoryForm.push(this.createEducationGroupInit(this.educationHistory[i]));
          }
        }
      } else {
        this.educationForm = this.fb.group({
          educationHistoryForm: this.fb.array([this.createEducationGroup()])
        });
        this.educationHistoryForm.push(this.createEducationGroup());
      }
    }
  }

  resetElements() {
    if (this.removedWork.length > 0) {
      for (let i = 0; i < this.removedWork.length; i++) {
        if (!(this.removedWork[i].description === '' && this.removedWork[i].employer === '' && this.removedWork[i].startDate === '' && this.removedWork[i].endDate === '' && this.removedWork[i].title === ''))
        this.workHistoryForm.push(this.createWorkGroupInit(this.removedWork[i]));
      }
    }

    if (this.removedEducation.length > 0) {
      for (let i = 0; i < this.removedEducation.length; i++) {
        if (!(this.removedEducation[i].schoolName === '' && this.removedEducation[i].degreeOrCert === '' && this.removedEducation[i].completionDate === '' && this.removedEducation[i].description === '')) {
          this.educationHistoryForm.push(this.createEducationGroupInit(this.removedEducation[i]));
        }
      }
    }
    this.removedWork = [];
    this.removedEducation = [];
  }

  ngOnDestroy() {
    if (this.linkedInSubscribe) {
      this.linkedInSubscribe.unsubscribe();
    }
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

}


